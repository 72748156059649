import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { SortOrder } from "../@types";
import {
  PaymentMethod,
  PaymentPurpose,
  PaymentSortBy,
  PaymentStatus,
} from "../services/paymentService";

interface PaymentFilterState {
  amount: string;
  purpose: PaymentPurpose | null;
  method: PaymentMethod | null;
  status: PaymentStatus | null;
  selectedProperty: number | null;
  selectedUnit: number | null;
  isExpanded: boolean;
  sortColumn: PaymentSortBy | null;
  sortDirection: SortOrder | null;
  setFilters: (
    amount: string,
    purpose: PaymentPurpose | null,
    method: PaymentMethod | null,
    status: PaymentStatus | null
  ) => void;
  setSelectedProperty: (propertyId: number | null) => void;
  setSelectedUnit: (unitId: number | null) => void;
  setIsExpanded: (expanded: boolean) => void;
  setSort: (column: PaymentSortBy, direction: SortOrder) => void;
}

const usePaymentFilterStore = create<PaymentFilterState>()(
  devtools(
    persist(
      (set, get) => ({
        amount: "",
        purpose: null,
        method: null,
        status: null,
        selectedProperty: null,
        selectedUnit: null,
        isExpanded: false,
        sortColumn: null,
        sortDirection: null,
        setFilters: (amount, purpose, method, status) =>
          set({
            amount,
            purpose,
            method,
            status,
          }),
        setSelectedProperty: (selectedProperty) => set({ selectedProperty }),
        setSelectedUnit: (selectedUnit) => set({ selectedUnit }),
        setIsExpanded: (isExpanded) => set({ isExpanded }),
        setSort: (sortColumn, sortDirection) =>
          set({ sortColumn, sortDirection }),
      }),
      {
        name: "payment-filter-storage",
      }
    )
  )
);

export default usePaymentFilterStore;
