import { useState, useEffect } from "react";
import i18n from "../i18n";
import i18nIsoNationalities from "i18n-nationality";

const useNationalityOptions = () => {
    const [nationalityOptions, setNationalityOptions] = useState<{ label: string; value: string }[]>([]);

    useEffect(() => {
        const loadNationalities = async () => {
            const locale = i18n.language || "en"; // Get current i18n language

            try {
                // Dynamically load the correct language file
                const langModule = await import(`i18n-nationality/langs/${locale}.json`);
                i18nIsoNationalities.registerLocale(langModule);

                // Fetch and format the nationalities
                const nationalities = Object.entries(i18nIsoNationalities.getNames(locale)).map(([code, name]) => ({
                    label: name, // "Deutsch", "Français", etc.
                    value: code, // "DE", "FR", etc.
                }));

                setNationalityOptions(nationalities);
            } catch (error) {
                console.error(`Error loading nationalities for locale "${locale}":`, error);
                setNationalityOptions([]);
            }
        };

        loadNationalities();
    }, [i18n.language]); // Reload when language changes

    return { nationalityOptions };
};

export default useNationalityOptions;
