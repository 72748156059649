import '../sidemodal.css';
import React, { useCallback, useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../components/custom-button/custom-button';
import useAlertStore from '../../../stores/alertStore';
import { UploadedFile } from '../../../components/file-upload/file-upload';
import FileUploadSection from '../../../components/file-upload-section/file-upload-section';
import SidebarInputField from '../components/sidebar-input-field/sidebar-input-field';
import DateSelect from '../../../components/date-select/date-select';
import SidebarSelectField, { SidebarSelectFieldOptions } from '../components/sidebar-select-field/sidebar-select-field';
import { PaymentMethod, PaymentPurpose, PaymentStatus, formatPaymentMethod, formatPaymentPurpose } from '../../../services/paymentService';
import { IDocument } from '../../../services/documentService';
import DeleteConfirmationModal from '../../delete-confirmation-modal/delete-confirmation-modal';
import { createLeasePayments, deleteLeasePayment, ICreateLeasePayment, ILeasePayment, updateLeasePayment, uploadLeasePaymentDocument } from '../../../services/leasePaymentService';

interface LeasePaymentSidebarModalProps {
    isVisible: boolean;
    lease_id: number;
    payment: ILeasePayment | null;
    onClose: () => void;
    onDeleteDocument: (document: IDocument) => void;
    onPaymentCreated: (payment: ILeasePayment) => void;
    onPaymentUpdated: (payment: ILeasePayment) => void;
    onPaymentDeleted: (payment: ILeasePayment) => void;
}

const LeasePaymentSidebarModal: React.FC<LeasePaymentSidebarModalProps> = ({
    isVisible,
    lease_id,
    payment,
    onClose,
    onDeleteDocument,
    onPaymentCreated,
    onPaymentUpdated,
    onPaymentDeleted,
}) => {
    const { t } = useTranslation();
    const showAlert = useAlertStore(state => state.showAlert);

    // Select options for payment purpose and method
    const paymentPurposeOptions: SidebarSelectFieldOptions[] = Object.values(PaymentPurpose).map(purpose => ({
        label: formatPaymentPurpose(purpose as PaymentPurpose, t),
        value: purpose,
    }));

    const paymentMethodOptions: SidebarSelectFieldOptions[] = Object.values(PaymentMethod).map(method => ({
        label: formatPaymentMethod(method as PaymentMethod, t),
        value: method,
    }));

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<ILeasePayment | null>(null);
    const [formState, setFormState] = useState({
        purpose: PaymentPurpose.RENT.toString(),
        method: PaymentMethod.CHEQUE.toString(),
        chequeNumber: "",
        bankName: "",
        dueDate: null as Date | null,
        amount: "",
        remark: "",
        bounced: false,
    });

    const [errors, setErrors] = useState({
        chequeNumberError: "",
        bankNameError: "",
        dueDateError: "",
        amountError: ""
    });

    const [file, setFile] = useState<UploadedFile | null>(null);

    // Reset form state on modal close
    useEffect(() => {
        if (!isVisible) resetFormState();
    }, [isVisible]);

    // Populate form if editing an existing payment
    useEffect(() => {
        if (payment) {
            setFormState({
                purpose: payment.purpose.toString(),
                method: payment.method.toString(),
                chequeNumber: payment.cheque_no ?? "",
                bankName: payment.bank_name ?? "",
                dueDate: new Date(payment.due_date),
                amount: payment.amount.toString(),
                remark: payment.remark ?? "",
                bounced: payment.status === PaymentStatus.BOUNCED,
            });
        }
    }, [payment]);

    const resetFormState = () => {
        setFormState({
            purpose: PaymentPurpose.RENT.toString(),
            method: PaymentMethod.CHEQUE.toString(),
            chequeNumber: "",
            bankName: "",
            dueDate: null,
            amount: "",
            remark: "",
            bounced: false,
        });
        setErrors({
            chequeNumberError: "",
            bankNameError: "",
            dueDateError: "",
            amountError: ""
        });
        setFile(null);
    };

    // Form validation
    const validateForm = () => {
        let isValid = true;
        const { dueDate, method, bankName, chequeNumber, amount } = formState;

        if (!dueDate) {
            setErrors(prev => ({ ...prev, dueDateError: t("invalid_input.shares_empty") }));
            isValid = false;
        }

        if ((method === PaymentMethod.BANK_TRANSFER.toString() || method === PaymentMethod.CHEQUE.toString()) && bankName.length <= 0) {
            setErrors(prev => ({ ...prev, bankNameError: t("invalid_input.shares_empty") }));
            isValid = false;
        }

        if (method === PaymentMethod.CHEQUE.toString() && chequeNumber.length <= 0) {
            setErrors(prev => ({ ...prev, chequeNumberError: t("invalid_input.shares_empty") }));
            isValid = false;
        }

        if (!amount || Number(amount) <= 0) {
            setErrors(prev => ({ ...prev, amountError: t("invalid_input.amount_invalid") }));
            isValid = false;
        }

        return isValid;
    };

    // Handle create or update payment
    const handleSavePayment = useCallback(async () => {
        if (!validateForm()) return;

        setIsLoading(true);
        const { purpose, method, chequeNumber, bankName, dueDate, amount, remark, bounced } = formState;

        try {
            if (payment) {
                // Update payment
                const updatedPayment = await updateLeasePayment(payment.payment_id, {
                    lease_id: payment.lease_id,
                    due_date: dueDate!,
                    amount: Number(amount),
                    cheque_no: chequeNumber || null,
                    bank_name: bankName || null,
                    remark: remark || null,
                    method: method as PaymentMethod,
                    purpose: purpose as PaymentPurpose,
                    status: bounced ? PaymentStatus.BOUNCED : PaymentStatus.PENDING,
                } as ICreateLeasePayment);

                if (file) {
                    const document = await uploadLeasePaymentDocument(updatedPayment.payment_id, file.file);
                    updatedPayment.document = document;
                }
                onPaymentUpdated(updatedPayment);
                showAlert("success", t("modals.lease_payment.update_success_message"));
            } else {
                // Create new payment
                const createdPayments = await createLeasePayments([{
                    lease_id,
                    due_date: dueDate!,
                    amount: Number(amount),
                    cheque_no: chequeNumber || null,
                    bank_name: bankName || null,
                    remark: remark || null,
                    method: method as PaymentMethod,
                    purpose: purpose as PaymentPurpose,
                    status: bounced ? PaymentStatus.BOUNCED : PaymentStatus.PENDING,
                    destination_bank_detail_id: null,
                } as ICreateLeasePayment]);

                if (createdPayments.length > 0) {
                    if (file) {
                        const document = await uploadLeasePaymentDocument(createdPayments[0].payment_id, file.file);
                        createdPayments[0].document = document;
                    }
                    onPaymentCreated(createdPayments[0]);
                    showAlert("success", t("modals.lease_payment.add_success_message"));
                }
            }
            onClose();
        } catch (error) {
            // @ts-ignore
            showAlert("error", error.message);
        } finally {
            setIsLoading(false);
        }
    }, [formState]);

    const handleDeletePayment = useCallback(async () => {
        if (isDeleteModalVisible) {
            try {
                setIsDeleting(true);

                const deleted = await deleteLeasePayment(isDeleteModalVisible.payment_id);
                if (deleted) {
                    onPaymentDeleted(isDeleteModalVisible);
                    showAlert("success", t("modals.lease_payment.delete_success_message"));
                    onClose();
                }
            } catch (error) {
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsDeleting(false);
                setIsDeleteModalVisible(null);
            }
        }
    }, [isDeleteModalVisible, t, onClose, onPaymentDeleted, showAlert]);

    return (
        <>
            <div className={`sidebar-modal-backdrop ${isVisible ? 'visible' : ''}`}>
                <div className={`sidebar-modal ${isVisible ? 'visible' : ''}`}>
                    <div className="d-flex align-items-center justify-content-between my-3">
                        <h4 className="sidebar-modal-headline ms-3">
                            {payment ? t("modals.lease_payment.update_title") : t("modals.lease_payment.add_title")}
                        </h4>
                        <button className="btn" onClick={onClose}>
                            <IoClose size={20} />
                        </button>
                    </div>
                    <div className="sidebar-modal-divider" />
                    <div className="sidebar-modal-content">
                        <div className="sidebar-modal-scrollable-content">

                            {/* BOUNCED CHEQUE FLAG */}
                            {payment && formState.method === PaymentMethod.CHEQUE && (
                                <div className="form-check form-switch mb-4">
                                    <input className="form-check-input" type="checkbox" checked={formState.bounced} onChange={() => setFormState(prev => ({ ...prev, bounced: !prev.bounced }))} />
                                    <label className="form-check-label">{t("labels.cheque_bounced")}</label>
                                </div>
                            )}

                            <div className="row">
                                {/* Purpose and Method Selection */}
                                <div className="col-6">
                                    <SidebarSelectField
                                        id="create-lease-payment-select-purpose"
                                        label={t("labels.payment_purpose")}
                                        value={formState.purpose}
                                        onChange={value => setFormState(prev => ({ ...prev, purpose: value }))}
                                        options={paymentPurposeOptions}
                                        labelOnTop
                                        required
                                    />
                                </div>
                                <div className="col-6">
                                    <SidebarSelectField
                                        id="create-lease-payment-select-method"
                                        label={t("labels.payment_method")}
                                        value={formState.method}
                                        onChange={value => setFormState(prev => ({ ...prev, method: value }))}
                                        options={paymentMethodOptions}
                                        labelOnTop
                                        required
                                    />
                                </div>

                                {/* Bank and Cheque Information */}
                                {(formState.method === PaymentMethod.BANK_TRANSFER.toString() || formState.method === PaymentMethod.CHEQUE.toString()) && (
                                    <div className="col-6">
                                        <SidebarInputField
                                            id="create-lease-payment-bankname"
                                            label={t("labels.bank_details.bank_name")}
                                            placeholder="Emirates NBD"
                                            value={formState.bankName}
                                            errorMessage={errors.bankNameError}
                                            onChange={value => setFormState(prev => ({ ...prev, bankName: value }))}
                                            onClear={() => setFormState(prev => ({ ...prev, bankName: "" }))}
                                            labelOnTop
                                            required
                                        />
                                    </div>
                                )}
                                {formState.method === PaymentMethod.CHEQUE.toString() && (
                                    <div className="col-6">
                                        <SidebarInputField
                                            id="create-lease-payment-cheque-number"
                                            label={t("labels.cheque_number")}
                                            placeholder="000001"
                                            value={formState.chequeNumber}
                                            errorMessage={errors.chequeNumberError}
                                            onChange={value => setFormState(prev => ({ ...prev, chequeNumber: value }))}
                                            onClear={() => setFormState(prev => ({ ...prev, chequeNumber: "" }))}
                                            labelOnTop
                                            required
                                        />
                                    </div>
                                )}

                                {/* Due Date and Amount */}
                                <div className="col-6">
                                    <DateSelect
                                        id="create-lease-payment-due-date"
                                        label={t("labels.due_date")}
                                        placeholder="10/09/2024"
                                        date={formState.dueDate}
                                        errorMessage={errors.dueDateError}
                                        onChange={date => setFormState(prev => ({ ...prev, dueDate: date }))}
                                        onClear={() => setFormState(prev => ({ ...prev, dueDate: null }))}
                                        labelOnTop
                                        required
                                    />
                                </div>
                                <div className="col-6">
                                    <SidebarInputField
                                        id="create-lease-payment-amount"
                                        label={t("labels.amount")}
                                        placeholder="3,000.00 AED"
                                        type="number"
                                        value={formState.amount}
                                        errorMessage={errors.amountError}
                                        onChange={value => setFormState(prev => ({ ...prev, amount: value }))}
                                        onClear={() => setFormState(prev => ({ ...prev, amount: "" }))}
                                        labelOnTop
                                        required
                                    />
                                </div>

                                {/* Remark */}
                                <div className="col-12">
                                    <SidebarInputField
                                        id="create-lease-payment-remark"
                                        label={t("labels.remark")}
                                        placeholder={t("placeholders.remark")}
                                        value={formState.remark}
                                        onChange={value => setFormState(prev => ({ ...prev, remark: value }))}
                                        onClear={() => setFormState(prev => ({ ...prev, remark: "" }))}
                                        labelOnTop
                                        isTextArea
                                    />
                                </div>
                            </div>

                            {/* Upload Proof Document Section */}
                            <FileUploadSection
                                fileUploadId="create-lease-payment-upload-document"
                                title={t("modals.lease_payment.upload_proof_document")}
                                documents={payment?.document ? [payment.document] : []}
                                uploadedFiles={file ? [file] : []}
                                onUpdateUploadedFiles={files => setFile(files.length > 0 ? files[0] : null)}
                                onDeleteDocument={onDeleteDocument}
                                maxFilesToUpload={1}
                                hasEditPermission
                                removePadding
                                isDefaultExpanded
                            />
                        </div>

                        {/* Save Button */}
                        <div className="store-investor-btn d-flex">
                            <CustomButton
                                className="w-100"
                                title={payment ? t("modals.lease_payment.update_button") : t("modals.lease_payment.add_button")}
                                isLoading={isLoading}
                                onClick={handleSavePayment}
                            />
                            {payment && (
                                <CustomButton
                                    className="w-100 ms-3"
                                    color="red"
                                    title={t("buttons.delete")}
                                    isLoading={isDeleting}
                                    onClick={() => setIsDeleteModalVisible(payment)}
                                />
                            )}
                        </div>
                    </div>
                </div>

                {/* DELETE DOCUMENT MODAL */}
                {isDeleteModalVisible && (
                    <DeleteConfirmationModal
                        title={t("modals.lease_payment.delete_modal.title")}
                        message={t("modals.lease_payment.delete_modal.message")}
                        isLoading={isDeleting}
                        onClose={() => setIsDeleteModalVisible(null)}
                        onDelete={handleDeletePayment}
                    />
                )}
            </div>
        </>
    );
};

export default LeasePaymentSidebarModal;
