import '../base-modal.css';
import './view-document-modal.css';

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import { downloadDocument, IDocument } from "../../services/documentService";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

interface ModalProps {
    document_id: number;
    onClose: () => void;
}

const ViewDocumentModal: React.FC<ModalProps> = ({ document_id, onClose }) => {
    const { t } = useTranslation();

    // States
    const [isDownloading, setIsDownloading] = useState(true);
    const [fileUrl, setFileUrl] = useState<string | null>(null);
    const [fileType, setFileType] = useState<string | null>(null);

    // Load the document when the modal opens
    useEffect(() => {
        const loadFile = async () => {
            try {
                setIsDownloading(true);
                const blob: Blob | null = await downloadDocument(document_id);
                if (blob) {
                    const blobUrl = URL.createObjectURL(blob);
                    setFileUrl(blobUrl);

                    // Determine file type
                    if (blob.type.includes("pdf")) {
                        setFileType("pdf");
                    } else if (blob.type.includes("image")) {
                        setFileType("image");
                    } else {
                        setFileType("unsupported");
                    }
                }
            } catch (error) {
                console.error("Error while downloading file: ", error);
            } finally {
                setIsDownloading(false);
            }
        };
        loadFile();
    }, [document_id]);

    // Render File Preview
    const renderFilePreview = () => {
        const previewStyle: React.CSSProperties = {
            maxHeight: "600px",
            overflowY: "auto",
            display: "flex",
        };

        if (fileType === "pdf") {
            return (
                <div style={previewStyle}>
                    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
                        <div style={{ height: "600px", width: "100%", overflowY: "hidden" }}>
                            <Viewer
                                fileUrl={fileUrl!}
                                defaultScale={0.8}
                                initialPage={0} // Ensures it starts from the first page
                            />
                        </div>
                    </Worker>
                </div>
            );
        } else if (fileType === "image") {
            return (
                <div style={previewStyle}>
                    <img
                        src={fileUrl!}
                        alt="Document Preview"
                        style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "contain",
                        }}
                    />
                </div>
            );
        } else {
            return <p>{t("modals.review_document.unsupported_file_type")}</p>;
        }
    };

    return (
        <div className="modal-backdrop">
            <div className="modal-box" style={{ height: "80%", position: "relative", padding: "1.5rem", width: "50%" }}>
                {/* Close button (inside modal, top-right corner) */}
                <span
                    style={{
                        position: "absolute",
                        top: "1rem",
                        right: "1rem",
                        cursor: "pointer",
                        fontSize: "1.8rem",
                        lineHeight: "1",
                        display: "flex",
                        alignItems: "center",
                    }}
                    onClick={onClose}
                >
                    &times;
                </span>

                <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h3 className="input-modal-title">{t("modals.view_document.title")}</h3>
                    </div>
                </div>

                {/* File Preview Section */}
                <div className="file-preview-container mb-4">
                    {isDownloading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "300px" }}>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    ) : (
                        renderFilePreview()
                    )}
                </div>
            </div>
        </div>
    );
};

export default ViewDocumentModal;
