import './tenant-leases-section.css';

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Table, Badge } from 'react-bootstrap';
import { ILease } from '../../../../services/leaseService';
import { EmployeeRole } from '../../../../services/employeeService';
import { formatMoney, getFormattedDate } from '../../../../utils/helpers';
import ExpandableSection from '../../../../components/expandable-section/expandable-section';

interface TenantLeasesSectionProps {
    leases: ILease[];
    employeeRoleId?: number;
}

const TenantLeasesSection: React.FC<TenantLeasesSectionProps> = ({ leases, employeeRoleId }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const hasEditPermission = employeeRoleId === EmployeeRole.ADMINISTRATOR || employeeRoleId === EmployeeRole.PROPERTY_MANAGER;
    
    // Sortieren der Mietverträge: aktive zuerst, dann nach Startdatum absteigend
    const sortedLeases = [...leases].sort((a, b) => {
        // Aktive Mietverträge haben Priorität
        if (isActive(a) && !isActive(b)) return -1;
        if (!isActive(a) && isActive(b)) return 1;
        
        // Danach nach Startdatum sortieren (neueste zuerst)
        return new Date(b.start_date).getTime() - new Date(a.start_date).getTime();
    });
    
    // Prüfen, ob ein Mietvertrag aktiv ist
    const isActive = (lease: ILease): boolean => {
        const today = new Date();
        const startDate = new Date(lease.start_date);
        const endDate = new Date(lease.end_date);
        
        return !lease.terminated && startDate <= today && endDate >= today;
    };

    // Navigieren zur Lease Detail Seite
    const handleLeaseClick = (leaseId: number) => {
        navigate(`/leases/${leaseId}`);
    };

    // Rendererfunktion für die mobile Ansicht
    const renderMobileView = () => {
        return (
            <div className="lease-mobile-view">
                {sortedLeases.map((lease) => (
                    <div 
                        key={lease.lease_id} 
                        className="lease-card clickable" 
                        onClick={() => handleLeaseClick(lease.lease_id)}
                    >
                        <div className="lease-card-header">
                            <div className="lease-card-status">
                                {lease.terminated ? (
                                    <Badge bg="danger">{t('components.tenant_leases.terminated', 'Terminated')}</Badge>
                                ) : isActive(lease) ? (
                                    <Badge bg="success">{t('components.tenant_leases.active', 'Active')}</Badge>
                                ) : new Date(lease.start_date) > new Date() ? (
                                    <Badge bg="info">{t('components.tenant_leases.upcoming', 'Upcoming')}</Badge>
                                ) : (
                                    <Badge bg="secondary">{t('components.tenant_leases.expired', 'Expired')}</Badge>
                                )}
                            </div>
                        </div>
                        
                        <div className="lease-card-detail">
                            <div className="lease-card-label">{t('components.tenant_leases.unit', 'Unit')}</div>
                            <div className="lease-card-value">{lease.unit.unit_number}</div>
                        </div>
                        
                        <div className="lease-card-detail">
                            <div className="lease-card-label">{t('components.tenant_leases.property', 'Property')}</div>
                            <div className="lease-card-value">{lease.unit.property.property_name}</div>
                        </div>
                        
                        <div className="lease-card-detail">
                            <div className="lease-card-label">{t('components.tenant_leases.period', 'Period')}</div>
                            <div className="lease-card-value">
                                {getFormattedDate(lease.start_date)} - {getFormattedDate(lease.end_date)}
                            </div>
                        </div>
                        
                        <div className="lease-card-detail">
                            <div className="lease-card-label">{t('components.tenant_leases.rent', 'Rent')}</div>
                            <div className="lease-card-value">AED {formatMoney(lease.annual_rent_amount)}</div>
                        </div>
                        
                        <div className="lease-card-detail">
                            <div className="lease-card-label">{t('components.tenant_leases.deposit', 'Deposit')}</div>
                            <div className="lease-card-value">AED {formatMoney(lease.deposit_amount)}</div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <ExpandableSection
            className="tenant-leases-section"
            title={t('components.tenant_leases.title', 'Lease Contracts')}
            isHeadlineLarge
            isDefaultExpanded
        >
            <div className="white-card">
                {leases.length === 0 ? (
                    <div className="text-center py-3 text-muted">
                        {t('components.tenant_leases.no_leases', 'No lease contracts found for this tenant')}
                    </div>
                ) : (
                    <>
                        {/* Mobile Ansicht */}
                        <div className="d-block d-md-none">
                            {renderMobileView()}
                        </div>
                        
                        {/* Desktop Ansicht */}
                        <div className="d-none d-md-block table-responsive">
                            <Table hover className="lease-table">
                                <thead>
                                    <tr>
                                        <th>{t('components.tenant_leases.status', 'Status')}</th>
                                        <th>{t('components.tenant_leases.unit', 'Unit')}</th>
                                        <th>{t('components.tenant_leases.property', 'Property')}</th>
                                        <th>{t('components.tenant_leases.period', 'Period')}</th>
                                        <th>{t('components.tenant_leases.rent', 'Rent')}</th>
                                        <th>{t('components.tenant_leases.deposit', 'Deposit')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedLeases.map((lease) => (
                                        <tr 
                                            key={lease.lease_id} 
                                            onClick={() => handleLeaseClick(lease.lease_id)}
                                            className="clickable-row"
                                        >
                                            <td>
                                                {lease.terminated ? (
                                                    <Badge bg="danger">{t('components.tenant_leases.terminated', 'Terminated')}</Badge>
                                                ) : isActive(lease) ? (
                                                    <Badge bg="success">{t('components.tenant_leases.active', 'Active')}</Badge>
                                                ) : new Date(lease.start_date) > new Date() ? (
                                                    <Badge bg="info">{t('components.tenant_leases.upcoming', 'Upcoming')}</Badge>
                                                ) : (
                                                    <Badge bg="secondary">{t('components.tenant_leases.expired', 'Expired')}</Badge>
                                                )}
                                            </td>
                                            <td>{lease.unit.unit_number}</td>
                                            <td>{lease.unit.property.property_name}</td>
                                            <td>
                                                {getFormattedDate(lease.start_date)} - {getFormattedDate(lease.end_date)}
                                            </td>
                                            <td>AED {formatMoney(lease.annual_rent_amount)}</td>
                                            <td>AED {formatMoney(lease.deposit_amount)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </>
                )}
            </div>
        </ExpandableSection>
    );
};

export default TenantLeasesSection; 