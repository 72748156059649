import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { SortOrder } from "../@types";
import { TenantSortBy } from "../services/tenantService";

interface TenantFilterState {
  active: boolean;
  selectedProperty: number | null;
  sortColumn: TenantSortBy | null;
  sortDirection: SortOrder | null;
  isExpanded: boolean;
  setFilters: (active: boolean) => void;
  setSort: (column: TenantSortBy, direction: SortOrder) => void;
  setSelectedProperty: (propertyId: number | null) => void;
  setIsExpanded: (expanded: boolean) => void;
}

const useTenantFilterStore = create<TenantFilterState>()(
  devtools(
    persist(
      (set, get) => ({
        active: false,
        sortColumn: null,
        sortDirection: null,
        selectedProperty: null,
        isExpanded: false,
        setFilters: (active) =>
          set({
            active,
          }),
        setSort: (sortColumn, sortDirection) =>
          set({ sortColumn, sortDirection }),
        setSelectedProperty: (selectedProperty) => set({ selectedProperty }),
        setIsExpanded: (isExpanded) => set({ isExpanded }),
      }),
      {
        name: "tenant-filter-storage",
      }
    )
  )
);

export default useTenantFilterStore;
