import styled from "styled-components";
import { colorPalette } from "../../utils/theme";

// Container for the tag
export const TagContainer = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    prop !== "backgroundColor" && prop !== "color" && prop !== "size",
})<{ backgroundColor: string; color: string; size: string }>`
  height: ${({ size }) => (size === "L" ? "36px" : "24px")};
  padding: ${({ size }) => (size === "L" ? "8px 12px" : "4px 8px")};
  border-radius: ${({ size }) => (size === "L" ? "8px" : "4px")};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  font-family: "Space Mono", sans-serif;
  font-weight: 400;

  font-size: ${({ size }) => (size === "L" ? "14px" : "13px")};
  line-height: ${({ size }) => (size === "L" ? "19.6px" : "18.2px")};
`;

// Optional logo on the left side
export const TagLogo = styled.img`
  height: 14px;
  width: 14px;
  margin-right: 8px;

  @media (max-width: 767.98px) {
    margin-right: 0px;
  }
`;

// Tooltip icon styling
export const TagTooltipIcon = styled.span`
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: ${colorPalette.successMedium};

  &:hover {
    color: #ededed;
  }
`;
