import '../base-modal.css';
import './review-document-modal.css';

import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Spinner } from "react-bootstrap";
import { downloadDocument, IDocument, updateDocumentStatus, DocumentStatus, DocumentType } from "../../services/documentService";
import SidebarInputField from '../sidebar/components/sidebar-input-field/sidebar-input-field';
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import ExpandableSection from '../../components/expandable-section/expandable-section';
import AddressForm from '../../components/address-form/address-form';

interface ModalProps {
    doc: IDocument;
    onClose: () => void;
    onAccepted: () => void;
    onDeclined: (reason: string) => void;
}

const ReviewDocumentModal: React.FC<ModalProps> = ({ doc, onClose, onAccepted, onDeclined }) => {
    const { t } = useTranslation();

    // States
    const [isDownloading, setIsDownloading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [documentIdentifier, setDocumentIdentifier] = useState<string>("");
    const [reason, setReason] = useState("");
    const [reasonError, setReasonError] = useState("");
    const [documentIdentifierError, setDocumentIdentifierError] = useState<string>("");
    const [fileUrl, setFileUrl] = useState<string | null>(null);
    const [fileType, setFileType] = useState<string | null>(null);

    const onClickInvestor = (investor_id: number) => {
        window.open(`/investors/${investor_id}`, "_blank");
    };

    // Load the document when the modal opens
    useEffect(() => {
        setDocumentIdentifier(doc.document_identifier ?? "");
        setDocumentIdentifierError("");

        const loadFile = async () => {
            try {
                setIsDownloading(true);
                const blob: Blob | null = await downloadDocument(doc.document_id);
                if (blob) {
                    const blobUrl = URL.createObjectURL(blob);
                    setFileUrl(blobUrl);

                    // Determine file type
                    if (blob.type.includes("pdf")) {
                        setFileType("pdf");
                    } else if (blob.type.includes("image")) {
                        setFileType("image");
                    } else {
                        setFileType("unsupported");
                    }
                }
            } catch (error) {
                console.error("Error while downloading file: ", error);
            } finally {
                setIsDownloading(false);
            }
        };
        loadFile();
    }, [doc]);

    // Accept Document
    const onHandleAcceptDocument = useCallback(async () => {
        let isValid = true;
        const needDocumentIdentifier = doc.document_type === DocumentType.PASSPORT || doc.document_type === DocumentType.EMIRATES_ID;

        if (needDocumentIdentifier && !documentIdentifier.trim()) {
            setDocumentIdentifierError(t("invalid_input.document_identifier_empty"));
            isValid = false;
        }

        if (isValid) {
            try {
                setIsLoading(true);
                await updateDocumentStatus(doc.document_id, DocumentStatus.ACCEPTED, documentIdentifier.trim().length > 0 ? documentIdentifier : null);
                onAccepted();
            } catch (error) {
                console.error(`Error while accepting document (${doc.document_id}):`, error);
            } finally {
                setIsLoading(false);
            }
        }
    }, [doc, documentIdentifier, onAccepted]);

    // Decline Document
    const onHandleDeclineDocument = useCallback(async () => {
        let isValid = true;

        if (!reason.trim()) {
            setReasonError(t("invalid_input.reason_empty"));
            isValid = false;
        }

        if (isValid) {
            try {
                setIsLoading(true);
                await updateDocumentStatus(doc.document_id, DocumentStatus.DECLINED, documentIdentifier.trim().length > 0 ? documentIdentifier : null, reason);
                onDeclined(reason);
            } catch (error) {
                console.error(`Error while declining document (${doc.document_id}):`, error);
            } finally {
                setIsLoading(false);
            }
        }
    }, [reason, doc, t, documentIdentifier, onDeclined]);

    // Render File Preview
    const renderFilePreview = () => {
        const previewStyle: React.CSSProperties = {
            maxHeight: "500px",
            overflowY: "auto",
            display: "flex",
        };

        if (fileType === "pdf") {
            return (
                <div className="file-preview-container" style={previewStyle}>
                    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
                        <div style={{ width: "100%" }}>
                            <Viewer
                                fileUrl={fileUrl!}
                                defaultScale={0.8}
                                initialPage={0} // Ensures it starts from the first page
                            />
                        </div>
                    </Worker>
                </div>
            );
        } else if (fileType === "image") {
            return (
                <div className="file-preview-container" style={previewStyle}>
                    <img
                        src={fileUrl!}
                        alt="Document Preview"
                        style={{
                            maxWidth: "100%",
                            objectFit: "contain",
                        }}
                    />
                </div>
            );
        } else {
            return <p>{t("modals.review_document.unsupported_file_type")}</p>;
        }
    };

    return (
        <div className="modal-backdrop">
            <div className="modal-box" style={{ position: "relative", padding: "1.5rem", width: doc.document_type === DocumentType.PROOF_OF_ADDRESS && doc.status === DocumentStatus.IN_REVIEW ? "70%" : "600px" }}>
                {/* Close button (inside modal, top-right corner) */}
                <span
                    style={{
                        position: "absolute",
                        top: "1rem",
                        right: "1rem",
                        cursor: "pointer",
                        fontSize: "1.8rem",
                        lineHeight: "1",
                        display: "flex",
                        alignItems: "center",
                    }}
                    onClick={onClose}
                >
                    &times;
                </span>

                <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h3 className="input-modal-title">{t("modals.review_document.title")}</h3>
                        {doc.investor && (
                            <h3
                                className="input-modal-subtitle"
                                style={{ cursor: "pointer" }}
                                onClick={() => onClickInvestor(doc.investor!.investor_id)}
                            >
                                {t("labels.firstname")}: {doc.investor.first_name} | {t("labels.lastname")}: {doc.investor.last_name} | {t("labels.birthdate")}: {doc.investor.birth_date}
                            </h3>
                        )}
                    </div>
                </div>

                <Row className="review-document-content">
                    <Col xs={12} md={doc.document_type === DocumentType.PROOF_OF_ADDRESS && doc.status === DocumentStatus.IN_REVIEW ? 6 : 12}>
                        {/* File Preview Section */}
                        <div className="file-preview-container mb-4">
                            {isDownloading ? (
                                <div className="d-flex justify-content-center align-items-center" style={{ height: "300px" }}>
                                    <Spinner animation="border" variant="primary" />
                                </div>
                            ) : (
                                renderFilePreview()
                            )}
                        </div>

                        {doc.status === DocumentStatus.IN_REVIEW && (
                            <>
                                <div className="row">
                                    {(doc.document_type === DocumentType.PASSPORT || doc.document_type === DocumentType.EMIRATES_ID) && (
                                        <div className="col-12 col-md-6">
                                            <SidebarInputField
                                                id="review-document-identifier"
                                                type="text"
                                                label={t("labels.document_identifier")}
                                                placeholder="CV7382371"
                                                value={documentIdentifier}
                                                errorMessage={documentIdentifierError}
                                                onClear={() => setDocumentIdentifier("")}
                                                onChange={(value: string) => {
                                                    setDocumentIdentifier(value);
                                                    setDocumentIdentifierError("");
                                                }}
                                                labelOnTop
                                                required
                                            />
                                        </div>
                                    )}
                                    <div className="col-12 col-md-6">
                                        {/* Decline Reason Input */}
                                        <SidebarInputField
                                            id="reason-for-refusal"
                                            type="text"
                                            label={t("labels.reason_for_refusal")}
                                            placeholder={t("placeholders.reason_for_refusal")}
                                            value={reason}
                                            errorMessage={reasonError}
                                            onClear={() => setReason("")}
                                            onChange={(value: string) => {
                                                setReason(value);
                                                setReasonError("");
                                            }}
                                            labelOnTop
                                            required
                                        />
                                    </div>
                                </div>

                                {/* Buttons */}
                                {!isDownloading &&
                                    <div className="text-end mt-4">
                                        <button
                                            className={`btn btn-success me-2 ${isLoading ? "disabled" : ""}`}
                                            onClick={onHandleAcceptDocument}
                                            disabled={isLoading}
                                        >
                                            {isLoading ? <Spinner size="sm" /> : t("buttons.accept")}
                                        </button>
                                        <button
                                            className={`btn btn-danger me-2 ${isLoading ? "disabled" : ""}`}
                                            onClick={onHandleDeclineDocument}
                                            disabled={isLoading}
                                        >
                                            {isLoading ? <Spinner size="sm" /> : t("buttons.decline")}
                                        </button>
                                    </div>
                                }
                            </>
                        )}
                    </Col>

                    {doc.status === DocumentStatus.IN_REVIEW && doc.investor && doc.document_type === DocumentType.PROOF_OF_ADDRESS && (
                        <Col xs={12} md={6}>
                            <ExpandableSection
                                className="investor-address-section"
                                title={t("pages.investor_detail.address_title")}
                                isHeadlineLarge
                                isDefaultExpanded
                            >
                                <div className="white-card">
                                    <AddressForm
                                        address={doc.investor.address}
                                        onAddressChanged={() => { }}
                                        disabled
                                    />
                                </div>
                            </ExpandableSection>
                        </Col>
                    )}
                </Row>
            </div>
        </div>
    );
};

export default ReviewDocumentModal;
