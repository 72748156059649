import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface DashboardFilterState {
  selectedProperty: number | null;
  setSelectedProperty: (propertyId: number | null) => void;
}

const useDashboardFilterStore = create<DashboardFilterState>()(
  devtools(
    persist(
      (set) => ({
        selectedProperty: null,
        setSelectedProperty: (selectedProperty) => set({ selectedProperty }),
      }),
      {
        name: "dashboard-filter-storage",
      }
    )
  )
);

export default useDashboardFilterStore; 