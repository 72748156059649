import { TFunction } from "i18next";
import { API_URL } from "../utils/constants";
import { IInvestor } from "./investorService";

export enum DocumentType {
  PASSPORT = "passport",
  PROOF_OF_ADDRESS = "proof-of-address",
  EMIRATES_ID = "emirates-id",
  VISA = "visa",
  TENANCY_CONTRACT = "tenancy-contract",
  PURCHASE_CONTRACT = "purchase-contract",
  TITLE_DEED = "title-deed",
  OQOOD = "oqood",
  POWER_OF_ATTORNEY = "power-of-attorney",
  PROPERTY_MANAGEMENT_CONTRACT = "property-management-contract",
  SNAGGING_REPORT = "snagging-report",
  DE_SNAGGING_REPORT = "de-snagging-report",
  INVOICE = "invoice",
  RECEIPT = "receipt",
}

export const formatDocumentType = (
  documentType: DocumentType | null | undefined,
  t: TFunction
) => {
  switch (documentType) {
    case DocumentType.PASSPORT:
      return t("labels.document_types.passport");
    case DocumentType.PROOF_OF_ADDRESS:
      return t("labels.document_types.proof_of_address");
    case DocumentType.EMIRATES_ID:
      return t("labels.document_types.emirates_id");
    case DocumentType.VISA:
      return t("labels.document_types.visa");
    case DocumentType.TENANCY_CONTRACT:
      return t("labels.document_types.tenancy_contract");
    case DocumentType.PURCHASE_CONTRACT:
      return t("labels.document_types.purchase_contract");
    case DocumentType.TITLE_DEED:
      return t("labels.document_types.title_deed");
    case DocumentType.OQOOD:
      return t("labels.document_types.oqood");
    case DocumentType.POWER_OF_ATTORNEY:
      return t("labels.document_types.power_of_attorney");
    case DocumentType.PROPERTY_MANAGEMENT_CONTRACT:
      return t("labels.document_types.property_management_contract");
    case DocumentType.INVOICE:
      return t("labels.document_types.invoice");
    case DocumentType.RECEIPT:
      return t("labels.document_types.receipt");
    default:
      return t("labels.unknown");
  }
};

export enum DocumentStatus {
  ACCEPTED = "accepted",
  IN_REVIEW = "in_review",
  DECLINED = "declined",
}

export const formatDocumentStatus = (
  status: DocumentStatus | null,
  t: TFunction
) => {
  switch (status) {
    case DocumentStatus.ACCEPTED:
      return t("labels.document_states.accepted");
    case DocumentStatus.IN_REVIEW:
      return t("labels.document_states.in_review");
    case DocumentStatus.DECLINED:
      return t("labels.document_states.declined");
    default:
      return t("labels.unknown");
  }
};

export interface IDocument {
  document_id: number;
  document_type?: DocumentType | null;
  status: DocumentStatus;
  reason_for_refusal: string | null;
  file_type: string;
  file_path: string;
  file_name: string;
  valid_until: string | null;
  internal_document: boolean;
  document_identifier?: string | null;
  created_at: string;
  updated_at: string;
  investor?: IInvestor | null;
}

export const fetchDocuments = async (url: string): Promise<IDocument[]> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.documents;
};

export const constructDocumentsUrl = (
  status: DocumentStatus | null,
  search: string,
  page?: number,
  pageSize?: number
): string => {
  let queryParams = new URLSearchParams();

  if (search.trim().length > 0) {
    queryParams.append("search", encodeURIComponent(search));
  }
  if (status !== null) {
    queryParams.append("status", status.toString());
  }
  if (page !== undefined) {
    queryParams.append("page", page.toString());
  }
  if (pageSize !== undefined) {
    queryParams.append("pageSize", pageSize.toString());
  }

  return `${API_URL}/documents?${queryParams.toString()}`;
};

export const downloadDocument = async (
  documentId: number
): Promise<Blob | null> => {
  const response = await fetch(`${API_URL}/documents/${documentId}/download`, {
    method: "GET",
    credentials: "include",
  });

  if (!response.ok) {
    throw new Error("Network response was not ok " + response.statusText);
  }

  const fileBlobResponse = await response.blob();
  return fileBlobResponse;
};

export const deleteDocument = async (document_id: number): Promise<boolean> => {
  const response = await fetch(`${API_URL}/documents/${document_id}`, {
    method: "DELETE",
    credentials: "include",
  });

  return response.status === 200;
};

export const updateDocumentStatus = async (
  document_id: number,
  status: DocumentStatus,
  document_identifier?: string | null,
  reason_for_refusal?: string
): Promise<IDocument> => {
  const response = await fetch(`${API_URL}/documents/${document_id}/status`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      status,
      reason_for_refusal: reason_for_refusal ?? null,
      document_identifier: document_identifier ?? null,
    }),
  });

  const data = await response.json();
  if (response.status === 200) return data;
  else throw new Error(data.message);
};

export const updateDocument = async (
  document_id: number,
  valid_until: string | null,
  document_identifier: string | null
): Promise<IDocument> => {
  const response = await fetch(`${API_URL}/documents/${document_id}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      valid_until,
      document_identifier,
    }),
  });

  const data = await response.json();
  if (response.status === 200) return data;
  else throw new Error(data.message);
};
