import './pm-contract-table.css';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../../components/custom-button/custom-button';
import { exportPMContractsCsv, formatPropertyManagementPackage, IPropertyManagementContract, PMContractSortBy } from '../../../../services/pmContractService';
import { getFormattedDate } from '../../../../utils/helpers';
import { SortOrder } from '../../../../@types';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

interface PMContractTableProps {
    contracts: IPropertyManagementContract[];
    selectedSortColumn: PMContractSortBy | null;
    selectedSortDirection: SortOrder | null;
    onSortChange: (sortColumn: PMContractSortBy, sortDirection: SortOrder) => void;
    onClickContract: (contract: IPropertyManagementContract) => void;
}

const PMContractTable: React.FC<PMContractTableProps> = ({
    contracts,
    selectedSortColumn,
    selectedSortDirection,
    onSortChange,
    onClickContract
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // State management
    const [selectedContracts, setSelectedContracts] = useState<Set<number>>(new Set());
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSort = (column: PMContractSortBy) => {
        const direction = selectedSortColumn === column && selectedSortDirection === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
        onSortChange(column, direction);
    };

    const handleCheckboxChange = (leaseId: number) => {
        setSelectedContracts(prevSelected => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(leaseId)) {
                newSelected.delete(leaseId);
            } else {
                newSelected.add(leaseId);
            }
            return newSelected;
        });
    };

    const handleExport = async () => {
        const selected = Array.from(selectedContracts);
        setIsLoading(true);
        try {
            const blob = await exportPMContractsCsv(selected);

            // Generate current date and time string
            const now = new Date();
            const formattedDate = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // yyyy-mm-dd
            const formattedTime = now.toLocaleTimeString().replace(/:/g, "-"); // hh-mm-ss    

            const fileName = `pm_contracts_${formattedDate}_${formattedTime}.csv`;

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();

            // Reset selected leases
            setSelectedContracts(new Set());
        } catch (error) {
            console.error("Error exporting property management contracts:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            {selectedContracts.size > 0 && (
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="d-flex align-items-center ms-auto">
                        <span className="me-3">{selectedContracts.size} {t("labels.selected")}</span>
                        <CustomButton
                            title={t("buttons.csv_export")}
                            isLoading={isLoading}
                            onClick={handleExport}
                        />
                    </div>
                </div>
            )}
            {contracts.length > 0 ? (
                <div className="pm-contract-table-responsive">
                    <table className="pm-contract-table">
                        <thead>
                            <tr>
                                <th>
                                    <input
                                        type="checkbox"
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setSelectedContracts(new Set(contracts.map(c => c.contract_id)));
                                            } else {
                                                setSelectedContracts(new Set());
                                            }
                                        }}
                                        checked={selectedContracts.size === contracts.length}
                                    />
                                </th>
                                <th onClick={() => handleSort(PMContractSortBy.UNIT_NUMBER)} style={{ cursor: 'pointer' }}>
                                    {t("pm_contract_table.unit_header")}
                                    {selectedSortColumn === PMContractSortBy.UNIT_NUMBER ? (
                                        selectedSortDirection === SortOrder.ASC ? <FaSortUp /> : <FaSortDown />
                                    ) : <FaSort />}
                                </th>
                                <th>{t("pm_contract_table.investor_header")}</th>
                                <th>{t("pm_contract_table.package_header")}</th>
                                <th>{t("pm_contract_table.start_date_header")}</th>
                                <th>{t("pm_contract_table.sign_date_header")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contracts.map(contract => (
                                <tr
                                    key={contract.contract_id}
                                    className="active"
                                    onClick={() => onClickContract(contract)}
                                >
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedContracts.has(contract.contract_id)}
                                            onChange={() => handleCheckboxChange(contract.contract_id)}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    </td>
                                    <td>
                                        <a href={`/units/${contract.unit?.unit_id}`}>
                                            {contract.unit?.unit_number}
                                        </a>
                                    </td>
                                    <td>
                                        {contract.requested_by ? (
                                            <a href={`/investors/${contract.requested_by?.investor_id}`}>
                                                {contract.requested_by.first_name} {contract.requested_by.last_name}
                                            </a>
                                        ) : (
                                            "-"
                                        )}
                                    </td>
                                    <td>{formatPropertyManagementPackage(contract.package)}</td>
                                    <td>{getFormattedDate(contract.start_date)}</td>
                                    <td>{contract.sign_date ? getFormattedDate(contract.sign_date) : "-"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p className="text-center my-0">{t("pages.pm_contracts.empty_message")}</p>
            )}
        </div>
    );
};

export default PMContractTable;
