// UnitDetailPage.tsx
import "./unit-detail-page.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Lottie from "lottie-react";
import DOMPurify from 'dompurify';
import _ from 'lodash';
import useAlertStore from "../../stores/alertStore";
import CustomButton from "../../components/custom-button/custom-button";
import { getFormattedDate } from "../../utils/helpers";
import { UploadedFile } from "../../components/file-upload/file-upload";
import { useTranslation } from "react-i18next";
import { DocumentType, IDocument } from "../../services/documentService";
import useEmployeeStore from "../../stores/employeeStore";
import useUnitDetailStore from "../../stores/unitDetailStore";
import { IUnit, deleteUnit, fetchUnit, updateUnit, uploadUnitDocuments, uploadUnitPictures } from "../../services/unitService";
import UnitDetails from "./components/unit-details/unit-details";
import UnitGeneralSection from "./components/unit-general-section/unit-general-section";
import UnitUtilitySection from "./components/unit-utility-section/unit-utility-section";
import OwnerDetails from "./components/owner-details/owner-details";
import LoadingAnim from "../../assets/anims/loading.-anim.json";
import DeleteAnim from "../../assets/anims/delete-anim.json";
import UnitPicturesSection from "./components/unit-pictures-section/unit-pictures-section";
import TenantDetails from "./components/tenant-details/tenant-details";
import DeleteConfirmationModal from "../../modals/delete-confirmation-modal/delete-confirmation-modal";
import useEditPermission from "../../hooks/useEditPermission";
import ParkingSpaceSection from "./components/parking-space-section/parking-space-section";
import usePageTitle from "../../hooks/usePageTitle";
import UnitConditionSection from "./components/unit-condition-section/unit-condition-section";
import FileUploadSection from "../../components/file-upload-section/file-upload-section";
import { EmployeeRole } from "../../services/employeeService";
import { Col, Row } from "react-bootstrap";
import Tag from "../../components/tag/tag";

enum DocumentTab {
    PURCHASE = 'purchase',
    TITLE_DEED = 'title_deed',
    OQOOD = 'oqood',
    SNAGGING = 'snagging',
    OTHER = 'other',
    PHOTOS = 'photos'
}

const UnitDetailPage = () => {
    let { unitId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const {
        unit,
        isLoading,
        isDeleting,
        setUnit,
        setIsLoading,
        setIsDeleting,
        addUnitOwner,
        updateUnitOwner,
        deleteUnitOwner,
        addParkingSpace,
        removeParkingSpace
    } = useUnitDetailStore();
    const employeeRoleId = useEmployeeStore(state => state.employee?.role_id);

    const hasEditPermission = useEditPermission(employeeRoleId);
    const hasDeletePermission = useEditPermission(employeeRoleId, [EmployeeRole.ADMINISTRATOR]);

    // States
    const [unitOriginal, setUnitOriginal] = useState<IUnit | null>(null);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [pictures, setPictures] = useState<UploadedFile[]>([]);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
    const [activeDocumentTab, setActiveDocumentTab] = useState<DocumentTab>(DocumentTab.PURCHASE);

    // Uploading Files
    const [purchaseContractFile, setPurchaseContractFile] = useState<UploadedFile | null>(null);
    const [titleDeedFile, setTitleDeedFile] = useState<UploadedFile | null>(null);
    const [oqoodFile, setOqoodFile] = useState<UploadedFile | null>(null);
    const [files, setFiles] = useState<UploadedFile[]>([]);
    const [internalFiles, setInternalFiles] = useState<UploadedFile[]>([]);
    const [snaggingFile, setSnaggingFile] = useState<UploadedFile | null>(null);
    const [deSnaggingFile, setDeSnaggingFile] = useState<UploadedFile | null>(null);

    useEffect(() => {
        if (unitId) {
            const loadUnit = async () => {
                try {
                    setIsLoading(true);
                    const unit = await fetchUnit(Number(unitId));
                    setUnit(unit);
                    setUnitOriginal(unit);
                } catch (error) {
                    console.log(`Error while fetching unit with id ${unitId}:`, error);
                    // @ts-ignore
                    showAlert("error", error.message);
                } finally {
                    setIsLoading(false);
                }
            }
            loadUnit();
        }
    }, [unitId, setIsLoading, setUnit, setUnitOriginal, showAlert]);

    // Set page title
    usePageTitle(t("pages.unit_detail.page_title", { name: unit ? unit.unit_number : "" }));

    const onClickUpdateUnit = useCallback(async () => {
        if (unit) {
            try {
                setIsUpdating(true);
                const updatedUnit = await updateUnit(unit);

                // Update unit details
                const updatedUnitDetails = {
                    ...unit,
                    unit_number: updatedUnit.unit_number,
                    floor: updatedUnit.floor,
                    square_feet: updatedUnit.square_feet,
                    bedroom: updatedUnit.bedroom,
                    bathroom: updatedUnit.bathroom,
                    dewa: updatedUnit.dewa,
                    empower: updatedUnit.empower,
                    gas_no: updatedUnit.gas_no,
                    municipality_no: updatedUnit.municipality_no,
                    under_renovation: updatedUnit.under_renovation,
                    under_dispute: updatedUnit.under_dispute,
                    furnished: updatedUnit.furnished,
                };

                // Array to hold all upload promises
                const uploadPromises = [];
                const updatedDocuments = [...unit.documents];

                // Upload purchase contract document
                if (purchaseContractFile) {
                    uploadPromises.push(
                        uploadUnitDocuments(unit.unit_id, [purchaseContractFile.file], DocumentType.PURCHASE_CONTRACT)
                            .then(uploadedDocuments => {
                                updatedDocuments.push(...uploadedDocuments);
                            })
                    );
                    setPurchaseContractFile(null);
                }

                // Upload title deed document
                if (titleDeedFile) {
                    uploadPromises.push(
                        uploadUnitDocuments(unit.unit_id, [titleDeedFile.file], DocumentType.TITLE_DEED)
                            .then(uploadedDocuments => {
                                updatedDocuments.push(...uploadedDocuments);
                            })
                    );
                    setTitleDeedFile(null);
                }

                // Upload oqood document
                if (oqoodFile) {
                    uploadPromises.push(
                        uploadUnitDocuments(unit.unit_id, [oqoodFile.file], DocumentType.OQOOD)
                            .then(uploadedDocuments => {
                                updatedDocuments.push(...uploadedDocuments);
                            })
                    );
                    setOqoodFile(null);
                }

                // Upload snagging document
                if (snaggingFile) {
                    uploadPromises.push(
                        uploadUnitDocuments(unit.unit_id, [snaggingFile.file], DocumentType.SNAGGING_REPORT)
                            .then(uploadedDocuments => {
                                updatedDocuments.push(...uploadedDocuments);
                            })
                    );
                    setSnaggingFile(null);
                }

                // Upload de-snagging document
                if (deSnaggingFile) {
                    uploadPromises.push(
                        uploadUnitDocuments(unit.unit_id, [deSnaggingFile.file], DocumentType.DE_SNAGGING_REPORT)
                            .then(uploadedDocuments => {
                                updatedDocuments.push(...uploadedDocuments);
                            })
                    );
                    setDeSnaggingFile(null);
                }

                // Upload new documents
                if (files.length > 0 || internalFiles.length > 0) {
                    const allFiles = [
                        ...files.map(file => ({ ...file, internal_document: false })),
                        ...internalFiles.map(file => ({ ...file, internal_document: true }))
                    ];

                    uploadPromises.push(
                        uploadUnitDocuments(
                            unit.unit_id,
                            allFiles.map(file => file.file),
                            null,
                            [],
                            allFiles.map(file => file.internal_document ? "true" : "false")
                        ).then(uploadedDocuments => {
                            updatedDocuments.push(...uploadedDocuments);
                        })
                    );

                    setFiles([]);
                    setInternalFiles([]);
                }

                // Upload new pictures
                if (pictures.length > 0) {
                    uploadPromises.push(
                        uploadUnitPictures(unit.unit_id, pictures.map(file => file.file))
                            .then(uploadedPictures => {
                                updatedUnitDetails.pictures = [...unit.pictures, ...uploadedPictures];
                            })
                    );

                    setPictures([]);
                }

                await Promise.all(uploadPromises);

                setUnit({
                    ...updatedUnitDetails,
                    documents: updatedDocuments,
                });

                setUnitOriginal(unit);
                showAlert("success", t("pages.unit_detail.save_success_message"));
            } catch (error) {
                console.log(`Error while updating unit (id: ${unitId}):`, error);
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsUpdating(false);
            }
        }
    }, [unit, purchaseContractFile, titleDeedFile, oqoodFile, files, internalFiles, snaggingFile, deSnaggingFile, pictures, unitId, setUnit, showAlert, t]);

    const onDeleteUnitDocument = useCallback(async (document: IDocument) => {
        if (unit) {
            const documents = unit.documents.filter(file => file.document_id !== document.document_id);
            setUnit({ ...unit, documents });
            setUnitOriginal({ ...unit, documents });
        }
    }, [unit, setUnit, setUnitOriginal]);

    const onPictureDeleted = useCallback(async (picture_id: number) => {
        const updatePictures = (unit: IUnit): IUnit => {
            const updatedPictures = unit.pictures.filter(picture => picture.picture_id !== picture_id);
            return { ...unit, pictures: updatedPictures };
        };

        useUnitDetailStore.setState(prevState => {
            if (!prevState.unit) return prevState;
            const updatedUnit = updatePictures(prevState.unit);
            return { unit: updatedUnit };
        });

        setUnitOriginal(prevUnitOriginal => {
            if (!prevUnitOriginal) return prevUnitOriginal;
            return updatePictures(prevUnitOriginal);
        });
    }, [setUnitOriginal]);

    const onDeleteUnit = useCallback(async () => {
        try {
            setIsDeleteModalVisible(false);
            setIsDeleting(true);

            const unitDeleted = await deleteUnit(Number(unitId));
            if (unitDeleted) {
                navigate("/units");
                showAlert("success", t("pages.unit_detail.delete_success_message"));
            }
        } catch (error) {
            console.log('Error while deleting unit:', error);
            // @ts-ignore
            showAlert("error", error.message);
        } finally {
            setIsDeleting(false);
        }
    }, [unitId, t, setIsDeleting, showAlert, navigate]);

    const dataHasBeenChanged = useMemo(() => {
        return !_.isEqual(unit, unitOriginal) || purchaseContractFile || titleDeedFile || oqoodFile || files.length > 0 || internalFiles.length > 0 || snaggingFile || deSnaggingFile || pictures.length > 0;
    }, [unit, unitOriginal, purchaseContractFile, titleDeedFile, oqoodFile, files, internalFiles, snaggingFile, deSnaggingFile, pictures]);

    // Calculate document counts for badges
    const documentCounts = useMemo(() => {
        if (!unit) return {
            [DocumentTab.PURCHASE]: 0,
            [DocumentTab.TITLE_DEED]: 0,
            [DocumentTab.OQOOD]: 0,
            [DocumentTab.SNAGGING]: 0,
            [DocumentTab.OTHER]: 0,
            [DocumentTab.PHOTOS]: 0
        };

        return {
            [DocumentTab.PURCHASE]: unit.documents.filter(document =>
                document.internal_document === false &&
                document.document_type === DocumentType.PURCHASE_CONTRACT
            ).length + (purchaseContractFile ? 1 : 0),

            [DocumentTab.TITLE_DEED]: unit.documents.filter(document =>
                document.internal_document === false &&
                document.document_type === DocumentType.TITLE_DEED
            ).length + (titleDeedFile ? 1 : 0),

            [DocumentTab.OQOOD]: unit.documents.filter(document =>
                document.internal_document === false &&
                document.document_type === DocumentType.OQOOD
            ).length + (oqoodFile ? 1 : 0),

            [DocumentTab.SNAGGING]: (
                unit.documents.filter(document =>
                    document.internal_document === false &&
                    document.document_type === DocumentType.SNAGGING_REPORT
                ).length +
                unit.documents.filter(document =>
                    document.internal_document === false &&
                    document.document_type === DocumentType.DE_SNAGGING_REPORT
                ).length +
                (snaggingFile ? 1 : 0) +
                (deSnaggingFile ? 1 : 0)
            ),

            [DocumentTab.OTHER]: unit.documents.filter(document =>
                document.internal_document === false &&
                !document.document_type
            ).length + (files.length || 0),

            [DocumentTab.PHOTOS]: unit.pictures.length + (pictures.length || 0)
        };
    }, [unit, purchaseContractFile, titleDeedFile, oqoodFile, snaggingFile, deSnaggingFile, files, pictures]);

    return (
        <div className="no-select unit-detail-page">
            {isLoading || isDeleting ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
                    <Lottie animationData={isLoading ? LoadingAnim : DeleteAnim} loop={true} style={{ height: isLoading ? 100 : 150 }} />
                </div>
            ) : (
                unit && (
                    <>
                        {/* SECTION TITLE */}
                        <div className="d-flex justify-content-between align-items-center">
                            {unit && (
                                <>
                                    <div className="d-flex align-items-center" style={{ gap: 24 }}>
                                        <div>
                                            <div className="d-flex">
                                                <h4 className="headline m-0 me-2">Unit {unit.unit_number} | {unit.property.property_name}</h4>
                                            </div>
                                            <p style={{ margin: 0, fontSize: 14 }}>{t("pages.unit_detail.subtitle", { date: getFormattedDate(unit.created_at) })} | ID: {unit.unit_id}</p>
                                        </div>
                                        {unit.pm_contract && (
                                            <Tag
                                                type="package"
                                                variant={unit.pm_contract.package === "default" ? "standard" : unit.pm_contract.package as "basic" | "premium"}
                                                size="L"
                                            />
                                        )}
                                    </div>

                                    {/* DESKTOP BUTTON */}
                                    {hasEditPermission && (
                                        <div className="d-none d-md-flex">
                                            {!isUpdating && hasDeletePermission && (
                                                <CustomButton
                                                    className="d-none d-md-block me-3"
                                                    title={t("buttons.delete")}
                                                    color="red"
                                                    isLoading={isDeleting}
                                                    onClick={() => setIsDeleteModalVisible(true)}
                                                />
                                            )}
                                            <CustomButton
                                                className="d-none d-md-block"
                                                title={t("buttons.save")}
                                                isLoading={isUpdating}
                                                disabled={!dataHasBeenChanged}
                                                onClick={onClickUpdateUnit}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>

                        <div className="row mt-3 gy-3">
                            <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                                <div className="row gy-4">
                                    <div className="col-12">
                                        <UnitDetails unit={unit} leasesAmount={0} />
                                    </div>
                                    <div className="col-12">
                                        <OwnerDetails
                                            employeeRoleId={employeeRoleId}
                                            propertyId={unit.property.property_id}
                                            unitId={unit.unit_id}
                                            owners={unit.owners}
                                            onUnitOwnerAdded={addUnitOwner}
                                            onUnitOwnerUpdated={updateUnitOwner}
                                            onUnitOwnerDeleted={deleteUnitOwner}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <TenantDetails
                                            employeeRoleId={employeeRoleId}
                                            propertyId={unit.property.property_id}
                                            unitId={unit.unit_id}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-7 col-xl-8">
                                <div className="row gy-4">

                                    {/* UNIT STATES SECTION */}
                                    <div className="col-12">
                                        <UnitConditionSection
                                            employeeRoleId={employeeRoleId}
                                            unit={unit}
                                            onUpdateUnit={setUnit}
                                        />
                                    </div>

                                    {/* UITILITY OVERVIEW */}
                                    <div className="col-12">
                                        <UnitUtilitySection
                                            employeeRoleId={employeeRoleId}
                                            unit={unit}
                                            onUpdateUnit={setUnit}
                                        />
                                    </div>

                                    {/* GENERAL INFORMATION */}
                                    <div className="col-12">
                                        <UnitGeneralSection
                                            employeeRoleId={employeeRoleId}
                                            unit={unit}
                                            onUpdateUnit={setUnit}
                                        />
                                    </div>

                                    {/* UNIT PARKING SPACE SECTION */}
                                    <ParkingSpaceSection
                                        property_id={unit.property.property_id}
                                        unit_id={unit.unit_id}
                                        parkingSpaces={unit.parking_spaces}
                                        onAddParkingSpace={addParkingSpace}
                                        onDeleteParkingSpace={removeParkingSpace}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="documents-container">
                            <h4 className="mb-3">{t("components.document_section.documents", "Documents")}</h4>

                            <div className="document-tabs">
                                <div
                                    className={`document-tab ${activeDocumentTab === DocumentTab.PURCHASE ? 'active' : ''}`}
                                    onClick={() => setActiveDocumentTab(DocumentTab.PURCHASE)}
                                >
                                    {t("components.document_section.purchase_contract", "Purchase Contract")}
                                    {documentCounts[DocumentTab.PURCHASE] > 0 &&
                                        <span className="document-tab-badge">{documentCounts[DocumentTab.PURCHASE]}</span>}
                                </div>

                                <div
                                    className={`document-tab ${activeDocumentTab === DocumentTab.TITLE_DEED ? 'active' : ''}`}
                                    onClick={() => setActiveDocumentTab(DocumentTab.TITLE_DEED)}
                                >
                                    {t("components.document_section.title_deed", "Title Deed")}
                                    {documentCounts[DocumentTab.TITLE_DEED] > 0 &&
                                        <span className="document-tab-badge">{documentCounts[DocumentTab.TITLE_DEED]}</span>}
                                </div>

                                <div
                                    className={`document-tab ${activeDocumentTab === DocumentTab.OQOOD ? 'active' : ''}`}
                                    onClick={() => setActiveDocumentTab(DocumentTab.OQOOD)}
                                >
                                    {t("components.document_section.oqood", "Oqood")}
                                    {documentCounts[DocumentTab.OQOOD] > 0 &&
                                        <span className="document-tab-badge">{documentCounts[DocumentTab.OQOOD]}</span>}
                                </div>

                                <div
                                    className={`document-tab ${activeDocumentTab === DocumentTab.SNAGGING ? 'active' : ''}`}
                                    onClick={() => setActiveDocumentTab(DocumentTab.SNAGGING)}
                                >
                                    {t("components.document_section.snagging_reports", "Snagging Reports")}
                                    {documentCounts[DocumentTab.SNAGGING] > 0 &&
                                        <span className="document-tab-badge">{documentCounts[DocumentTab.SNAGGING]}</span>}
                                </div>

                                <div
                                    className={`document-tab ${activeDocumentTab === DocumentTab.OTHER ? 'active' : ''}`}
                                    onClick={() => setActiveDocumentTab(DocumentTab.OTHER)}
                                >
                                    {t("components.document_section.other_documents", "Other Documents")}
                                    {documentCounts[DocumentTab.OTHER] > 0 &&
                                        <span className="document-tab-badge">{documentCounts[DocumentTab.OTHER]}</span>}
                                </div>

                                <div
                                    className={`document-tab ${activeDocumentTab === DocumentTab.PHOTOS ? 'active' : ''}`}
                                    onClick={() => setActiveDocumentTab(DocumentTab.PHOTOS)}
                                >
                                    {t("components.document_section.photos", "Photos")}
                                    {documentCounts[DocumentTab.PHOTOS] > 0 &&
                                        <span className="document-tab-badge">{documentCounts[DocumentTab.PHOTOS]}</span>}
                                </div>
                            </div>

                            <div className="document-tab-content">
                                {(() => {
                                    switch (activeDocumentTab) {
                                        case DocumentTab.PHOTOS:
                                            return (
                                                <div key="photos" className="tab-pane-content">
                                                    <UnitPicturesSection
                                                        employeeRoleId={employeeRoleId}
                                                        unitId={unit.unit_id}
                                                        pictures={unit.pictures}
                                                        newPictures={pictures}
                                                        onPictureDeleted={onPictureDeleted}
                                                        onUpdatePictures={setPictures}
                                                    />
                                                </div>
                                            );
                                        case DocumentTab.PURCHASE:
                                            return (
                                                <div key="purchase" className="tab-pane-content">
                                                    <FileUploadSection
                                                        className="mt-0"
                                                        fileUploadId="unit-upload-purchase-contract"
                                                        title={t("components.document_section.provide_purchase_contract_title")}
                                                        subtitle={t("components.document_section.provide_documents_subtitle")}
                                                        documents={unit.documents.filter(document => document.internal_document === false && document.document_type === DocumentType.PURCHASE_CONTRACT)}
                                                        uploadedFiles={purchaseContractFile ? [purchaseContractFile] : []}
                                                        onDeleteDocument={onDeleteUnitDocument}
                                                        onUpdateUploadedFiles={(files) => setPurchaseContractFile(files[0])}
                                                        hasEditPermission={hasEditPermission}
                                                        maxFilesToUpload={1}
                                                        isDefaultExpanded
                                                    />
                                                </div>
                                            );
                                        case DocumentTab.TITLE_DEED:
                                            return (
                                                <div key="title-deed" className="tab-pane-content">
                                                    <FileUploadSection
                                                        className="mt-0"
                                                        fileUploadId="unit-upload-title-deed"
                                                        title={t("components.document_section.provide_title_deed_title")}
                                                        subtitle={t("components.document_section.provide_documents_subtitle")}
                                                        documents={unit.documents.filter(document => document.internal_document === false && document.document_type === DocumentType.TITLE_DEED)}
                                                        uploadedFiles={titleDeedFile ? [titleDeedFile] : []}
                                                        onDeleteDocument={onDeleteUnitDocument}
                                                        onUpdateUploadedFiles={(files) => setTitleDeedFile(files[0])}
                                                        hasEditPermission={hasEditPermission}
                                                        maxFilesToUpload={1}
                                                        isDefaultExpanded
                                                    />
                                                </div>
                                            );
                                        case DocumentTab.OQOOD:
                                            return (
                                                <div key="oqood" className="tab-pane-content">
                                                    <FileUploadSection
                                                        className="mt-0"
                                                        fileUploadId="unit-upload-oqood"
                                                        title={t("components.document_section.provide_oqood_title")}
                                                        subtitle={t("components.document_section.provide_documents_subtitle")}
                                                        documents={unit.documents.filter(document => document.internal_document === false && document.document_type === DocumentType.OQOOD)}
                                                        uploadedFiles={oqoodFile ? [oqoodFile] : []}
                                                        onDeleteDocument={onDeleteUnitDocument}
                                                        onUpdateUploadedFiles={(files) => setOqoodFile(files[0])}
                                                        hasEditPermission={hasEditPermission}
                                                        maxFilesToUpload={1}
                                                        isDefaultExpanded
                                                    />
                                                </div>
                                            );
                                        case DocumentTab.SNAGGING:
                                            return (
                                                <div key="snagging" className="tab-pane-content">
                                                    <Row>
                                                        <Col xs={12} md={6}>
                                                            <FileUploadSection
                                                                className="mt-0"
                                                                fileUploadId="unit-upload-snagging-report"
                                                                title={t("components.document_section.provide_snagging_report_title")}
                                                                subtitle={t("components.document_section.provide_documents_subtitle")}
                                                                documents={unit.documents.filter(document => document.internal_document === false && document.document_type === DocumentType.SNAGGING_REPORT)}
                                                                uploadedFiles={snaggingFile ? [snaggingFile] : []}
                                                                onDeleteDocument={onDeleteUnitDocument}
                                                                onUpdateUploadedFiles={(files) => setSnaggingFile(files[0])}
                                                                hasEditPermission={hasEditPermission}
                                                                maxFilesToUpload={1}
                                                                isDefaultExpanded
                                                            />
                                                        </Col>
                                                        <Col xs={12} md={6}>
                                                            <FileUploadSection
                                                                className="mt-0"
                                                                fileUploadId="unit-upload-de-snagging-report"
                                                                title={t("components.document_section.provide_de_snagging_report_title")}
                                                                subtitle={t("components.document_section.provide_documents_subtitle")}
                                                                documents={unit.documents.filter(document => document.internal_document === false && document.document_type === DocumentType.DE_SNAGGING_REPORT)}
                                                                uploadedFiles={deSnaggingFile ? [deSnaggingFile] : []}
                                                                onDeleteDocument={onDeleteUnitDocument}
                                                                onUpdateUploadedFiles={(files) => setDeSnaggingFile(files[0])}
                                                                hasEditPermission={hasEditPermission}
                                                                maxFilesToUpload={1}
                                                                isDefaultExpanded
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            );
                                        case DocumentTab.OTHER:
                                            return (
                                                <div key="other" className="tab-pane-content">
                                                    <FileUploadSection
                                                        className="mt-0"
                                                        fileUploadId="unit-upload-other-documents"
                                                        title={t("components.document_section.available_documents_title", { number: unit.documents.filter(document => !document.internal_document && !document.document_type).length })}
                                                        subtitle={t("components.document_section.provide_documents_subtitle")}
                                                        documents={unit.documents.filter(document => document.internal_document === false && !document.document_type)}
                                                        uploadedFiles={files}
                                                        onDeleteDocument={onDeleteUnitDocument}
                                                        onUpdateUploadedFiles={setFiles}
                                                        hasEditPermission={hasEditPermission}
                                                        isDefaultExpanded
                                                    />
                                                </div>
                                            );
                                        default:
                                            return null;
                                    }
                                })()}
                            </div>
                        </div>

                        {/* MOBILE BUTTON */}
                        {hasEditPermission && (
                            <div className="d-md-none">
                                <CustomButton
                                    className="mt-4 w-100"
                                    title={t("buttons.save")}
                                    isLoading={isUpdating}
                                    disabled={!dataHasBeenChanged}
                                    onClick={onClickUpdateUnit}
                                />
                                {!isUpdating && hasDeletePermission && (
                                    <CustomButton
                                        className="mt-3 w-100"
                                        title={t("buttons.delete")}
                                        color="red"
                                        isLoading={isDeleting}
                                        onClick={() => setIsDeleteModalVisible(true)}
                                    />
                                )}
                            </div>
                        )}
                    </>
                )
            )}

            {/* DELETE CONFIRMATION MODAL */}
            {
                isDeleteModalVisible && hasEditPermission && (
                    <DeleteConfirmationModal
                        title={t("pages.unit_detail.delete_modal.title")}
                        message={t("pages.unit_detail.delete_modal.message")}
                        onClose={() => setIsDeleteModalVisible(false)}
                        onDelete={onDeleteUnit}
                    />
                )
            }
        </div>
    );
};

export default UnitDetailPage;
