import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandableSection from '../../../../components/expandable-section/expandable-section';
import CustomButton from '../../../../components/custom-button/custom-button';
import SidebarSelectField, { SidebarSelectFieldOptions } from '../../../../modals/sidebar/components/sidebar-select-field/sidebar-select-field';
import usePaymentFilterStore from '../../../../stores/paymentFilterStore';
import { formatPaymentMethod, formatPaymentPurpose, formatPaymentStatus, PaymentMethod, PaymentPurpose, PaymentStatus } from '../../../../services/paymentService';
import CustomInputField from '../../../../components/custom-input-field/custom-input-field';
import { useUnits } from '../../../../hooks/useUnits';

interface PaymentFiltersProps {
    onFilterUpdated: () => void;
}

const PaymentFilters: React.FC<PaymentFiltersProps> = ({ onFilterUpdated }) => {
    const { t } = useTranslation();

    const purposeOptions: SidebarSelectFieldOptions[] = Object.values(PaymentPurpose).map((purpose) => ({
        label: formatPaymentPurpose(purpose, t),
        value: purpose,
    }));

    const methodOptions: SidebarSelectFieldOptions[] = Object.values(PaymentMethod).map((method) => ({
        label: formatPaymentMethod(method, t),
        value: method,
    }));

    const statusOptions: SidebarSelectFieldOptions[] = Object.values(PaymentStatus).map((status) => ({
        label: formatPaymentStatus(status, t),
        value: status,
    }));

    const { selectedProperty, selectedUnit, amount, purpose, method, status, isExpanded, setFilters, setIsExpanded, setSelectedUnit } = usePaymentFilterStore();

    // States
    const [unitsSearchQuery, setUnitsSearchQuery] = useState<string>("");

    // Local state for filters
    const [localUnit, setLocalUnit] = useState<string>(selectedUnit ? selectedUnit.toString() : "");
    const [localAmount, setLocalAmount] = useState<string>(amount);
    const [localPurpose, setLocalPurpose] = useState<PaymentPurpose | null>(purpose);
    const [localMethod, setLocalMethod] = useState<PaymentMethod | null>(method);
    const [localStatus, setLocalStatus] = useState<PaymentStatus | null>(status);

    // State to track whether filters have changed
    const [filtersChanged, setFiltersChanged] = useState(false);

    // Zustand store
    const { units, setSize: setUnitsSize, isReachingEnd: isReachingUnitsEnd } = useUnits(selectedProperty, null, unitsSearchQuery);

    // Check if any filter has been changed
    useEffect(() => {
        const hasChanges =
            localUnit !== selectedUnit?.toString() ||
            localAmount !== amount ||
            localPurpose !== purpose ||
            localMethod !== method ||
            localStatus !== status;

        setFiltersChanged(hasChanges);
    }, [localUnit, localAmount, localPurpose, localMethod, localStatus, selectedUnit, amount, purpose, method, status]);

    const handleApplyFilters = useCallback(() => {
        setSelectedUnit(localUnit.length > 0 ? Number(localUnit) : null);
        setFilters(amount, localPurpose, localMethod, localStatus);
        onFilterUpdated();
    }, [localUnit, localAmount, localPurpose, localMethod, localStatus]);

    const onSearchUnit = useCallback((searchQuery: string, page: number) => {
        setUnitsSearchQuery(searchQuery);
        setUnitsSize(page);
    }, [setUnitsSize]);

    return (
        <ExpandableSection
            className="mt-4"
            title={t("labels.filter.show")}
            isDefaultExpanded={isExpanded}
            onToggle={setIsExpanded}
        >
            <div className="row">
                {/* Unit Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <SidebarSelectField
                        id="unit"
                        label={t("labels.unit")}
                        placeholder={t("placeholders.search")}
                        value={localUnit}
                        onChange={setLocalUnit}
                        options={units.map(unit => ({
                            label: unit.unit_number,
                            value: unit.unit_id.toString()
                        }))}
                        onSearch={onSearchUnit}
                        isEndReached={isReachingUnitsEnd}
                        labelOnTop
                        isSearchable
                        showClearIcon
                    />
                </div>

                {/* Amount Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <CustomInputField
                        id="amountInput"
                        label={t('labels.amount')}
                        placeholder="1-2"
                        value={localAmount}
                        onChange={setLocalAmount}
                        onClear={() => setLocalAmount("")}
                        type="text"
                        inputStyle={{ width: '100%' }}
                    />
                </div>

                {/* Purpose Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <SidebarSelectField
                        id="payment-filter-purpose-selection"
                        label={t("labels.purpose_type")}
                        placeholder={t("placeholders.select")}
                        value={localPurpose ?? undefined}
                        onChange={(value: string) => {
                            if (!value.trim()) {
                                setLocalPurpose(null);
                            } else {
                                const mappedValue = value as PaymentPurpose;
                                setLocalPurpose(mappedValue);
                            }
                        }}
                        options={purposeOptions}
                        showClearIcon
                        labelOnTop
                    />
                </div>

                {/* Method Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <SidebarSelectField
                        id="payment-filter-method-selection"
                        label={t("labels.payment_method")}
                        placeholder={t("placeholders.select")}
                        value={localMethod ?? undefined}
                        onChange={(value: string) => {
                            if (!value.trim()) {
                                setLocalMethod(null);
                            } else {
                                const mappedValue = value as PaymentMethod;
                                setLocalMethod(mappedValue);
                            }
                        }}
                        options={methodOptions}
                        showClearIcon
                        labelOnTop
                    />
                </div>

                {/* Status Filter */}
                <div className="col-6 col-md-3 col-xxl-2">
                    <SidebarSelectField
                        id="payment-filter-method-selection"
                        label={t("labels.status")}
                        placeholder={t("placeholders.select")}
                        value={localStatus ?? undefined}
                        onChange={(value: string) => {
                            if (!value.trim()) {
                                setLocalStatus(null);
                            } else {
                                const mappedValue = value as PaymentStatus;
                                setLocalStatus(mappedValue);
                            }
                        }}
                        options={statusOptions}
                        showClearIcon
                        labelOnTop
                    />
                </div>

                {/* Apply Button */}
                <div className="col-6 col-md-3 col-xxl-2 d-flex align-items-center">
                    <CustomButton
                        color="green"
                        title={t('buttons.apply_filters')}
                        onClick={handleApplyFilters}
                        isLoading={false}
                        disabled={!filtersChanged}
                    />
                </div>
            </div>
        </ExpandableSection>
    );
};

export default PaymentFilters;
