import '../sidemodal.css';
import './lease-sidemodal.css';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import CustomButton from '../../../components/custom-button/custom-button';
import SidebarSelectField from '../components/sidebar-select-field/sidebar-select-field';
import useAlertStore from '../../../stores/alertStore';
import { useTranslation } from 'react-i18next';
import { UploadedFile } from '../../../components/file-upload/file-upload';
import { ILease, ILeaseOccupant, createLease, uploadLeaseDocuments } from '../../../services/leaseService';
import { useTenants } from '../../../hooks/useTenants';
import { useUnits } from '../../../hooks/useUnits';
import { useProperties } from '../../../hooks/useProperties';
import LeaseDocumentUploadSection from './components/lease-document-upload-section';
import LeaseGeneralInfoSection from './components/lease-general-info-section';
import LeasePaymentInfoSection from './components/lease-payment-info-section';
import { PaymentMethod, PaymentPurpose, PaymentStatus } from '../../../services/paymentService';
import { LeasePaymentTableItem } from './components/lease-payment-table-section/lease-payment-table-section';
import { fetchUnit, IUnit } from '../../../services/unitService';
import LeaseOccupantsSection from './components/lease-occupants-section';
import { createLeasePayments, ICreateLeasePayment } from '../../../services/leasePaymentService';

interface LeaseSidebarModalProps {
    isVisible: boolean;
    propertyId?: number;
    unitId?: number;
    onClose: () => void;
    onLeaseCreated: (lease: ILease) => void;
}

const LeaseSidebarModal: React.FC<LeaseSidebarModalProps> = ({ isVisible, propertyId, unitId, onClose, onLeaseCreated }) => {
    const { t } = useTranslation();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tenantsSearchQuery, setTenantsSearchQuery] = useState<string>("");
    const [unitsSearchQuery, setUnitsSearchQuery] = useState<string>("");

    // Lease
    const [property, setProperty] = useState<string>(propertyId ? propertyId.toString() : "");
    const [unit, setUnit] = useState<string>(unitId ? unitId.toString() : "");
    const [bedroom, setBedroom] = useState<number>(0);
    const [tenant, setTenant] = useState<string>("");

    // Lease general information
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [occupants, setOccupants] = useState<ILeaseOccupant[]>([]);
    const [remark, setRemark] = useState<string>("");
    const [startDateError, setStartDateError] = useState<string>("");
    const [endDateError, setEndDateError] = useState<string>("");

    // Payment information
    const [annualRent, setAnnualRent] = useState<string>("");
    const [totalRent, setTotalRent] = useState<string>("");
    const [deposit, setDeposit] = useState<string>("");
    const [petDeposit, setPetDeposit] = useState<string>("");
    const [rentPayments, setRentPayments] = useState<string>("");
    const [annualRentError, setAnnualRentError] = useState<string>("");
    const [totalRentError, setTotalRentError] = useState<string>("");
    const [depositError, setDepositError] = useState<string>("");
    const [rentPaymentsError, setRentPaymentsError] = useState<string>("");

    // Lease file
    const [file, setFile] = useState<UploadedFile | null>(null);
    const [error, setError] = useState<string>("");

    // Payment cards
    const [payments, setPayments] = useState<LeasePaymentTableItem[]>([]);

    // Hooks
    const { properties, isReachingEnd: isReachingPropertiesEnd } = useProperties();
    const { tenants, setSize: setTenantsSize, isReachingEnd: isReachingTenantsEnd } = useTenants(null, tenantsSearchQuery);
    const { units, setSize: setUnitsSize, isReachingEnd: isReachingUnitsEnd } = useUnits(property.length > 0 ? Number(property) : null, unitId ?? null, unitsSearchQuery);

    // Clean states
    useEffect(() => {
        if (!isVisible) {
            setProperty("");
            setUnit("");
            setTenant("");
            setStartDate(null);
            setEndDate(null);
            setOccupants([]);
            setRemark("");
            setStartDateError("");
            setEndDateError("");

            setAnnualRent("");
            setTotalRent("");
            setDeposit("");
            setPetDeposit("");
            setRentPayments("");
            setAnnualRentError("");
            setTotalRentError("");
            setDepositError("");
            setRentPaymentsError("");

            setFile(null);
            setError("");
            setPayments([]);
        }
    }, [isVisible]);

    useEffect(() => {
        if (propertyId) {
            setProperty(propertyId.toString());
        }
    }, [propertyId]);

    useEffect(() => {
        if (unitId) {
            setUnit(unitId.toString());
        }
    }, [unitId]);

    useEffect(() => {
        if (unit) {
            const fetchUnitAnnualRent = async () => {
                try {
                    const fetchedUnit = await fetchUnit(Number(unit));
                    if (fetchedUnit.minimum_annual_rent) {
                        setBedroom(fetchedUnit.bedroom);
                        setAnnualRent(fetchedUnit.minimum_annual_rent.toString());
                    }
                } catch (error) {
                    console.log('Error while fetching unit in lease modal:', error);
                }
            }
            fetchUnitAnnualRent();
        }
    }, [unit]);

    // Calculate payment cards
    useEffect(() => {
        if (startDate && endDate && totalRent && annualRent && deposit && rentPayments.length > 0) {
            const totalPayments = parseInt(rentPayments) + 1; // rentPayments + 1 for the security deposit
            const rentAmountPerPayment = parseFloat(totalRent) / parseInt(rentPayments);
            const paymentDueDates = [];
            
            // Berechne Admin Fee basierend auf Schlafzimmeranzahl
            const adminFeeAmount = bedroom === 0 ? 1600 : bedroom === 1 ? 2650 : 3700;
            
            // Starte mit der Admin Fee als erste Zahlung
            const paymentCards: LeasePaymentTableItem[] = [{
                paymentMethod: PaymentMethod.BANK_TRANSFER,
                paymentDueDate: new Date(startDate),
                paymentPurpose: PaymentPurpose.ADMIN_FEE,
                amount: adminFeeAmount
            }];

            // Generate payment due dates
            for (let i = 0; i < totalPayments - 1; i++) {
                const dueDate = new Date(startDate);
                dueDate.setMonth(dueDate.getMonth() + (i * 12) / parseInt(rentPayments));
                paymentDueDates.push(dueDate);
            }

            // Add payment cards for rent payments
            paymentDueDates.forEach((dueDate, index) => {
                paymentCards.push({
                    paymentMethod: PaymentMethod.CHEQUE,
                    paymentDueDate: dueDate,
                    paymentPurpose: PaymentPurpose.RENT,
                    amount: rentAmountPerPayment
                });
            });

            // Add payment card for the security deposit
            paymentCards.push({
                paymentMethod: PaymentMethod.CHEQUE,
                paymentDueDate: startDate,
                paymentPurpose: PaymentPurpose.SECURITY_DEPOSIT,
                amount: Number(deposit)
            });

            if (petDeposit.length > 0) {
                // Add payment card for the pet deposit
                paymentCards.push({
                    paymentMethod: PaymentMethod.CHEQUE,
                    paymentDueDate: startDate,
                    paymentPurpose: PaymentPurpose.PET_DEPOSIT,
                    amount: Number(petDeposit)
                });
            }

            // Sortiere die Zahlungen nach Fälligkeitsdatum (aufsteigend)
            const sortedPaymentCards = paymentCards.sort((a, b) => {
                // Wenn beide Zahlungen RENT sind, sortiere nach Fälligkeitsdatum
                if (a.paymentPurpose === PaymentPurpose.RENT && b.paymentPurpose === PaymentPurpose.RENT) {
                    return a.paymentDueDate.getTime() - b.paymentDueDate.getTime();
                }
                
                // Wenn nur Zahlung A den Zweck RENT hat, platziere sie am Ende
                if (a.paymentPurpose === PaymentPurpose.RENT) {
                    return 1;
                }
                
                // Wenn nur Zahlung B den Zweck RENT hat, platziere sie am Ende
                if (b.paymentPurpose === PaymentPurpose.RENT) {
                    return -1;
                }
                
                // Für alle anderen Zahlungen, sortiere nach Fälligkeitsdatum
                return a.paymentDueDate.getTime() - b.paymentDueDate.getTime();
            });

            setPayments(sortedPaymentCards);
        } else if (startDate) {
            // Wenn nur das Startdatum vorhanden ist, füge nur die Admin Fee hinzu
            const adminFeeAmount = bedroom === 0 ? 1600 : bedroom === 1 ? 2650 : 3700;
            
            const adminFeePayment: LeasePaymentTableItem = {
                paymentMethod: PaymentMethod.BANK_TRANSFER,
                paymentDueDate: new Date(startDate),
                paymentPurpose: PaymentPurpose.ADMIN_FEE,
                amount: adminFeeAmount
            };
            
            setPayments([adminFeePayment]);
        } else {
            setPayments([]);
        }
    }, [startDate, endDate, totalRent, annualRent, deposit, petDeposit, rentPayments, bedroom]);

    const onFileSelected = (selectedFile: UploadedFile) => {
        setFile(selectedFile);
    };

    // Create Lease
    const onHandleCreateLease = useCallback(async () => {
        let isValid = true;

        // Check if start date is not empty
        if (!startDate) {
            setStartDateError(t("invalid_input.start_date_empty"));
            isValid = false;
        }

        // Check if end date is not empty
        if (!endDate) {
            setEndDateError(t("invalid_input.end_date_empty"));
            isValid = false;
        }

        // Check if the annual rent is not empty
        if (!annualRent.trim()) {
            setAnnualRentError(t("invalid_input.annual_rent_empty"));
            isValid = false;
        }

        // Check if the total rent is not empty
        if (!totalRent.trim()) {
            setTotalRentError(t("invalid_input.total_rent_empty"));
            isValid = false;
        }

        // Check if the deposit is not empty
        if (!deposit.trim()) {
            setDepositError(t("invalid_input.deposit_empty"));
            isValid = false;
        }

        // Check if the deposit is not empty
        if (!rentPayments.trim()) {
            setRentPaymentsError(t("invalid_input.no_of_rent_payments_empty"));
            isValid = false;
        }

        if (isValid && unit && property.length > 0 && unit.length > 0 && tenant.length > 0 && payments.length > 0) {
            try {
                setError("");
                setIsLoading(true);

                const createdLease = await createLease(
                    Number(unit),
                    Number(tenant),
                    startDate!,
                    endDate!,
                    occupants,
                    Number(annualRent),
                    Number(totalRent),
                    Number(deposit),
                    petDeposit.length > 0 ? Number(petDeposit) : 0,
                    remark,
                    payments.filter(payment => payment.paymentPurpose === PaymentPurpose.RENT).length
                );

                // Prepare the payment data
                const createdPayments: ICreateLeasePayment[] = payments.map(payment => ({
                    lease_id: createdLease.lease_id,
                    due_date: payment.paymentDueDate,
                    amount: Number(payment.amount),
                    cheque_no: null,
                    bank_name: null,
                    remark: null,
                    method: payment.paymentMethod,
                    purpose: payment.paymentPurpose,
                    status: PaymentStatus.PENDING,
                }));

                // Create the payments
                if (createdPayments.length > 0) {
                    await createLeasePayments(createdPayments);
                }

                // Upload file
                if (file) {
                    await uploadLeaseDocuments(createdLease.lease_id, file.file);
                }

                onLeaseCreated(createdLease);

                onClose()
                showAlert("success", t("modals.create_lease.create_success_message"));
            } catch (error) {
                // @ts-ignore
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    }, [startDate, endDate, occupants, remark, annualRent, totalRent, deposit, petDeposit, rentPayments, property, unit, tenant, file, payments, onLeaseCreated, onClose, showAlert, t]);

    // Set end date after selecting start year to one year
    useEffect(() => {
        if (startDate) {
            const endDate = new Date(startDate);
            endDate.setFullYear(startDate.getFullYear() + 1);
            endDate.setDate(endDate.getDate() - 1);
            setEndDate(endDate);
        }
    }, [startDate]);

    const onSearchUnit = useCallback((searchQuery: string, page: number) => {
        setUnitsSearchQuery(searchQuery);
        setUnitsSize(page);
    }, []);

    const onSearchTenant = useCallback((searchQuery: string, page: number) => {
        setTenantsSearchQuery(searchQuery);
        setTenantsSize(page);
    }, []);

    const selectedUnitDate: IUnit | null = useMemo(() => {
        if (units.length > 0 && unitId) {
            return units.find(unit => unit.unit_id === unitId) ?? null;
        } else return null;
    }, [units, unitId]);

    return (
        <div className={`sidebar-modal-backdrop ${isVisible ? 'visible' : ''}`}>
            <div className={`sidebar-modal ${isVisible ? 'visible' : ''}`}>
                <div className="d-flex align-items-center justify-content-between my-3">
                    <h4 className="sidebar-modal-headline ms-3">{t("modals.create_lease.title")}</h4>
                    <button className="btn" onClick={onClose}>
                        <IoClose size={20} />
                    </button>
                </div>
                <div className="sidebar-modal-divider" />
                <div className="sidebar-modal-content">
                    <div className="sidebar-modal-scrollable-content">
                        <div className="row">
                            {!propertyId && (
                                <div className="col-12 col-md-6">
                                    <SidebarSelectField
                                        id="property"
                                        label={t("labels.property")}
                                        value={property}
                                        placeholder={t("placeholders.select")}
                                        onChange={setProperty}
                                        options={properties.map(property => ({
                                            label: property.property_name,
                                            value: property.property_id.toString()
                                        }))}
                                        isEndReached={isReachingPropertiesEnd}
                                        required
                                        labelOnTop
                                    />
                                </div>
                            )}
                            {!unitId && (
                                <div className="col-12 col-md-6">
                                    {property && (
                                        <SidebarSelectField
                                            id="unit"
                                            label={t("labels.unit")}
                                            placeholder={t("placeholders.search")}
                                            value={unit}
                                            onChange={setUnit}
                                            options={units.map(unit => ({
                                                label: unit.unit_number,
                                                value: unit.unit_id.toString()
                                            }))}
                                            onSearch={onSearchUnit}
                                            isEndReached={isReachingUnitsEnd}
                                            required
                                            labelOnTop
                                            isSearchable
                                        />
                                    )}
                                </div>
                            )}
                            {property && unit && (
                                <SidebarSelectField
                                    id="tenant"
                                    label={t("labels.tenant")}
                                    placeholder={t("placeholders.search")}
                                    value={tenant}
                                    onChange={setTenant}
                                    options={tenants.map(tenant => ({
                                        label: `${tenant.first_name} ${tenant.last_name}`,
                                        value: tenant.tenant_id.toString()
                                    }))}
                                    onSearch={onSearchTenant}
                                    isEndReached={isReachingTenantsEnd}
                                    required
                                    labelOnTop
                                    isSearchable
                                />
                            )}
                        </div>

                        {/* GENERAL SECTION */}
                        <LeaseGeneralInfoSection
                            startDate={startDate}
                            endDate={endDate}
                            remark={remark}
                            startDateError={startDateError}
                            endDateError={endDateError}
                            onStartDateChange={(value) => {
                                setStartDate(value);
                                setStartDateError("");
                            }}
                            onEndDateChange={(value) => {
                                setEndDate(value);
                                setEndDateError("");
                            }}
                            onRemarkChange={setRemark}
                        />

                        {/* OCCUPANTS SECTION */}
                        <LeaseOccupantsSection
                            occupants={occupants}
                            onUpdateOccupants={setOccupants}
                        />

                        {/* PAYMENT INFORMATION SECTION */}
                        <LeasePaymentInfoSection
                            payments={payments}
                            annualRent={annualRent}
                            totalRent={totalRent}
                            deposit={deposit}
                            rentPayments={rentPayments}
                            annualRentError={annualRentError}
                            totalRentError={totalRentError}
                            depositError={depositError}
                            rentPaymentsError={rentPaymentsError}
                            onAnnualRentChange={(value) => {
                                setAnnualRent(value);
                                setAnnualRentError("");
                            }}
                            onTotalRentChange={(value) => {
                                setTotalRent(value);
                                setTotalRentError("");
                            }}
                            onDepositChange={(value) => {
                                setDeposit(value);
                                setDepositError("");
                            }}
                            onPetDepositChange={setPetDeposit}
                            onRentPaymentsChange={(value) => {
                                setRentPayments(value);
                                setRentPaymentsError("");
                            }}
                            startDate={startDate}
                            endDate={endDate}
                            furnished={selectedUnitDate?.furnished ?? false}
                        />

                        {/* UPLOAD DOCUMENT SECTION */}
                        <LeaseDocumentUploadSection
                            file={file}
                            onFileSelected={onFileSelected}
                            onRemoveFile={() => setFile(null)}
                        />
                    </div>
                    <div className="store-investor-btn">
                        {error && (
                            <p className="text-danger">{error}</p>
                        )}
                        <CustomButton
                            className="w-100"
                            title={t("modals.create_lease.create_button")}
                            isLoading={isLoading}
                            onClick={onHandleCreateLease}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LeaseSidebarModal;
