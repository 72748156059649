import '../sidemodal.css';

import React, { useCallback, useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import CustomButton from '../../../components/custom-button/custom-button';
import SidebarSelectField, { SidebarSelectFieldOptions } from '../components/sidebar-select-field/sidebar-select-field';
import useAlertStore from '../../../stores/alertStore';
import { useTranslation } from 'react-i18next';
import ExpandableSection from '../../../components/expandable-section/expandable-section';
import { useUnits } from '../../../hooks/useUnits';
import { useProperties } from '../../../hooks/useProperties';
import DateSelect from '../../../components/date-select/date-select';
import { format } from 'date-fns';
import { createPMContract, deletePMContract, IPropertyManagementContract, PropertyManagementPackage, updatePMContract, uploadPMContractDocument } from '../../../services/pmContractService';
import FileUploadSection from '../../../components/file-upload-section/file-upload-section';
import { UploadedFile } from '../../../components/file-upload/file-upload';

interface AddPMContractSidebarModalProps {
    isVisible: boolean;
    selectedContract?: IPropertyManagementContract;
    propertyId?: number;
    unitId?: number;
    onClose: () => void;
    onAddContract: (contract: IPropertyManagementContract) => void;
    onUpdateContract?: (contract: IPropertyManagementContract) => void;
    onDeleteContract?: (contract_id: number) => void;
}

const AddPMContractSidebarModal: React.FC<AddPMContractSidebarModalProps> = ({ isVisible, selectedContract, propertyId, unitId, onClose, onAddContract, onUpdateContract, onDeleteContract }) => {
    const { t } = useTranslation();

    const pmPackageOptions: SidebarSelectFieldOptions[] = Object.values(PropertyManagementPackage).map((purpose) => ({
        label: purpose.replace(/-/g, ' ').replace(/\b\w/g, (s) => s.toUpperCase()),
        value: purpose,
    }));

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);

    const { properties, isReachingEnd: isReachingPropertiesEnd } = useProperties();

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [unitsSearchQuery, setUnitsSearchQuery] = useState<string>("");
    const [property, setProperty] = useState<string>(() => {
        if (propertyId) {
            return propertyId.toString();
        }
        if (properties.length > 0) {
            return properties[0].property_id.toString();
        }
        return "";
    });
    const [unit, setUnit] = useState<string>(unitId ? unitId.toString() : "");
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [signDate, setSignDate] = useState<Date | null>(null);
    const [pmPackage, setPMPackage] = useState<PropertyManagementPackage>(PropertyManagementPackage.BASIC);
    const [contractFile, setContractFile] = useState<UploadedFile | null>(null);
    const [startDateError, setStartDateError] = useState<string>("");

    // Hooks
    const { units, setSize: setUnitsSize, isReachingEnd: isReachingUnitsEnd } = useUnits(property.length > 0 ? Number(property) : null, unitId ?? null, unitsSearchQuery);

    // Clean states
    useEffect(() => {
        if (selectedContract) {
            setStartDate(new Date(selectedContract.start_date));
            setSignDate(selectedContract.sign_date ? new Date(selectedContract.sign_date) : null);
            setPMPackage(selectedContract.package);
        }
    }, [selectedContract]);

    useEffect(() => {
        if (!isVisible) {
            setStartDate(null);
            setSignDate(null);
            setContractFile(null)
            setPMPackage(PropertyManagementPackage.BASIC);
            setStartDateError("");
        }
    }, [isVisible]);

    // Create unit owner
    const onHandleCreatePMContract = useCallback(async () => {
        let isValid = true;

        // Check if start date is not empty
        if (!startDate) {
            setStartDateError(t("invalid_input.purchase_date_empty"));
            isValid = false;
        }

        if (isValid && property.length > 0 && unit.length > 0) {
            try {
                setIsLoading(true);

                let createdContract = await createPMContract(
                    Number(unit),
                    pmPackage,
                    format(startDate!, "yyyy-MM-dd"),
                    signDate ? format(signDate!, "yyyy-MM-dd") : null,
                );

                if (contractFile) {
                    const document = await uploadPMContractDocument(createdContract.contract_id, contractFile.file);
                    createdContract.document = document;
                }

                onAddContract(createdContract);

                showAlert("success", t("modals.pm_contract.add_success_message"));
            } catch (error) {
                console.log("Error while creating pm contract:", error);
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsLoading(false);
            }
        }
    }, [property, unit, startDate, signDate, pmPackage, contractFile, t]);

    const onHandleUpdatePMContract = useCallback(async () => {
        let isValid = true;

        // Check if start date is not empty
        if (!startDate) {
            setStartDateError(t("invalid_input.purchase_date_empty"));
            isValid = false;
        }

        if (isValid && selectedContract && onUpdateContract) {
            try {
                setIsUpdating(true);

                let updatedContract = await updatePMContract(
                    selectedContract.contract_id,
                    pmPackage,
                    format(startDate!, "yyyy-MM-dd"),
                    signDate ? format(signDate!, "yyyy-MM-dd") : null,
                );

                if (contractFile) {
                    const document = await uploadPMContractDocument(updatedContract.contract_id, contractFile.file);
                    updatedContract.document = document;
                }

                showAlert("success", t("modals.pm_contract.update_success_message"));

                onUpdateContract(updatedContract);
            } catch (error) {
                console.log("Error while updating pm contract:", error);
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsUpdating(false);
            }
        }
    }, [selectedContract, pmPackage, startDate, signDate, contractFile, t]);

    const onHandleDeletePMContract = useCallback(async () => {
        if (!selectedContract || !onDeleteContract) return;

        try {
            setIsDeleting(true);
            const deleted = await deletePMContract(selectedContract.contract_id);
            if (deleted) {
                showAlert("success", t("modals.pm_contract.delete_success_message"));

                onDeleteContract(selectedContract.contract_id);
            }
        } catch (error) {
            console.log("Error while deleting pm contract: ", error);
        } finally {
            setIsDeleting(false);
        }
    }, [selectedContract]);

    const onHandleDeleteDocument = useCallback(() => {
        if (selectedContract && onUpdateContract) {
            onUpdateContract({ ...selectedContract, document: null });
        }
    }, [selectedContract]);

    const onSearchUnit = useCallback((searchQuery: string, page: number) => {
        setUnitsSearchQuery(searchQuery);
        setUnitsSize(page);
    }, [setUnitsSize]);

    const onContractFileSelected = (selectedFiles: UploadedFile[]) => {
        setContractFile(selectedFiles[0]);
    };

    return (
        <div className={`sidebar-modal-backdrop ${isVisible ? 'visible' : ''}`}>
            <div className={`sidebar-modal ${isVisible ? 'visible' : ''}`}>
                <div className="d-flex align-items-center justify-content-between my-3">
                    <h4 className="sidebar-modal-headline ms-3">{selectedContract ? t("modals.pm_contract.edit_title") : t("modals.pm_contract.add_title")}</h4>
                    <button className="btn" onClick={onClose}>
                        <IoClose size={20} />
                    </button>
                </div>
                <div className="sidebar-modal-divider" />
                <div className="sidebar-modal-content">
                    <div className="sidebar-modal-scrollable-content">
                        <div className="row">
                            {!selectedContract && (
                                <>
                                    {!propertyId && (
                                        <div className="col-12 col-md-6">
                                            <SidebarSelectField
                                                id="property"
                                                label={t("labels.property")}
                                                value={property}
                                                onChange={setProperty}
                                                options={properties.map(property => ({
                                                    label: property.property_name,
                                                    value: property.property_id.toString()
                                                }))}
                                                isEndReached={isReachingPropertiesEnd}
                                                required
                                                labelOnTop
                                            />
                                        </div>
                                    )}
                                    {!unitId && (
                                        <div className="col-12 col-md-6">
                                            {property && (
                                                <SidebarSelectField
                                                    id="unit"
                                                    label={t("labels.unit")}
                                                    placeholder={t("placeholders.search")}
                                                    value={unit}
                                                    onChange={setUnit}
                                                    options={units.map(unit => ({
                                                        label: unit.unit_number,
                                                        value: unit.unit_id.toString()
                                                    }))}
                                                    onSearch={onSearchUnit}
                                                    isEndReached={isReachingUnitsEnd}
                                                    required
                                                    labelOnTop
                                                    isSearchable
                                                />
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>

                        {/* GENERAL SECTION */}
                        <ExpandableSection
                            className={selectedContract ? "" : "mt-4"}
                            title={t("modals.pm_contract.general_title")}
                            isDefaultExpanded
                        >
                            <div className="row">
                                <div className="col-12">
                                    <SidebarSelectField
                                        id="pm-contract-filter-package-selection"
                                        label={t("labels.pm_package")}
                                        placeholder={t("placeholders.select")}
                                        value={pmPackage ?? undefined}
                                        onChange={(value: string) => {
                                            const mappedValue = value as PropertyManagementPackage;
                                            setPMPackage(mappedValue);
                                        }}
                                        options={pmPackageOptions}
                                        showClearIcon
                                        labelOnTop
                                    />
                                </div>
                                <div className="col-12 col-xxl-6">
                                    <DateSelect
                                        id="pm-contract-start-date"
                                        label={t("labels.start_date")}
                                        errorMessage={startDateError}
                                        placeholder={t("placeholders.select_date_and_time")}
                                        date={startDate}
                                        onChange={(value) => {
                                            setStartDate(value);
                                            setStartDateError("");
                                        }}
                                        labelOnTop
                                        required
                                    />
                                </div>
                                <div className="col-12 col-xxl-6">
                                    <DateSelect
                                        id="pm-contract-sign-date"
                                        label={t("labels.sign_date")}
                                        placeholder={t("placeholders.select_date_and_time")}
                                        date={signDate}
                                        onChange={setSignDate}
                                        labelOnTop
                                    />
                                </div>
                            </div>

                            {/* UPLOAD PM CONTRACT SECTION */}
                            <FileUploadSection
                                fileUploadId="create-pm-contract-upload-contract"
                                title={t("modals.pm_contract.upload_contract")}
                                documents={selectedContract?.document ? [selectedContract.document] : []}
                                uploadedFiles={contractFile ? [contractFile] : []}
                                onUpdateUploadedFiles={onContractFileSelected}
                                onDeleteDocument={onHandleDeleteDocument}
                                maxFilesToUpload={1}
                                isHeadlineLarge={false}
                                removePadding
                                hasEditPermission
                                isDefaultExpanded
                            />
                        </ExpandableSection>
                    </div>
                    <div className="store-investor-btn">
                        {selectedContract ? (
                            <div className="d-flex">
                                <CustomButton
                                    className="w-100"
                                    title={t("modals.pm_contract.update_button")}
                                    isLoading={isUpdating}
                                    onClick={onHandleUpdatePMContract}
                                    disabled={isDeleting || isUpdating}
                                />
                                <CustomButton
                                    className="w-100 ms-3"
                                    color="red"
                                    title={t("modals.pm_contract.delete_button")}
                                    isLoading={isDeleting}
                                    onClick={onHandleDeletePMContract}
                                    disabled={isDeleting || isUpdating}
                                />
                            </div>
                        ) : (
                            <CustomButton
                                className="w-100"
                                title={t("modals.pm_contract.add_button")}
                                isLoading={isLoading}
                                onClick={onHandleCreatePMContract}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddPMContractSidebarModal;
