import React from 'react';
import { useTranslation } from 'react-i18next';
import ExpandableSection from '../../../../components/expandable-section/expandable-section';
import FileCard from '../../../../components/file-card/file-card';
import FileUpload, { UploadedFile } from '../../../../components/file-upload/file-upload';

interface LeaseDocumentUploadSectionProps {
    file: UploadedFile | null;
    onFileSelected: (selectedFile: UploadedFile) => void;
    onRemoveFile: () => void;
}

const LeaseDocumentUploadSection: React.FC<LeaseDocumentUploadSectionProps> = ({ file, onFileSelected, onRemoveFile }) => {
    const { t } = useTranslation();

    return (
        <ExpandableSection title={t("modals.create_lease.upload_document")} className="mt-4">
            <FileUpload
                id="create-lease-documents"
                allowedTypes=".pdf,image/*"
                maxFilesToUpload={1}
                onFileSelected={(files) => onFileSelected(files[0])}
                currentFileCount={file ? 1 : 0}
            />
            {file && (
                <FileCard
                    index={0}
                    className="mt-3"
                    key={file.filename}
                    fileName={file.filename}
                    fileSize={file.filesize}
                    progress={file.progress}
                    uploaded={file.uploaded}
                    onClickRemove={onRemoveFile}
                />
            )}
        </ExpandableSection>
    );
};

export default LeaseDocumentUploadSection;
