import { IoConstructOutline, IoLocationOutline } from "react-icons/io5";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";

// Base-Container mit Padding für den Schatten
export const PropertyCardContainer = styled.div`
  height: 100%;
  position: relative;
  padding: 8px;
`;

// Hauptkarte mit flacherem Design
export const PropertyCardWrapper = styled.div<{ deactivated?: boolean }>`
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(240, 240, 240, 0.8);
  cursor: ${({ deactivated }) => (deactivated ? "not-allowed" : "pointer")};

  @media (max-width: 768px) {
    border-radius: 8px;
  }
`;

// Bild-Container mit flacherem Aspect Ratio
export const PropertyImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 45%; /* Reduziertes Aspect Ratio für Desktop */
  overflow: hidden;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 70%,
      rgba(0, 0, 0, 0.3) 100%
    );
    z-index: 1;
  }
  
  @media (max-width: 768px) {
    padding-top: 40%; /* Noch flacheres Aspect Ratio für Mobile */
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

// Bild ohne Animation
export const StyledLazyLoadImage = styled(LazyLoadImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease;
  
  ${PropertyCardWrapper}:hover & {
    transform: scale(1.05);
  }
`;

// Dunkleres Overlay für Under Construction
export const PropertyOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  
  @media (max-width: 768px) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

// Construction Icon ohne Effekte
export const ConstructionIcon = styled(IoConstructOutline)`
  color: #fff;
  font-size: 36px;
  z-index: 3;
  
  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

// Content-Bereich mit kürzerem Spacing
export const PropertyContent = styled.div`
  padding: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  
  @media (max-width: 768px) {
    padding: 0.75rem;
  }
`;

// Kleinerer Titel
export const PropertyTitle = styled.h3`
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  margin-bottom: 0.6rem;
  color: #1a202c;
  
  @media (max-width: 768px) {
    font-size: 0.95rem;
    margin-bottom: 0.5rem;
  }
`;

// Einfacherer Location Wrapper
export const PropertyLocationWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  color: #718096;
`;

// Location Link ohne Overflows
export const PropertyLocation = styled.a`
  font-size: 0.85rem;
  color: #4a5568;
  text-decoration: none;
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  
  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

// Angepasstes Location-Icon
export const LocationIcon = styled(IoLocationOutline)`
  color: #4a5568;
  min-width: 16px;
  font-size: 16px;
`;

// Badge für Status-Anzeige
export const PropertyBadge = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.7rem;
  font-weight: 600;
  z-index: 3;
`;
