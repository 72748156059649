import './topbar.css';

import React, { useEffect, useState } from 'react';
import { BsInfoCircle, BsList, BsSearch, BsX } from "react-icons/bs";
import UserDefaultAvatar from '../../assets/images/default-avatar.webp';
import { useLocation, useNavigate } from 'react-router-dom';
import useEmployeeStore from '../../stores/employeeStore';
import useSearchStore from '../../stores/searchStore';
import InfoModal from '../../modals/info-modal/info-modal';
import { useTranslation } from 'react-i18next';

interface TopbarProps {
    isSidebarVisible: boolean;
    toggleSidebar: () => void;
}

const Topbar: React.FC<TopbarProps> = ({ isSidebarVisible, toggleSidebar }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    // Stores
    const { employee } = useEmployeeStore();
    const { search, setSearch } = useSearchStore();

    // States
    const [title, setTitle] = useState<string>("");
    const [isSearchVisible, setIsSearchVisible] = useState<boolean>(false);
    const [isInfoModalVisible, setIsInfoModalVisible] = useState<boolean>(false);

    // Set Title
    useEffect(() => {
        setIsSearchVisible(false);
        if (location.pathname === "/") {
            setTitle(t("topbar.menu_dashboard"));
        }
        else if (location.pathname === "/investors") {
            setTitle(t("topbar.menu_investors"));
            setIsSearchVisible(true);
        }
        else if (location.pathname.includes("/investors/")) {
            setTitle(t("topbar.menu_investor"));
        }
        else if (location.pathname.includes("/employees")) {
            setTitle(t("topbar.menu_employees"));
            setIsSearchVisible(true);
        }
        else if (location.pathname.includes("/audit-logs")) {
            setTitle(t("topbar.menu_audit_logs"));
            setIsSearchVisible(false);
        }
        else if (location.pathname.includes("/tenants")) {
            if (/\/tenants\/\d+$/.test(location.pathname)) {
                setTitle(t("topbar.menu_tenant"));
                setIsSearchVisible(false);
            } else {
                setTitle(t("topbar.menu_tenants"));
                setIsSearchVisible(true);
            }
        }
        else if (location.pathname === "/construction-updates") {
            setTitle(t("topbar.menu_construction_updates"));
        }
        else if (location.pathname.includes("/construction-update/")) {
            setTitle(t("topbar.menu_construction_update"));
        }
        else if (location.pathname === "/courses") {
            setTitle(t("topbar.menu_after_sale_course"));
        }
        else if (location.pathname.includes("/course/")) {
            setTitle(t("topbar.menu_course"));
        }
        else if (location.pathname === "/properties") {
            setTitle(t("topbar.menu_properties"));
        }
        else if (location.pathname.includes("/available-units")) {
            setTitle(t("topbar.menu_available_units"));
            setIsSearchVisible(true);
        }
        else if (location.pathname === "/units") {
            setTitle(t("topbar.menu_units"));
            setIsSearchVisible(true);
        }
        else if (location.pathname.includes("/units/")) {
            setTitle(t("topbar.menu_unit"));
            setIsSearchVisible(false);
        }
        else if (location.pathname === "/leases") {
            setTitle(t("topbar.menu_leases"));
            setIsSearchVisible(true);
        }
        else if (location.pathname.includes("/leases/")) {
            setTitle(t("topbar.menu_lease"));
            setIsSearchVisible(false);
        }
        else if (location.pathname === "/pm-contracts") {
            setTitle(t("topbar.menu_pm_contracts"));
            setIsSearchVisible(false);
        }
        else if (location.pathname === "/payments") {
            setTitle(t("topbar.menu_payments"));
            setIsSearchVisible(false);
        }
        else if (location.pathname.includes("/news")) {
            setTitle(t("topbar.menu_news"));
            setIsSearchVisible(true);
        }
        else if (location.pathname === "/profile") {
            setTitle(t("topbar.menu_profile"));
            setIsSearchVisible(false);
        }
        else if (location.pathname.includes("/documents")) {
            setTitle(t("topbar.menu_documents"));
        }
    }, [location, t]);

    // Functions
    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const onClickProfileAvatar = () => {
        navigate("/profile");
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-dark">
            <div className="container-fluid d-flex justify-content-between">
                <div>
                    <button className="navbar-toggler" type="button" onClick={toggleSidebar}>
                        {isSidebarVisible ? <BsX color="black" size={28} /> : <BsList color="black" size={28} />}
                    </button>
                    <span className="ms-2 navbar-title d-none d-md-inline">{title}</span>
                </div>

                <div className="d-flex align-items-center">
                    {/* Large screens */}
                    {isSearchVisible && (
                        <div className="d-none d-lg-flex align-items-center">
                            <div className="search-container me-3">
                                <BsSearch className="search-icon" />
                                <input
                                    className="search-input form-control"
                                    type="search"
                                    placeholder={t("placeholders.search")}
                                    aria-label="Search"
                                    value={search}
                                    onChange={handleSearchInputChange}
                                />
                            </div>
                        </div>
                    )}
                    <BsInfoCircle
                        className="notification-icon ms-4"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setIsInfoModalVisible(true)}
                    />
                    <img
                        className="avatar ms-4 me-3"
                        src={employee?.avatar ?? UserDefaultAvatar}
                        alt="avatar"
                        style={{ cursor: 'pointer' }}
                        onClick={onClickProfileAvatar}
                    />
                </div>
            </div>
            {isInfoModalVisible && (
                <InfoModal onClose={() => setIsInfoModalVisible(false)} />
            )}
        </nav>
    );
};

export default Topbar;
