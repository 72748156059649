import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TagContainer, TagLogo, TagTooltipIcon } from './tag.styles';
import { colorPalette } from '../../utils/theme';
import LogoIcon from '../../assets/icons/logo-icon-white.png';
import LogoIconDark from '../../assets/icons/logo-icon-dark.png';
import { MaterialSymbol } from '@material-symbols/font-400';
import useIsMobile from '../../hooks/useIsMobile';
import MaterialIcon from '../material-icon';

interface UnitStatusTagProps {
    label: string;
    size?: 'L' | 'S';
    type: 'unitStatus';
    variant: 'inProgress' | 'handover' | 'vacant' | 'rented' | 'completed' | 'selfInterest';
    icon?: MaterialSymbol;
    tooltip?: string;
    backgroundColor?: string;
}

interface PackageTagProps {
    size?: 'L' | 'S';
    type: 'package';
    variant: 'basic' | 'premium' | 'standard' | 'noManagement';
    icon?: MaterialSymbol;
    tooltip?: string;
    backgroundColor?: string;
}

// Combine both prop types into a single type
type TagProps = UnitStatusTagProps | PackageTagProps;

// Define styles for unit status
const unitStatusStyles = {
    inProgress: { backgroundColor: colorPalette.blue200, color: colorPalette.textMain },
    handover: { backgroundColor: colorPalette.blue200, color: colorPalette.blue500 },
    vacant: { backgroundColor: colorPalette.blue200, color: colorPalette.textMain },
    rented: { backgroundColor: colorPalette.successLight, color: colorPalette.successDark },
    completed: { backgroundColor: colorPalette.blue100, color: colorPalette.textDark },
    selfInterest: { backgroundColor: colorPalette.blue100, color: colorPalette.textDark },
};

// Define styles for package tags
const packageStyles = {
    basic: { backgroundColor: colorPalette.blue300, color: colorPalette.textMain },
    standard: { backgroundColor: colorPalette.blue300, color: colorPalette.textMain },
    premium: { backgroundColor: "linear-gradient(83.75deg, #7B7043 3.65%, #B9A862 99.84%)", color: colorPalette.white },
    noManagement: { backgroundColor: colorPalette.blue600, color: colorPalette.white },
};

const Tag: React.FC<TagProps> = (props) => {
    const isMobile = useIsMobile();

    let styles;

    // Handle unit status tags
    if (props.type === 'unitStatus') {
        styles = unitStatusStyles[props.variant];
    }
    // Handle package tags
    else if (props.type === 'package') {
        styles = packageStyles[props.variant];
    }

    const backgroundColor = props.backgroundColor || styles?.backgroundColor || '#FFFFFF';
    const color = styles?.color ?? '#000000';

    return (
        <TagContainer
            backgroundColor={backgroundColor}
            color={color}
            size={props.size ?? "L"}
        >
            {/* Optional logo */}
            {props.type === "package" && <TagLogo src={props.variant === "premium" ? LogoIcon : LogoIconDark} alt="logo" />}

            {/* Icon before label */}
            {props.icon && <MaterialIcon icon={props.icon} size={24} marginRight="8px" />}

            {props.type === "unitStatus" && (
                <>
                    {props.label}
                </>
            )}

            {!isMobile && props.type === "package" && (
                <>
                    {props.variant === "basic" ? "Basic" : 
                     props.variant === "standard" ? "Standard" : 
                     props.variant === "premium" ? "Premium" : 
                     "Kein Management"}
                </>
            )}

            {/* Optional tooltip */}
            {props.tooltip && (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{props.tooltip}</Tooltip>}
                >
                    <TagTooltipIcon>
                        <MaterialIcon icon="info" size={24} />
                    </TagTooltipIcon>
                </OverlayTrigger>
            )}
        </TagContainer>
    );
};

export default Tag;
