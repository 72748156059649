import React from 'react';
import useNumberAnimation from '../../../../hooks/useNumberAnimation';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'date-fns';
import styles from './payment-details.module.css';
import { formatMoney } from '../../../../utils/helpers';
import { ILeasePayment } from '../../../../services/leasePaymentService';

interface PaymentInfo {
    total_rent: number;
    security_deposit: number;
    rent_received: number;
    next_rent: ILeasePayment | null;
}

interface Props {
    payments: PaymentInfo;
}

const PaymentDetails: React.FC<Props> = ({ payments }) => {
    const { t } = useTranslation();

    const animatedTotalRent = useNumberAnimation(payments.total_rent);
    const animatedSecurityDeposit = useNumberAnimation(payments.security_deposit);
    const animatedRentReceived = useNumberAnimation(payments.rent_received);
    const animatedNextRent = useNumberAnimation(payments.next_rent?.amount ?? 0);

    // Mobile layout - compact 2-column view
    const mobileLayout = (
        <div className={styles.mobilePaymentGrid}>
            <div className={styles.mobilePaymentItem}>
                <div className={styles.mobileLabel}>{t("pages.lease_detail.payment_details.total_rent_label")}</div>
                <div className={styles.mobileAmount}>AED {formatMoney(animatedTotalRent)}</div>
            </div>
            <div className={styles.mobilePaymentItem}>
                <div className={styles.mobileLabel}>{t("pages.lease_detail.payment_details.security_deposit_label")}</div>
                <div className={styles.mobileAmount}>AED {formatMoney(animatedSecurityDeposit)}</div>
            </div>
            <div className={styles.mobilePaymentItem}>
                <div className={styles.mobileLabel}>{t("pages.lease_detail.payment_details.rent_received_label")}</div>
                <div className={styles.mobileAmount}>AED {formatMoney(animatedRentReceived)}</div>
            </div>
            <div className={styles.mobilePaymentItem}>
                <div className={styles.mobileLabel}>
                    {t("pages.lease_detail.payment_details.next_rent_label")}{" "}
                    {payments.next_rent ? `(${formatDate(payments.next_rent.due_date, "dd.MM.yyyy")})` : ""}
                </div>
                <div className={styles.mobileAmount}>AED {formatMoney(animatedNextRent)}</div>
            </div>
        </div>
    );

    // Desktop layout - standard card view
    const desktopLayout = (
        <div className="row gy-3 justify-content-center">
            <div className="col-12 col-sm-6 col-xl-3 text-center">
                <div className={`p-3 ${styles.paymentDetailsCard}`}>
                    <div className={styles.numberItem}>
                        AED {formatMoney(animatedTotalRent)}
                        <span>{t("pages.lease_detail.payment_details.total_rent_label")}</span>
                    </div>
                </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-3 text-center">
                <div className={`p-3 ${styles.paymentDetailsCard}`}>
                    <div className={styles.numberItem}>
                        AED {formatMoney(animatedSecurityDeposit)}
                        <span>{t("pages.lease_detail.payment_details.security_deposit_label")}</span>
                    </div>
                </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-3 text-center">
                <div className={`p-3 ${styles.paymentDetailsCard}`}>
                    <div className={styles.numberItem}>
                        AED {formatMoney(animatedRentReceived)}
                        <span>{t("pages.lease_detail.payment_details.rent_received_label")}</span>
                    </div>
                </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-3 text-center">
                <div className={`p-3 ${styles.paymentDetailsCard}`}>
                    <div className={styles.numberItem}>
                        AED {formatMoney(animatedNextRent)}
                        <span>
                            {t("pages.lease_detail.payment_details.next_rent_label")}{" "}
                            {payments.next_rent ? `(${formatDate(payments.next_rent.due_date, "dd.MM.yyyy")})` : ""}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className={styles.title}>{t("pages.property_detail.payment_details.title")}</h5>
            </div>
            <div className="d-sm-none">
                {mobileLayout}
            </div>
            <div className="d-none d-sm-block">
                {desktopLayout}
            </div>
        </div>
    );
};

export default PaymentDetails;
