import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './lease-payment-table-section.module.css';
import { formatMoney } from '../../../../../utils/helpers';
import { PaymentMethod, PaymentPurpose } from '../../../../../services/paymentService';

export interface LeasePaymentTableItem {
    paymentMethod: PaymentMethod;
    paymentDueDate: Date;
    paymentPurpose: PaymentPurpose;
    amount: number;
}

interface LeasePaymentTableSectionProps {
    payments: LeasePaymentTableItem[];
}

const LeasePaymentTableSection: React.FC<LeasePaymentTableSectionProps> = ({ payments }) => {
    const { t } = useTranslation();

    // Helper function to translate payment method
    const translatePaymentMethod = (method: PaymentMethod) => {
        switch (method) {
            case PaymentMethod.CHEQUE:
                return t('payment_method.cheque');
            case PaymentMethod.CASH:
                return t('payment_method.cash');
            case PaymentMethod.BANK_TRANSFER:
                return t('payment_method.bank_transfer');
            default:
                return '';
        }
    };

    // Helper function to translate payment purpose
    const translatePaymentPurpose = (purpose: PaymentPurpose) => {
        switch (purpose) {
            case PaymentPurpose.RENT:
                return t('payment_purpose.rent');
            case PaymentPurpose.SECURITY_DEPOSIT:
                return t('payment_purpose.security_deposit');
            case PaymentPurpose.PET_DEPOSIT:
                return t('payment_purpose.pet_deposit');
            case PaymentPurpose.ADMIN_FEE:
                return t('payment_purpose.admin_fee');
            case PaymentPurpose.PROPERTY_MANAGEMENT_FEE:
                return t('payment_purpose.property_management_fee');
            case PaymentPurpose.HANDOVER_FEE:
                return t('payment_purpose.handover_fee');
            case PaymentPurpose.OVER_PAYMENT:
                return t('payment_purpose.over_payment');
            case PaymentPurpose.UNDER_PAYMENT:
                return t('payment_purpose.under_payment');
            default:
                return purpose; // Zeige den rohen Enum-Wert an, falls keine Übersetzung vorhanden ist
        }
    };

    return (
        <>
            {payments.length > 0 && (
                <div className={styles.paymentTableContainer}>
                    <table className={`table ${styles.paymentTable}`}>
                        <thead>
                            <tr>
                                <th>{t('lease_payment_table.purpose')}</th>
                                <th>{t('lease_payment_table.due_date')}</th>
                                <th>{t('lease_payment_table.amount')}</th>
                                <th>{t('lease_payment_table.method')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {payments.map((payment, index) => (
                                <tr key={index}>
                                    <td>{translatePaymentPurpose(payment.paymentPurpose)}</td>
                                    <td>{payment.paymentDueDate.toLocaleDateString()}</td>
                                    <td>
                                        <span className={styles.totalAmountBadge}>
                                            {formatMoney(Number(payment.amount.toFixed(2)))}
                                        </span>
                                    </td>
                                    <td>{translatePaymentMethod(payment.paymentMethod)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
};

export default LeasePaymentTableSection;
