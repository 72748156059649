import '../base-modal.css';
import './edit-document-modal.css';

import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import { DocumentStatus, DocumentType, downloadDocument, IDocument, updateDocument } from "../../services/documentService";
import DateSelect from '../../components/date-select/date-select';
import SidebarInputField from '../sidebar/components/sidebar-input-field/sidebar-input-field';
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import useAlertStore from '../../stores/alertStore';

interface EditDocumentModalProps {
    document: IDocument;
    onClose: () => void;
    onUpdateDocument: (updatedDocument: IDocument) => void;
}

const EditDocumentModal: React.FC<EditDocumentModalProps> = ({ document, onClose, onUpdateDocument }) => {
    const { t } = useTranslation();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);

    // States
    const [isSaving, setIsSaving] = useState(false);
    const [isDownloading, setIsDownloading] = useState(true);
    const [fileUrl, setFileUrl] = useState<string | null>(null);
    const [fileType, setFileType] = useState<string | null>(null);
    const [documentIdentifier, setDocumentIdentifier] = useState<string>("");
    const [expiryDate, setExpiryDate] = useState<Date | null>(null);

    // Error States
    const [documentIdentifierError, setDocumentIdentifierError] = useState<string>("");

    // Load the document when the modal opens
    useEffect(() => {
        setExpiryDate(document.valid_until ? new Date(document.valid_until) : null);
        setDocumentIdentifier(document.document_identifier ?? "");

        const loadFile = async () => {
            try {
                setIsDownloading(true);
                const blob: Blob | null = await downloadDocument(document.document_id);
                if (blob) {
                    const blobUrl = URL.createObjectURL(blob);
                    setFileUrl(blobUrl);

                    // Determine file type
                    if (blob.type.includes("pdf")) {
                        setFileType("pdf");
                    } else if (blob.type.includes("image")) {
                        setFileType("image");
                    } else {
                        setFileType("unsupported");
                    }
                }
            } catch (error) {
                console.error("Error while downloading file: ", error);
            } finally {
                setIsDownloading(false);
            }
        };
        loadFile();
    }, [document]);

    // Render File Preview
    const renderFilePreview = () => {
        const previewStyle: React.CSSProperties = {
            maxHeight: "450px",
            overflowY: "auto",
            display: "flex",
        };

        if (fileType === "pdf") {
            return (
                <div style={previewStyle}>
                    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
                        <div style={{ height: "450px", width: "100%", overflowY: "hidden" }}>
                            <Viewer
                                fileUrl={fileUrl!}
                                defaultScale={0.8}
                                initialPage={0} // Ensures it starts from the first page
                            />
                        </div>
                    </Worker>
                </div>
            );
        } else if (fileType === "image") {
            return (
                <div style={previewStyle}>
                    <img
                        src={fileUrl!}
                        alt="Document Preview"
                        style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "contain",
                        }}
                    />
                </div>
            );
        } else {
            return <p>{t("modals.view_document.unsupported_file_type")}</p>;
        }
    };

    const onHandleEditDocument = useCallback(async () => {
        let isValid = true;
        const needDocumentIdentifier = document.document_type === DocumentType.PASSPORT || document.document_type === DocumentType.EMIRATES_ID;

        if (needDocumentIdentifier && !documentIdentifier.trim()) {
            setDocumentIdentifierError(t("invalid_input.document_identifier_empty"));
            isValid = false;
        }

        if (isValid) {
            try {
                setIsSaving(true);
                const updatedDocument = await updateDocument(document.document_id, expiryDate ? expiryDate.toISOString() : null, documentIdentifier.trim().length > 0 ? documentIdentifier : null);
                onUpdateDocument(updatedDocument);
            } catch (error) {
                console.log("Error while saving existing document:", error);
                // @ts-ignore
                showAlert("error", "Error while updating existing document. Error: " + error.message);
            } finally {
                setIsSaving(false);
            }
        }
    }, [document, expiryDate, documentIdentifier]);

    return (
        <div className="modal-backdrop">
            <div className="modal-box" style={{ position: "relative", padding: "1.5rem", width: document.document_type === DocumentType.PROOF_OF_ADDRESS && document.status === DocumentStatus.IN_REVIEW ? "70%" : "600px" }}>
                {/* Close button (inside modal, top-right corner) */}
                <span
                    style={{
                        position: "absolute",
                        top: "1rem",
                        right: "1rem",
                        cursor: "pointer",
                        fontSize: "1.8rem",
                        lineHeight: "1",
                        display: "flex",
                        alignItems: "center",
                    }}
                    onClick={onClose}
                >
                    &times;
                </span>

                <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h3 className="input-modal-title">{t("modals.view_document.title")}</h3>
                    </div>
                </div>

                {/* File Preview Section */}
                <div className="file-preview-container mb-4">
                    {isDownloading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "300px" }}>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    ) : (
                        renderFilePreview()
                    )}
                </div>

                <div className="row">
                    <div className="col-12 col-md-6">
                        <DateSelect
                            id="edit-document-valid-until"
                            label={t("labels.valid_until")}
                            placeholder={t("placeholders.select_date")}
                            date={expiryDate}
                            onChange={setExpiryDate}
                            labelOnTop
                            required
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <SidebarInputField
                            id="edit-document-identifier"
                            type="text"
                            label={t("labels.document_identifier")}
                            placeholder="CV7382371"
                            value={documentIdentifier}
                            errorMessage={documentIdentifierError}
                            onClear={() => setDocumentIdentifier("")}
                            onChange={(value: string) => {
                                setDocumentIdentifier(value);
                                setDocumentIdentifierError("");
                            }}
                            labelOnTop
                            required
                        />
                    </div>
                </div>

                <div className="text-end mt-4">
                    <button className={`btn btn-success add-btn me-2 ${isSaving ? 'disabled' : ''}`} onClick={onHandleEditDocument} disabled={isSaving}>
                        {isSaving ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        ) : (
                            t("buttons.save")
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditDocumentModal;
