import './payments-table.css';

import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'date-fns';
import { formatPaymentMethod, formatPaymentPurpose } from '../../../../services/paymentService';
import { formatMoney } from '../../../../utils/helpers';
import { useLeasePayments } from '../../../../hooks/useLeasePayments';
import { LeasePaymentFilter } from '../../../../services/leasePaymentService';

interface LeasePaymentsTableProps {
    propertyId: number;
    filter: LeasePaymentFilter;
}

const LeasePaymentsTable: React.FC<LeasePaymentsTableProps> = ({ propertyId, filter }) => {
    const { t } = useTranslation();
    const {
        lease_payments,
        totalCount,
        isLoading,
        isLoadingMore,
        size,
        setSize,
        isReachingEnd
    } = useLeasePayments(Number(propertyId), undefined, filter);

    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const onClickPayment = (leaseId: number) => {
        window.open(`/leases/${leaseId}`, '_blank');
    };

    const title = filter === LeasePaymentFilter.OVERDUE
        ? t("pages.property_detail.overdue_payments.title")
        : t("pages.property_detail.upcoming_payments.title");

    const noPaymentsText = filter === LeasePaymentFilter.OVERDUE
        ? t("pages.property_detail.overdue_payments.no_payments_text")
        : t("pages.property_detail.upcoming_payments.no_payments_text");

    // Scroll handler to detect when the user reaches the bottom
    const handleScroll = () => {
        const container = scrollContainerRef.current;
        if (container) {
            const { scrollTop, scrollHeight, clientHeight } = container;

            if (scrollTop + clientHeight >= scrollHeight - 1) {
                setSize(size + 1);
            }
        }
    };

    useEffect(() => {
        const container = scrollContainerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        // Cleanup on unmount
        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [size, isReachingEnd, isLoadingMore]);

    return (
        <div className="payments">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="title">({totalCount}) {title}</h5>
            </div>
            {isLoading ? (
                <div className="payments-table-responsive">
                    <table className="payments-table">
                        <thead>
                            <tr>
                                <th>{t("payment_table.due_date")}</th>
                                <th>{t("payment_table.amount")}</th>
                                <th>{t("payment_table.method")}</th>
                                <th>{t("payment_table.purpose")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[...Array(5)].map((_, idx) => (
                                <tr key={idx}>
                                    <td><span className="skeleton due-date"></span></td>
                                    <td><span className="skeleton amount"></span></td>
                                    <td><span className="skeleton method"></span></td>
                                    <td><span className="skeleton purpose"></span></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <>
                    {lease_payments.length > 0 ? (
                        <div
                            className="payments-table-responsive"
                            ref={scrollContainerRef}
                        >
                            <table className="payments-table">
                                <thead>
                                    <tr>
                                        <th>{t("payment_table.due_date")}</th>
                                        <th>{t("payment_table.amount")} (AED)</th>
                                        <th>{t("payment_table.method")}</th>
                                        <th>{t("payment_table.purpose")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lease_payments.map(payment => (
                                        <tr key={payment.payment_id} onClick={() => onClickPayment(payment.lease_id)}>
                                            <td>{formatDate(payment.due_date, "dd.MM.yyyy")}</td>
                                            <td>
                                                <span className="total-amount-badge">
                                                    {formatMoney(payment.amount)}
                                                </span>
                                            </td>
                                            <td>
                                                <span>{formatPaymentMethod(payment.method, t)}</span>
                                                {payment.cheque_no && payment.cheque_no.length > 0 && (
                                                    <span className="cheque-number">({payment.cheque_no})</span>
                                                )}
                                            </td>
                                            <td>{formatPaymentPurpose(payment.purpose, t)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="payments-table-card">
                            <p className="no-payments-text">{noPaymentsText}</p>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default LeasePaymentsTable;
