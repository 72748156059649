import './lease-payments-table.css';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate, isBefore, isEqual, startOfDay } from 'date-fns';
import { formatPaymentMethod, formatPaymentPurpose, PaymentStatus } from '../../../../services/paymentService';
import { formatMoney } from '../../../../utils/helpers';
import { IoAdd, IoReceipt, IoSettingsOutline, IoCalendarOutline, IoTimeOutline, IoCardOutline, IoChevronDown, IoChevronUp } from 'react-icons/io5';
import FileUpload, { UploadedFile } from '../../../../components/file-upload/file-upload';
import FileCard from '../../../../components/file-card/file-card';
import CustomButton from '../../../../components/custom-button/custom-button';
import useAlertStore from '../../../../stores/alertStore';
import DeleteFileModal from '../../../../modals/delete-file-modal/delete-file-modal';
import { IDocument } from '../../../../services/documentService';
import LeasePaymentSidebarModal from '../../../../modals/sidebar/lease-payment-sidemodal/lease-payment-sidemodal';
import useEmployeeStore from '../../../../stores/employeeStore';
import useEditPermission from '../../../../hooks/useEditPermission';
import { EmployeeRole } from '../../../../services/employeeService';
import { ILeasePayment } from '../../../../services/leasePaymentService';
import { uploadLeaseDocuments } from '../../../../services/leaseService';

interface LeasePaymentsTableProps {
    lease_id: number;
    payments: ILeasePayment[];
    onPaymentsUpdated: (payments: ILeasePayment[]) => void;
}

const LeasePaymentsTable: React.FC<LeasePaymentsTableProps> = ({ lease_id, payments, onPaymentsUpdated }) => {
    const { t } = useTranslation();

    // Stores
    const employeeRoleId = useEmployeeStore(state => state.employee?.role_id);
    const hasEditPermission = useEditPermission(employeeRoleId, [EmployeeRole.ADMINISTRATOR, EmployeeRole.FINANCE_MANAGER]);
    const showAlert = useAlertStore(state => state.showAlert);

    // States
    const [expandedRow, setExpandedRow] = useState<number | null>(null);
    const [expandedCard, setExpandedCard] = useState<number | null>(null);
    const [file, setFile] = useState<UploadedFile | null>(null);
    const [isDocumentUploading, setIsDocumentUploading] = useState<boolean>(false);
    const [isDeleteFileModalVisible, setIsDeleteFileModalVisible] = useState<IDocument | null>(null);
    const [isSidebarModalVisible, setIsSidebarModalVisible] = useState<boolean>(false);
    const [selectedPayment, setSelectedPayment] = useState<ILeasePayment | null>(null);

    // Überprüft, ob die Zahlung überfällig ist (mindestens ein Tag nach Fälligkeitsdatum)
    const isOverdue = (dueDate: string, paymentDate: string | null) => {
        if (paymentDate) return false; // Wenn bereits bezahlt, nicht überfällig
        
        const today = startOfDay(new Date()); // Heute um 00:00 Uhr
        const dueDateDate = startOfDay(new Date(dueDate)); // Fälligkeitsdatum um 00:00 Uhr
        
        // Wenn das Fälligkeitsdatum vor dem heutigen Tag liegt
        return isBefore(dueDateDate, today);
    };

    // Überprüft, ob die Zahlung heute fällig ist
    const isDueToday = (dueDate: string, paymentDate: string | null) => {
        if (paymentDate) return false; // Wenn bereits bezahlt, nicht fällig
        
        const today = startOfDay(new Date()); // Heute um 00:00 Uhr
        const dueDateDate = startOfDay(new Date(dueDate)); // Fälligkeitsdatum um 00:00 Uhr
        
        // Wenn das Fälligkeitsdatum heute ist
        return isEqual(dueDateDate, today);
    };

    // Bestimmt die CSS-Klasse für den Status
    const getStatusClass = (payment: ILeasePayment) => {
        if (payment.payment_date) return "paid";
        if (isOverdue(payment.due_date, payment.payment_date)) return "overdue";
        if (isDueToday(payment.due_date, payment.payment_date)) return "due-today";
        return "unpaid";
    };

    // Bestimmt den Statustext
    const getStatusText = (payment: ILeasePayment) => {
        if (payment.status === PaymentStatus.BOUNCED) return t("labels.bounced");
        if (payment.payment_date) return t("labels.paid");
        if (isOverdue(payment.due_date, payment.payment_date)) return t("labels.overdue");
        if (isDueToday(payment.due_date, payment.payment_date)) return "Fällig";
        return t("labels.unpaid");
    };

    const toggleRowExpansion = (payment_id: number) => {
        setExpandedRow(expandedRow === payment_id ? null : payment_id);
        setFile(null);
    };

    const toggleCardExpansion = (payment_id: number) => {
        setExpandedCard(expandedCard === payment_id ? null : payment_id);
        setFile(null);
    };

    const onClickUploadDocument = useCallback(async (payment_id: number) => {
        if (file) {
            try {
                setIsDocumentUploading(true);
                const document = await uploadLeaseDocuments(payment_id, file.file);
                const updatedPayments = payments.map((payment) =>
                    payment.payment_id === payment_id
                        ? { ...payment, document: { ...document } }
                        : { ...payment }
                );
                setFile(null);
                onPaymentsUpdated([...updatedPayments]);
            } catch (error) {
                console.log('Error while uploading payment document:', error);
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsDocumentUploading(false);
            }
        }
    }, [file, payments, onPaymentsUpdated]);

    const onHandleDeleteDocument = useCallback((document: IDocument) => {
        const updatedPayments = payments.map((payment) =>
            payment.document && payment.document.document_id === document.document_id
                ? { ...payment, document: null }
                : { ...payment }
        );
        onPaymentsUpdated([...updatedPayments]);

        if (selectedPayment) {
            setSelectedPayment({ ...selectedPayment, document: null });
        }
    }, [payments, selectedPayment, onPaymentsUpdated, setSelectedPayment]);

    // Render mobile payment card
    const renderMobilePaymentCard = (payment: ILeasePayment) => {
        const isExpanded = expandedCard === payment.payment_id;
        const statusClass = getStatusClass(payment);
        const statusText = getStatusText(payment);
        
        return (
            <div key={payment.payment_id} className="mobile-payment-card">
                <div 
                    className="mobile-payment-header" 
                    onClick={() => toggleCardExpansion(payment.payment_id)}
                >
                    <div className="mobile-payment-date">
                        <div className="mobile-payment-label">
                            <IoCalendarOutline className="mobile-icon" />
                            {formatDate(payment.due_date, "dd.MM.yyyy")}
                        </div>
                        {payment.payment_date && (
                            <div className="mobile-payment-subtext">
                                <IoTimeOutline className="mobile-icon" />
                                {t("labels.paid")}: {formatDate(payment.payment_date, "dd.MM.yyyy")}
                            </div>
                        )}
                        <div className="mobile-payment-purpose">
                            {formatPaymentPurpose(payment.purpose, t)}
                        </div>
                    </div>
                    <div className="mobile-payment-amount">
                        <div className="mobile-payment-amount-text">
                            AED {formatMoney(payment.amount)}
                        </div>
                        <span className={`status-badge-${statusClass} status-badge-mobile`}>
                            {statusText}
                        </span>
                    </div>
                    <div className="mobile-payment-expand">
                        {isExpanded ? <IoChevronUp /> : <IoChevronDown />}
                    </div>
                </div>
                
                {isExpanded && (
                    <div className="mobile-payment-details">
                        <div className="mobile-payment-info-grid">
                            <div className="mobile-payment-info-item">
                                <div className="mobile-payment-info-label">{t("payment_table.method")}</div>
                                <div className="mobile-payment-info-value" style={{ textDecoration: payment.status === PaymentStatus.BOUNCED ? 'line-through' : 'none' }}>
                                    {formatPaymentMethod(payment.method, t)}
                                    {payment.cheque_no && (
                                        <span className="mobile-payment-info-subtext">({payment.cheque_no})</span>
                                    )}
                                </div>
                            </div>
                            <div className="mobile-payment-info-item">
                                <div className="mobile-payment-info-label">{t("payment_table.status")}</div>
                                <div className="mobile-payment-info-value">
                                    <span className={`status-badge-${statusClass} status-badge-mobile`}>
                                        {statusText}
                                    </span>
                                </div>
                            </div>
                        </div>
                        
                        {payment.remark && payment.remark.length > 0 && (
                            <div className="mobile-payment-remark">
                                <div className="mobile-payment-info-label">{t("labels.remark")}</div>
                                <div className="mobile-payment-info-value">{payment.remark}</div>
                            </div>
                        )}
                        
                        <div className="mobile-payment-document">
                            {payment.document && (
                                <FileCard
                                    index={payment.document.document_id}
                                    fileName={payment.document.file_name}
                                    filePath={payment.document.file_path}
                                    doc={payment.document}
                                    onClickRemove={() => setIsDeleteFileModalVisible(payment.document)}
                                    progress={100}
                                    uploaded
                                />
                            )}
                            {file && (
                                <FileCard
                                    index={1}
                                    fileName={file.filename}
                                    progress={file.progress}
                                    onClickRemove={() => setFile(null)}
                                    uploaded={false}
                                />
                            )}
                            {!payment.document && !file && (
                                <FileUpload
                                    id={`lease-payment-document-mobile-${payment.payment_id}`}
                                    onFileSelected={(files) => setFile(files.length > 0 ? files[0] : null)}
                                    maxFilesToUpload={1}
                                    currentFileCount={payment.document ? 1 : 0}
                                />
                            )}
                            {!payment.document && file && (
                                <CustomButton
                                    className="mt-3 me-3"
                                    color="blue"
                                    size="small"
                                    title="Upload"
                                    icon={IoAdd}
                                    isLoading={isDocumentUploading}
                                    disabled={isDocumentUploading}
                                    onClick={() => onClickUploadDocument(payment.payment_id)}
                                />
                            )}
                        </div>
                        
                        {hasEditPermission && (
                            <div className="mobile-payment-actions">
                                <CustomButton
                                    className="w-100 mt-3"
                                    title={t("buttons.edit")}
                                    size="small"
                                    isLoading={false}
                                    onClick={() => {
                                        setSelectedPayment(payment);
                                        setIsSidebarModalVisible(true);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    return (
        <>
            <div className="lease-payments">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="title">({payments.length}) {t("pages.lease_detail.lease_payments.title")}</h5>
                    {hasEditPermission && (
                        <button className="btn btn-create-payment" onClick={() => setIsSidebarModalVisible(true)}>
                            {t("buttons.create")}
                        </button>
                    )}
                </div>
                
                {/* Mobile view - cards */}
                {payments.length > 0 ? (
                    <div className="d-sm-none">
                        <div className="mobile-payments-list">
                            {payments.map(payment => renderMobilePaymentCard(payment))}
                        </div>
                    </div>
                ) : (
                    <div className="d-sm-none">
                        <div className="lease-payments-table-card">
                            <p className="no-payments-text">{t("pages.lease_detail.lease_payments.no_payments")}</p>
                        </div>
                    </div>
                )}
                
                {/* Desktop view - table */}
                {payments.length > 0 ? (
                    <div className="d-none d-sm-block lease-payments-table-responsive">
                        <table className="lease-payments-table">
                            <thead>
                                <tr>
                                    <th>{t("payment_table.due_date")}</th>
                                    <th>{t("payment_table.paid_date")}</th>
                                    <th>{t("payment_table.amount")} (AED)</th>
                                    <th>{t("payment_table.method")}</th>
                                    <th>{t("payment_table.purpose")}</th>
                                    <th>{t("payment_table.status")}</th>
                                    {hasEditPermission && (
                                        <th><IoSettingsOutline /></th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {payments.map(payment => (
                                    <React.Fragment key={payment.payment_id}>
                                        <tr onClick={() => toggleRowExpansion(payment.payment_id)}>
                                            <td
                                                style={isOverdue(payment.due_date, payment.payment_date) || isDueToday(payment.due_date, payment.payment_date) ? { fontWeight: "600" } : {}}>
                                                {formatDate(payment.due_date, "dd.MM.yyyy")}
                                            </td>
                                            <td>
                                                {payment.payment_date ? formatDate(payment.payment_date, "dd.MM.yyyy") : "-"}
                                            </td>
                                            <td>
                                                <span
                                                    className={`status-badge-${getStatusClass(payment)}`}
                                                >
                                                    {formatMoney(payment.amount)}
                                                </span>
                                            </td>
                                            <td>
                                                <div>
                                                    <span style={{ textDecoration: payment.status === PaymentStatus.BOUNCED ? 'line-through' : 'none' }}>
                                                        {formatPaymentMethod(payment.method, t)}
                                                    </span>
                                                    {payment.cheque_no && payment.cheque_no.length > 0 && (
                                                        <span className="cheque-number">({payment.cheque_no})</span>
                                                    )}
                                                </div>
                                            </td>
                                            <td>{formatPaymentPurpose(payment.purpose, t)}</td>
                                            <td>
                                                <span className={`status-badge-${getStatusClass(payment)}`}>
                                                    {getStatusText(payment)}
                                                </span>
                                            </td>
                                            {hasEditPermission && (
                                                <td
                                                    className="settings-icon"
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        setSelectedPayment(payment);
                                                        setIsSidebarModalVisible(true);
                                                    }}
                                                >
                                                    <IoSettingsOutline />
                                                </td>
                                            )}
                                        </tr>
                                        {/* Expandable content row */}
                                        {expandedRow === payment.payment_id && (
                                            <tr className="expandable-content">
                                                <td colSpan={hasEditPermission ? 7 : 6}>
                                                    <div className="expanded-content-box">
                                                        {payment.document && (
                                                            <FileCard
                                                                index={payment.document.document_id}
                                                                fileName={payment.document.file_name}
                                                                filePath={payment.document.file_path}
                                                                doc={payment.document}
                                                                onClickRemove={() => setIsDeleteFileModalVisible(payment.document)}
                                                                progress={100}
                                                                uploaded
                                                            />
                                                        )}
                                                        {file && (
                                                            <FileCard
                                                                index={1}
                                                                fileName={file.filename}
                                                                progress={file.progress}
                                                                onClickRemove={() => setFile(null)}
                                                                uploaded={false}
                                                            />
                                                        )}
                                                        {!payment.document && !file && (
                                                            <FileUpload
                                                                id={`lease-payment-document-${payment.payment_id}`}
                                                                onFileSelected={(files) => setFile(files.length > 0 ? files[0] : null)}
                                                                maxFilesToUpload={1}
                                                                currentFileCount={payment.document ? 1 : 0}
                                                            />
                                                        )}
                                                        {!payment.document && file && (
                                                            <CustomButton
                                                                className="mt-3 me-3"
                                                                color="blue"
                                                                size="small"
                                                                title="Upload"
                                                                icon={IoAdd}
                                                                isLoading={isDocumentUploading}
                                                                disabled={isDocumentUploading}
                                                                onClick={() => onClickUploadDocument(payment.payment_id)}
                                                            />
                                                        )}
                                                        {payment.remark && payment.remark.length > 0 && (
                                                            <p className="remark">{t("labels.remark")}: {payment.remark}</p>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className="d-none d-sm-block lease-payments-table-card">
                        <p className="no-payments-text">{t("pages.lease_detail.lease_payments.no_payments")}</p>
                    </div>
                )}
            </div>

            {/* DELETE DOCUMENT MODAL */}
            {isDeleteFileModalVisible && (
                <DeleteFileModal
                    document_id={isDeleteFileModalVisible.document_id}
                    onClose={(deleted: boolean) => {
                        if (deleted) {
                            onHandleDeleteDocument(isDeleteFileModalVisible);
                        }
                        setIsDeleteFileModalVisible(null);
                    }}
                />
            )}

            {/* LEASE PAYMENT SIDEBAR MODAL */}
            {hasEditPermission && (
                <LeasePaymentSidebarModal
                    lease_id={lease_id}
                    payment={selectedPayment}
                    isVisible={isSidebarModalVisible}
                    onClose={() => {
                        setIsSidebarModalVisible(false);
                        setSelectedPayment(null);
                    }}
                    onDeleteDocument={onHandleDeleteDocument}
                    onPaymentCreated={(payment) => {
                        payments.push(payment);
                        onPaymentsUpdated(payments);
                    }}
                    onPaymentUpdated={(updatedPayment) => {
                        const updatedPayments = payments.map((payment) =>
                            payment.payment_id === updatedPayment.payment_id
                                ? { ...updatedPayment }
                                : { ...payment }
                        );
                        onPaymentsUpdated([...updatedPayments]);
                    }}
                    onPaymentDeleted={(deletedPayment) => {
                        onPaymentsUpdated([...payments.filter(payment => payment.payment_id !== deletedPayment.payment_id)]);
                    }}
                />
            )}
        </>
    );
};

export default LeasePaymentsTable;
