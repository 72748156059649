import { SortOrder } from "../@types";
import { API_URL } from "../utils/constants";
import { IAddress } from "./addressService";
import { IBankDetail } from "./bankDetailService";
import { DocumentType, IDocument } from "./documentService";
import { IUnitOwner } from "./unitOwnerService";

export enum InvestorSortBy {
  NAME = "name",
  EMAIL = "email",
  PHONE_NUMBER = "phone_number",
}

export interface IStatus {
  status_id: number;
  status_name: string;
}

export interface ISalesAgent {
  employee_id: number;
  first_name: string;
  last_name: string;
  avatar: string | null;
}

export interface IInvestor {
  investor_id: number;
  first_name: string;
  last_name: string;
  email: string;
  status: IStatus;
  sales_agent: ISalesAgent | null;
  avatar?: string | null;
  phone_number?: string | null;
  birth_date?: string | null;
  nationality?: string | null;
  created_at: string;
  updated_at: string;
  address: IAddress;
  documents: IDocument[];
  owned_units: IUnitOwner[];
  bank_details: IBankDetail[];
}

export const fetchInvestors = async (url: string): Promise<IInvestor[]> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.investors;
};

export const constructInvestorsUrl = (
  statusId: number | null,
  search: string,
  page?: number,
  pageSize?: number,
  sales_agent?: string,
  owned_units?: string,
  total_purchased_value?: string,
  sortBy?: InvestorSortBy,
  sortOrder?: SortOrder
): string => {
  let queryParams = new URLSearchParams();

  if (search.trim().length > 0) {
    queryParams.append("search", encodeURIComponent(search));
  }
  if (statusId !== null && statusId !== 4) {
    queryParams.append("statusId", statusId.toString());
  }
  if (statusId !== null && statusId === 4) {
    queryParams.append("filter", "withoutDocuments");
  }
  if (page !== undefined) {
    queryParams.append("page", page.toString());
  }
  if (pageSize !== undefined) {
    queryParams.append("pageSize", pageSize.toString());
  }
  if (sales_agent !== undefined && sales_agent.length > 0) {
    queryParams.append("sales_agent", sales_agent);
  }
  if (owned_units !== undefined && owned_units.length > 0) {
    queryParams.append("owned_units", owned_units);
  }
  if (total_purchased_value !== undefined && total_purchased_value.length > 0) {
    queryParams.append("total_purchased_value", total_purchased_value);
  }
  if (sortBy !== undefined) {
    queryParams.append("sortBy", sortBy);
  }
  if (sortOrder !== undefined) {
    queryParams.append("sortOrder", sortOrder);
  }

  return `${API_URL}/investors?${queryParams.toString()}`;
};

export const fetchInvestor = async (investorId: number): Promise<IInvestor> => {
  const response = await fetch(`${API_URL}/investors/${investorId}`, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data;
};

export const createInvestor = async (
  first_name: string,
  last_name: string,
  email: string,
  phone_number: string,
  nationality: string,
  sales_agent_id: number,
  status_id: number
): Promise<IInvestor> => {
  const response = await fetch(`${API_URL}/investors`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      first_name,
      last_name,
      email,
      phone_number,
      nationality: nationality.trim().length > 0 ? nationality : null,
      sales_agent_id,
      status_id,
    }),
  });

  const data = await response.json();
  if (response.status === 201) return data;
  else throw new Error(data.message);
};

export const updateInvestor = async (
  investor: IInvestor
): Promise<IInvestor> => {
  const response = await fetch(`${API_URL}/investors/${investor.investor_id}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(investor),
  });

  const data = await response.json();
  if (response.status === 200) return data;
  else throw new Error(data.message);
};

export const deleteInvestor = async (investor_id: number): Promise<boolean> => {
  const response = await fetch(`${API_URL}/investors/${investor_id}`, {
    method: "DELETE",
    credentials: "include",
  });
  return response.status === 200;
};

export const uploadInvestorCsvFile = async (csv: File): Promise<void> => {
  const formData = new FormData();
  formData.append(`csv`, csv);

  const response = await fetch(`${API_URL}/investors/csv-import`, {
    method: "POST",
    credentials: "include",
    body: formData,
  });

  if (response.status === 201) return Promise.resolve();
  const data = await response.json();
  throw new Error(data.message);
};

export const exportInvestorCsv = async (
  investorIds: number[]
): Promise<Blob> => {
  const response = await fetch(`${API_URL}/investors/csv-export`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ investorIds }),
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message);
  }

  return await response.blob();
};

export const uploadInvestorAvatar = async (
  avatarFile: File
): Promise<string> => {
  const formData = new FormData();
  formData.append("avatar", avatarFile);

  const response = await fetch(`${API_URL}/investors/avatar`, {
    method: "PATCH",
    credentials: "include",
    body: formData,
  });

  const data = await response.json();
  if (response.status === 200) return data.avatar;
  else throw new Error(data.message);
};

export const uploadInvestorDocuments = async (
  investor_id: number,
  files: File[],
  document_type: DocumentType | null = null,
  valid_until_dates: (string | null)[] = [],
  internal_document_list: string[] = [],
  document_identifiers: (string | null)[] = []
): Promise<IDocument[]> => {
  const formData = new FormData();

  files.forEach((file, index) => {
    formData.append(`file_${index}`, file);
    if (valid_until_dates[index]) {
      formData.append(
        `valid_until_${index}`,
        valid_until_dates[index] as string
      );
    }
    if (internal_document_list[index]) {
      formData.append(
        `internal_document_${index}`,
        internal_document_list[index]
      );
    }
    if (document_identifiers[index]) {
      formData.append(
        `document_identifier_${index}`,
        document_identifiers[index] as string
      );
    }
  });

  const response = await fetch(
    `${API_URL}/investors/${investor_id}/${document_type ?? "documents"}`,
    {
      method: "POST",
      credentials: "include",
      body: formData,
    }
  );

  const data = await response.json();
  if (response.status === 201) return data.documents;
  else throw new Error(data.message);
};
