// PropertiesPage.tsx

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import PropertyCard from "../components/property-card/property-card";
import { useProperties } from "../hooks/useProperties";
import useAlertStore from "../stores/alertStore";
import Lottie from "lottie-react";
import LoadingAnim from "../assets/anims/loading.-anim.json";
import usePageTitle from "../hooks/usePageTitle";
import useEmployeeStore from "../stores/employeeStore";
import { EmployeeRole } from "../services/employeeService";
import CustomButton from "../components/custom-button/custom-button";
import { BsPlus } from "react-icons/bs";
import PropertySideModal from "../modals/sidebar/property-sidemodal/property-sidemodal";

const PropertiesPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Set page title
    usePageTitle(t("pages.properties.page_title"));

    // States
    const [isSidebarModalVisible, setIsSidebarModalVisible] = useState<boolean>(false);

    // Stores
    const { employee } = useEmployeeStore();
    const showAlert = useAlertStore(state => state.showAlert);
    const isAdmin = employee?.role_id === EmployeeRole.ADMINISTRATOR;

    // Hooks
    const {
        properties,
        isLoading,
        size,
        setSize,
        isReachingEnd,
        error,
        mutate,
    } = useProperties();

    useEffect(() => {
        if (error) {
            showAlert("error", error.message);
        }
    }, [error, showAlert]);

    const loadMore = () => {
        setSize(size + 1);
    };

    const onClickProperty = (propertyId: number, propertyName: string) => {
        navigate(`/properties/${propertyId}?propertyName=${encodeURIComponent(propertyName)}`);
    }

    return (
        <div className="no-select">
            {/* SECTION TITLE */}
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    {employee?.role_id === EmployeeRole.ADMINISTRATOR ? (
                        <>
                            <h4 className="my-0">{t("pages.properties.title", { number: properties.length })}</h4>
                        </>
                    ) : (
                        <>
                            <h4 className="m-0">{t("pages.dashboard.title", { name: employee?.first_name })}</h4>
                            <p style={{ margin: 0, fontSize: 14 }}>{t("pages.dashboard.subtitle")}</p>
                        </>
                    )}
                </div>
                {isAdmin && (
                    <div className="d-flex d-none d-sm-inline">
                        <CustomButton
                            title={t("pages.properties.create_property_button", { defaultValue: "Add Property" })}
                            icon={BsPlus}
                            isLoading={false}
                            onClick={() => setIsSidebarModalVisible(true)}
                        />
                    </div>
                )}
            </div>

            {/* MOBILE BUTTONS */}
            {isAdmin && (
                <div className="d-flex d-sm-none mt-3">
                    <CustomButton
                        title={t("pages.properties.create_property_button", { defaultValue: "Add Property" })}
                        icon={BsPlus}
                        isLoading={false}
                        onClick={() => setIsSidebarModalVisible(true)}
                    />
                </div>
            )}

            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </div>
            ) : (
                properties.length === 0 ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                        <p>{t("pages.properties.empty_message")}</p>
                    </div>
                ) : (
                    <InfiniteScroll
                        style={{ overflowY: "hidden", overflowX: "hidden" }}
                        dataLength={properties.length}
                        next={loadMore}
                        hasMore={!isReachingEnd}
                        loader={
                            <div className="d-flex justify-content-center mt-4">
                                <div className="spinner-border text-primary" role="status" />
                            </div>
                        }
                        scrollableTarget="content-area"
                    >
                        <div className="container-fluid p-3">
                            <div className="row g-0">
                                {properties.map((property, index) => (
                                    <div className="col-12 col-sm-6 col-xl-4" key={index}>
                                        <PropertyCard
                                            imageSrc={property.property_image}
                                            title={property.property_name}
                                            location={property.property_location}
                                            locationUrl={property.property_map_link}
                                            under_construction={property.under_construction}
                                            onClick={() => onClickProperty(property.property_id, property.property_name)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </InfiniteScroll>
                )
            )}

            {/* Property Sidebar Modal */}
            <PropertySideModal
                isVisible={isSidebarModalVisible}
                onClose={() => setIsSidebarModalVisible(false)}
                mutate={mutate}
            />
        </div>
    );
};

export default PropertiesPage;
