// TenantDetailPage.tsx
import "./tenant-detail-page.css";

import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { deleteTenant, updateTenant, uploadTenantDocuments } from "../../services/tenantService";
import useAlertStore from "../../stores/alertStore";
import CustomButton from "../../components/custom-button/custom-button";
import DefaultAvatar from "../../assets/images/default-avatar.webp";
import { getFormattedDate } from "../../utils/helpers";
import { UploadedFile } from "../../components/file-upload/file-upload";
import { useTranslation } from "react-i18next";
import { DocumentType, IDocument } from "../../services/documentService";
import useEmployeeStore from "../../stores/employeeStore";
import useTenantDetailStore from "../../stores/tenantDetailStore";
import { ITenant, fetchTenant } from "../../services/tenantService";
import Lottie from "lottie-react";
import LoadingAnim from "../../assets/anims/loading.-anim.json";
import DeleteAnim from "../../assets/anims/delete-anim.json";
import TenantGeneralSection from "./components/tenant-general-section/tenant-general-section";
import useEditPermission from "../../hooks/useEditPermission";
import DeleteConfirmationModal from "../../modals/delete-confirmation-modal/delete-confirmation-modal";
import FileUploadSection from "../../components/file-upload-section/file-upload-section";
import usePageTitle from "../../hooks/usePageTitle";
import TenantLeasesSection from './components/tenant-leases-section/tenant-leases-section';

// Enum für die verschiedenen Dokument-Tabs
enum DocumentTab {
    PASSPORT = 'passport',
    EMIRATES_ID = 'emirates_id',
    VISA = 'visa',
    OTHER = 'other',
    INTERNAL = 'internal'
}

const TenantDetailPage = () => {
    let { tenantId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const {
        tenant,
        isLoading,
        isDeleting,
        setTenant,
        setIsLoading,
        setIsDeleting,
    } = useTenantDetailStore();
    const employeeRoleId = useEmployeeStore(state => state.employee?.role_id);
    const hasEditPermission = useEditPermission(employeeRoleId);

    // States
    const [tenantOriginal, setTenantOriginal] = useState<ITenant | null>(null);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [passportFiles, setPassportFiles] = useState<UploadedFile[]>([]);
    const [emiratesIDFiles, setEmiratesIDFiles] = useState<UploadedFile[]>([]);
    const [visaFiles, setVisaFiles] = useState<UploadedFile[]>([]);
    const [files, setFiles] = useState<UploadedFile[]>([]);
    const [internalFiles, setInternalFiles] = useState<UploadedFile[]>([]);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);

    // Error States
    const [firstnameError, setFirstnameError] = useState<string>("");
    const [lastnameError, setLastnameError] = useState<string>("");
    const [nationalityError, setNationalityError] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [genderError, setGenderError] = useState<string>("");
    const [leadError, setLeadError] = useState<string>("");
    const [birthdateError, setBirthdateError] = useState<string>("");
    const [phoneNumberError, setPhoneNumberError] = useState<string>("");

    // Aktiver Tab für Dokumente
    const [activeDocumentTab, setActiveDocumentTab] = useState<DocumentTab>(DocumentTab.PASSPORT);
    
    // Zählt die Dokumente pro Tab
    const documentCounts = useMemo(() => {
        if (!tenant) return {
            [DocumentTab.PASSPORT]: 0,
            [DocumentTab.EMIRATES_ID]: 0,
            [DocumentTab.VISA]: 0,
            [DocumentTab.OTHER]: 0,
            [DocumentTab.INTERNAL]: 0
        };
        
        return {
            [DocumentTab.PASSPORT]: tenant.passport.length,
            [DocumentTab.EMIRATES_ID]: tenant.emirates_id.length,
            [DocumentTab.VISA]: tenant.visa.length,
            [DocumentTab.OTHER]: tenant.documents.filter(doc => !doc.internal_document).length,
            [DocumentTab.INTERNAL]: tenant.documents.filter(doc => doc.internal_document).length
        };
    }, [tenant]);

    // Fetch tenant data
    useEffect(() => {
        if (tenantId) {
            const loadTenant = async () => {
                try {
                    setIsLoading(true);
                    const tenant = await fetchTenant(Number(tenantId));
                    setTenant(tenant);
                    setTenantOriginal(tenant);
                } catch (error) {
                    console.log(`Error while fetching tenant with id ${tenantId}:`, error);
                    // @ts-ignore
                    showAlert("error", error.message);
                } finally {
                    setIsLoading(false);
                }
            }
            loadTenant();
        }
    }, [tenantId]);

    // Set page title
    usePageTitle(t("pages.tenant_detail.page_title", { name: tenant ? `${tenant.first_name} ${tenant.last_name}` : "" }));

    // Functions
    const onClickUpdateTenant = useCallback(async () => {
        if (tenant) {

            let isValid = true;

            // Check if the firstname is not empty
            if (!tenant.first_name.trim()) {
                setFirstnameError(t("invalid_input.firstname_empty"));
                isValid = false;
            }

            // Check if the lastname is not empty
            if (!tenant.last_name.trim()) {
                setLastnameError(t("invalid_input.lastname_empty"));
                isValid = false;
            }

            // Check if the dateOfBirth is not empty
            if (!tenant.birth_date) {
                setBirthdateError(t("invalid_input.date_of_birth_empty"));
                isValid = false;
            }

            // Check if the gender is not empty
            if (!tenant.gender.trim()) {
                setGenderError(t("invalid_input.gender_empty"));
                isValid = false;
            }

            // Check if the nationality is not empty
            if (!tenant.nationality_code.trim()) {
                setNationalityError(t("invalid_input.nationality_empty"));
                isValid = false;
            }

            // Check if the phone number is not empty
            if (!tenant.phone_number?.trim()) {
                setPhoneNumberError(t("invalid_input.phone_number_empty"));
                isValid = false;
            }

            // Check if the lead is not empty
            if (!tenant.source_of_lead.trim()) {
                setLeadError(t("invalid_input.source_of_lead_empty"));
                isValid = false;
            }

            // Basic email validation
            if (!tenant.email.trim()) {
                setEmailError(t("invalid_input.email_empty"));
                isValid = false;
            } else if (!/^\S+@\S+\.\S+$/.test(tenant.email.trim())) {
                setEmailError(t("invalid_input.email_invalid"));
                isValid = false;
            }

            if (isValid) {
                try {
                    setIsUpdating(true);
                    const updatedTenant = await updateTenant(tenant);
                    setTenant({
                        ...tenant,
                        email: updatedTenant.email,
                        phone_number: updatedTenant.phone_number,
                        first_name: updatedTenant.first_name,
                        last_name: updatedTenant.last_name,
                        blocked: updatedTenant.blocked
                    });

                    // Upload new documents
                    if (files.length > 0 || internalFiles.length > 0) {
                        const allFiles = [
                            ...files.map(file => ({ ...file, internal_document: false })),
                            ...internalFiles.map(file => ({ ...file, internal_document: true }))
                        ];
                        const uploadedDocuments = await uploadTenantDocuments(
                            tenant.tenant_id,
                            allFiles.map(file => file.file),
                            null,
                            [],
                            allFiles.map(file => file.internal_document ? "true" : "false")
                        );
                        const updatedDocuments = tenant.documents;
                        updatedDocuments.push(...uploadedDocuments);
                        setTenant({
                            ...tenant,
                            documents: updatedDocuments
                        });
                        setFiles([]);
                        setInternalFiles([]);
                    }

                    // Upload new passport documents
                    if (passportFiles.length > 0) {
                        // Filter passport files to ensure valid_until and document_identifier are provided
                        const validPassportFiles = passportFiles.filter(
                            (file) => file.valid_until !== null && file.valid_until !== undefined &&
                                file.document_identifier !== null && file.document_identifier !== undefined && file.document_identifier.trim() !== ""
                        );

                        if (validPassportFiles.length > 0) {
                            const newDocuments = await uploadTenantDocuments(
                                tenant.tenant_id,
                                passportFiles.map(file => file.file),
                                DocumentType.PASSPORT,
                                passportFiles.map(file => file.valid_until ? file.valid_until.toISOString() : null)
                            );
                            setPassportFiles([]);

                            const newPassportFiles = tenant.passport;
                            newPassportFiles.push(...newDocuments);
                            setTenant({ ...tenant, passport: newPassportFiles });
                            setTenantOriginal({ ...tenant, passport: newPassportFiles });
                        } else {
                            showAlert("error", t("pages.investor_detail.upload_passport_missing_fields_error"));
                            return;
                        }
                    }

                    // Upload new emirates id documents
                    if (emiratesIDFiles.length > 0) {
                        // Filter emirates id files to ensure valid_until and document_identifier are provided
                        const validEmiratesIDFiles = emiratesIDFiles.filter(
                            (file) => file.valid_until !== null && file.valid_until !== undefined &&
                                file.document_identifier !== null && file.document_identifier !== undefined && file.document_identifier.trim() !== ""
                        );

                        if (validEmiratesIDFiles.length > 0) {
                            const newDocuments = await uploadTenantDocuments(
                                tenant.tenant_id,
                                emiratesIDFiles.map(file => file.file),
                                DocumentType.EMIRATES_ID,
                                emiratesIDFiles.map(file => file.valid_until ? file.valid_until.toISOString() : null)
                            );

                            setEmiratesIDFiles([]);

                            const newEmiratesIDFiles = tenant.emirates_id;
                            newEmiratesIDFiles.push(...newDocuments);
                            setTenant({ ...tenant, emirates_id: newEmiratesIDFiles });
                            setTenantOriginal({ ...tenant, emirates_id: newEmiratesIDFiles });
                        } else {
                            showAlert("error", t("pages.investor_detail.upload_emirates_id_missing_fields_error"));
                            return;
                        }
                    }

                    // Upload new visa documents
                    if (visaFiles.length > 0) {
                        const newDocuments = await uploadTenantDocuments(
                            tenant.tenant_id,
                            visaFiles.map(file => file.file),
                            DocumentType.VISA,
                            visaFiles.map(file => file.valid_until ? file.valid_until.toISOString() : null)
                        );
                        setVisaFiles([]);

                        const newVisaFiles = tenant.visa;
                        newVisaFiles.push(...newDocuments);
                        setTenant({ ...tenant, visa: newVisaFiles });
                        setTenantOriginal({ ...tenant, visa: newVisaFiles });
                    }

                    showAlert("success", t("pages.tenant_detail.save_success_message"));
                } catch (error) {
                    console.log('Error while updating tenant: ', error);
                    // @ts-ignore
                    showAlert("error", error.message);
                } finally {
                    setIsUpdating(false);
                }
            }
        }
    }, [tenant, files, internalFiles, passportFiles, emiratesIDFiles, visaFiles, setTenant, showAlert, t]);

    const onDeleteTenantDocument = useCallback(async (document: IDocument) => {
        if (tenant) {
            if (document.document_type === DocumentType.PASSPORT) {
                const passport = tenant.passport.filter(file => file.document_id !== document.document_id);
                setTenant({ ...tenant, passport });
                setTenantOriginal({ ...tenant, passport });
            }
            else if (document.document_type === DocumentType.EMIRATES_ID) {
                const emiratesID = tenant.emirates_id.filter(file => file.document_id !== document.document_id);
                setTenant({ ...tenant, emirates_id: emiratesID });
                setTenantOriginal({ ...tenant, emirates_id: emiratesID });
            }
            else if (document.document_type === DocumentType.VISA) {
                const visa = tenant.visa.filter(file => file.document_id !== document.document_id);
                setTenant({ ...tenant, visa: visa });
                setTenantOriginal({ ...tenant, visa: visa });
            }
            else {
                const documents = tenant.documents.filter(file => file.document_id !== document.document_id);
                setTenant({ ...tenant, documents });
                setTenantOriginal({ ...tenant, documents });
            }
        }
    }, [tenant]);

    const onDeleteTenant = useCallback(async () => {
        try {
            setIsDeleteModalVisible(false);
            setIsDeleting(true);

            const tenantDeleted = await deleteTenant(Number(tenantId));
            if (tenantDeleted) {
                navigate("/tenants");
                showAlert("success", t("pages.tenant_detail.delete_success_message"));
            }
        } catch (error) {
            console.log('Error while deleting tenant:', error);
            // @ts-ignore
            showAlert("error", error.message);
        } finally {
            setIsDeleting(false);
        }
    }, [tenantId, t, setIsDeleteModalVisible, setIsDeleting, showAlert, navigate]);

    const onClearError = (field: String) => {
        if (field === "first_name") {
            setFirstnameError("");
        }
        else if (field === "last_name") {
            setLastnameError("");
        }
        else if (field === "nationality_code") {
            setNationalityError("");
        }
        else if (field === "gender") {
            setGenderError("");
        }
        else if (field === "email") {
            setEmailError("");
        }
        else if (field === "phone_number") {
            setPhoneNumberError("");
        }
        else if (field === "source_of_lead") {
            setLeadError("");
        }
        else if (field === "birth_date") {
            setBirthdateError("");
        }
    }

    const dataHasBeenChanged = useMemo(() => {
        return !_.isEqual(tenant, tenantOriginal) || passportFiles.length > 0 || emiratesIDFiles.length > 0 || visaFiles.length > 0 || files.length > 0 || internalFiles.length > 0;
    }, [tenant, tenantOriginal, passportFiles, emiratesIDFiles, visaFiles, files, internalFiles]);

    return (
        <div className="no-select">
            {isLoading || isDeleting ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
                    <Lottie animationData={isLoading ? LoadingAnim : DeleteAnim} loop={true} style={{ height: isLoading ? 100 : 150 }} />
                </div>
            ) : (
                tenant && (
                    <>
                        {/* SECTION TITLE */}
                        <div className="d-flex justify-content-between align-items-center">
                            {tenant && (
                                <>
                                    <div className="d-flex align-items-center">
                                        <div className="d-flex">
                                            <img style={{ width: 50, height: 50, borderRadius: 25 }} src={DefaultAvatar} alt="Avatar" />
                                            <div className="ms-3">
                                                <div className="d-flex">
                                                    <h4 className="m-0 me-2">{tenant.first_name} {tenant.last_name}</h4>
                                                    {/*<InvestorStatusBadge status={tenant.status.status_name} />*/}
                                                </div>
                                                <p style={{ margin: 0, fontSize: 14 }}>{t("pages.tenant_detail.subtitle", { date: getFormattedDate(tenant.created_at) })} | ID: {tenant.tenant_id}</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* DESKTOP BUTTON */}
                                    {hasEditPermission && (
                                        <div className="d-none d-md-flex">
                                            {!isUpdating && (
                                                <CustomButton
                                                    className="d-none d-md-block me-3"
                                                    title={t("buttons.delete")}
                                                    color="red"
                                                    isLoading={isDeleting}
                                                    onClick={() => setIsDeleteModalVisible(true)}
                                                />
                                            )}
                                            {!isDeleting && (
                                                <CustomButton
                                                    className="d-none d-md-block"
                                                    title={t("buttons.save")}
                                                    isLoading={isUpdating}
                                                    disabled={!dataHasBeenChanged}
                                                    onClick={onClickUpdateTenant}
                                                />
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>

                        {/* TENANT OVERVIEW */}
                        {tenant && (
                            <div className="row gy-4 mt-3">
                                <div className="col-12">
                                    <TenantGeneralSection
                                        employeeRoleId={employeeRoleId}
                                        tenant={tenant}
                                        firstnameError={firstnameError}
                                        lastnameError={lastnameError}
                                        nationalityError={nationalityError}
                                        emailError={emailError}
                                        phoneNumberError={phoneNumberError}
                                        genderError={genderError}
                                        leadError={leadError}
                                        birthdateError={birthdateError}
                                        onUpdateTenant={setTenant}
                                        onClearError={onClearError}
                                    />
                                </div>
                            </div>
                        )}

                        {/* TENANT LEASES SECTION */}
                        <div className="row gy-4 mt-4">
                            <div className="col-12">
                                <TenantLeasesSection 
                                    leases={tenant.leases}
                                    employeeRoleId={employeeRoleId}
                                />
                            </div>
                        </div>

                        {/* DOCUMENTS SECTION WITH TABS */}
                        <div className="documents-container">
                            <h4 className="mb-3">{t("components.document_section.documents", "Documents")}</h4>

                            <div className="document-tabs">
                                <div
                                    className={`document-tab ${activeDocumentTab === DocumentTab.PASSPORT ? 'active' : ''}`}
                                    onClick={() => setActiveDocumentTab(DocumentTab.PASSPORT)}
                                >
                                    {t("labels.passport")}
                                    {documentCounts[DocumentTab.PASSPORT] > 0 &&
                                        <span className="document-tab-badge">{documentCounts[DocumentTab.PASSPORT]}</span>}
                                </div>

                                <div
                                    className={`document-tab ${activeDocumentTab === DocumentTab.EMIRATES_ID ? 'active' : ''}`}
                                    onClick={() => setActiveDocumentTab(DocumentTab.EMIRATES_ID)}
                                >
                                    {t("labels.emirates_id")}
                                    {documentCounts[DocumentTab.EMIRATES_ID] > 0 &&
                                        <span className="document-tab-badge">{documentCounts[DocumentTab.EMIRATES_ID]}</span>}
                                </div>

                                <div
                                    className={`document-tab ${activeDocumentTab === DocumentTab.VISA ? 'active' : ''}`}
                                    onClick={() => setActiveDocumentTab(DocumentTab.VISA)}
                                >
                                    {t("labels.visa")}
                                    {documentCounts[DocumentTab.VISA] > 0 &&
                                        <span className="document-tab-badge">{documentCounts[DocumentTab.VISA]}</span>}
                                </div>

                                <div
                                    className={`document-tab ${activeDocumentTab === DocumentTab.OTHER ? 'active' : ''}`}
                                    onClick={() => setActiveDocumentTab(DocumentTab.OTHER)}
                                >
                                    {t("components.document_section.other_documents", "Other Documents")}
                                    {documentCounts[DocumentTab.OTHER] > 0 &&
                                        <span className="document-tab-badge">{documentCounts[DocumentTab.OTHER]}</span>}
                                </div>

                                <div
                                    className={`document-tab ${activeDocumentTab === DocumentTab.INTERNAL ? 'active' : ''}`}
                                    onClick={() => setActiveDocumentTab(DocumentTab.INTERNAL)}
                                >
                                    {t("components.document_section.internal_documents", "Internal Documents")}
                                    {documentCounts[DocumentTab.INTERNAL] > 0 &&
                                        <span className="document-tab-badge">{documentCounts[DocumentTab.INTERNAL]}</span>}
                                </div>
                            </div>

                            <div className="document-tab-content">
                                {(() => {
                                    switch (activeDocumentTab) {
                                        case DocumentTab.PASSPORT:
                                            return (
                                                <div key="passport" className="tab-pane-content">
                                                    <FileUploadSection
                                                        className="mt-0"
                                                        fileUploadId="tenant-upload-passport"
                                                        title={t("labels.passport")}
                                                        documents={tenant.passport}
                                                        uploadedFiles={passportFiles}
                                                        onDeleteDocument={onDeleteTenantDocument}
                                                        onUpdateUploadedFiles={setPassportFiles}
                                                        maxFilesToUpload={2}
                                                        hasEditPermission={hasEditPermission}
                                                        isDefaultExpanded
                                                        allowExpirationDate
                                                        allowDocumentIdentifier
                                                    />
                                                </div>
                                            );
                                        case DocumentTab.EMIRATES_ID:
                                            return (
                                                <div key="emirates-id" className="tab-pane-content">
                                                    <FileUploadSection
                                                        className="mt-0"
                                                        fileUploadId="tenant-upload-emirates-id"
                                                        title={t("labels.emirates_id")}
                                                        documents={tenant.emirates_id}
                                                        uploadedFiles={emiratesIDFiles}
                                                        onDeleteDocument={onDeleteTenantDocument}
                                                        onUpdateUploadedFiles={setEmiratesIDFiles}
                                                        maxFilesToUpload={2}
                                                        hasEditPermission={hasEditPermission}
                                                        isDefaultExpanded
                                                        allowExpirationDate
                                                        allowDocumentIdentifier
                                                    />
                                                </div>
                                            );
                                        case DocumentTab.VISA:
                                            return (
                                                <div key="visa" className="tab-pane-content">
                                                    <FileUploadSection
                                                        className="mt-0"
                                                        fileUploadId="tenant-upload-visa"
                                                        title={t("labels.visa")}
                                                        documents={tenant.visa}
                                                        uploadedFiles={visaFiles}
                                                        onDeleteDocument={onDeleteTenantDocument}
                                                        onUpdateUploadedFiles={setVisaFiles}
                                                        maxFilesToUpload={1}
                                                        hasEditPermission={hasEditPermission}
                                                        isDefaultExpanded
                                                        allowExpirationDate
                                                    />
                                                </div>
                                            );
                                        case DocumentTab.OTHER:
                                            return (
                                                <div key="other" className="tab-pane-content">
                                                    <FileUploadSection
                                                        className="mt-0"
                                                        fileUploadId="tenant-upload-other-documents"
                                                        title={t("components.document_section.available_documents_title", { number: tenant.documents.filter(document => document.internal_document === false).length })}
                                                        subtitle={t("components.document_section.provide_documents_subtitle")}
                                                        documents={tenant.documents.filter(document => document.internal_document === false)}
                                                        uploadedFiles={files}
                                                        onDeleteDocument={onDeleteTenantDocument}
                                                        onUpdateUploadedFiles={setFiles}
                                                        hasEditPermission={hasEditPermission}
                                                        isDefaultExpanded
                                                    />
                                                </div>
                                            );
                                        case DocumentTab.INTERNAL:
                                            return (
                                                <div key="internal" className="tab-pane-content">
                                                    <FileUploadSection
                                                        className="mt-0"
                                                        fileUploadId="tenant-upload-internal-documents"
                                                        title={t("components.document_section.internal_documents_title", { number: tenant.documents.filter(document => document.internal_document === true).length })}
                                                        subtitle={t("components.document_section.internal_documents_subtitle")}
                                                        documents={tenant.documents.filter(document => document.internal_document === true)}
                                                        uploadedFiles={internalFiles}
                                                        onDeleteDocument={onDeleteTenantDocument}
                                                        onUpdateUploadedFiles={setInternalFiles}
                                                        hasEditPermission={hasEditPermission}
                                                        isDefaultExpanded
                                                    />
                                                </div>
                                            );
                                        default:
                                            return null;
                                    }
                                })()}
                            </div>
                        </div>

                        {/* MOBILE BUTTON */}
                        {hasEditPermission && (
                            <div className="d-md-none">
                                {!isDeleting && (
                                    <CustomButton
                                        className="mt-4 w-100"
                                        title={t("buttons.save")}
                                        isLoading={isUpdating}
                                        disabled={!dataHasBeenChanged}
                                        onClick={onClickUpdateTenant}
                                    />
                                )}
                                {!isUpdating && (
                                    <CustomButton
                                        className="mt-3 w-100"
                                        title={t("buttons.delete")}
                                        color="red"
                                        isLoading={isDeleting}
                                        onClick={() => setIsDeleteModalVisible(true)}
                                    />
                                )}
                            </div>
                        )}
                    </>
                )
            )}

            {/* DELETE CONFIRMATION MODAL */}
            {isDeleteModalVisible && hasEditPermission && (
                <DeleteConfirmationModal
                    title={t("pages.tenant_detail.delete_modal.title")}
                    message={t("pages.tenant_detail.delete_modal.message")}
                    onClose={() => setIsDeleteModalVisible(false)}
                    onDelete={onDeleteTenant}
                />
            )}
        </div>
    );
};

export default TenantDetailPage;
