export interface IDashboardData {
  dataNumbers: number[];
  totalInvestors: number;
  activeInvestors: number;
  loggedInInvestors: number;
  verifiedInvestors: number;
  unitsCovered: number;
  totalUnits: number;
  totalPMContracts: number;
  totalPMContractsUnsigned: number;
  totalPMPremium: number;
  totalPMBasic: number;
  totalPMOnboarding: number;
  totalDocumentsInReview: number;
}

export const fetchDashboardData = async (
  url: string
): Promise<IDashboardData> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data;
};
