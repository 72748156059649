import { SortOrder } from "../@types";
import { API_URL } from "../utils/constants";
import { IDocument } from "./documentService";
import { ILeasePayment } from "./leasePaymentService";
import { ITenant } from "./tenantService";
import { IUnit } from "./unitService";

export interface ILeaseOccupant {
  occupant_id: number;
  name: string;
  email: string;
}

export interface ILease {
  lease_id: number;
  unit: IUnit;
  tenant: ITenant;
  start_date: string;
  end_date: string;
  annual_rent_amount: number;
  total_rent_amount: number;
  deposit_amount: number;
  remark: string;
  created_at: string;
  updated_at: string;
  terminated: boolean;
  documents: IDocument[];
  payments: ILeasePayment[];
  occupants: ILeaseOccupant[];
}

export enum LeaseSortBy {
  TENANT = "tenant",
  UNIT_NUMBER = "unit_number",
  RENT = "total_rent_amount",
  SECURITY_DEPOSIT = "deposit_amount",
}

export const fetchLeases = async (url: string): Promise<ILease[]> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.leases;
};

export const constructLeasesUrl = (
  propertyId: number | null,
  search: string,
  page?: number,
  pageSize?: number,
  total_rent_amount?: string,
  security_deposit?: string,
  payment_frequency?: string,
  duration?: string,
  sortBy?: LeaseSortBy,
  sortOrder?: SortOrder
): string => {
  let queryParams = new URLSearchParams();

  if (search.trim().length > 0) {
    queryParams.append("search", encodeURIComponent(search));
  }
  if (propertyId !== null) {
    queryParams.append("propertyId", propertyId.toString());
  }
  if (page !== undefined) {
    queryParams.append("page", page.toString());
  }
  if (pageSize !== undefined) {
    queryParams.append("pageSize", pageSize.toString());
  }
  if (total_rent_amount !== undefined && total_rent_amount.length > 0) {
    queryParams.append("total_rent_amount", total_rent_amount);
  }
  if (security_deposit !== undefined && security_deposit.length > 0) {
    queryParams.append("deposit_amount", security_deposit);
  }
  if (payment_frequency !== undefined && payment_frequency.length > 0) {
    queryParams.append("payment_frequency", payment_frequency);
  }
  if (duration !== undefined && duration.length > 0) {
    queryParams.append("duration", duration);
  }
  if (sortBy !== undefined) {
    queryParams.append("sortBy", sortBy);
  }
  if (sortOrder !== undefined) {
    queryParams.append("sortOrder", sortOrder);
  }

  return `${API_URL}/leases?${queryParams.toString()}`;
};

export const fetchLease = async (lease_id: number): Promise<ILease> => {
  const response = await fetch(`${API_URL}/leases/${lease_id}`, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data;
};

export const deleteLease = async (lease_id: number): Promise<boolean> => {
  const response = await fetch(`${API_URL}/leases/${lease_id}`, {
    method: "DELETE",
    credentials: "include",
  });
  return response.status === 200;
};

export const createLease = async (
  unit_id: number,
  tenant_id: number,
  start_date: Date,
  end_date: Date,
  occupants: ILeaseOccupant[],
  annual_rent_amount: number,
  total_rent_amount: number,
  deposit_amount: number,
  pet_deposit_amount: number,
  remark: string,
  rental_payments: number
): Promise<ILease> => {
  const response = await fetch(`${API_URL}/leases`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      unit_id,
      tenant_id,
      start_date,
      end_date,
      occupants,
      annual_rent_amount,
      total_rent_amount,
      deposit_amount,
      pet_deposit_amount,
      remark,
      rental_payments,
    }),
  });

  const data = await response.json();
  if (response.status === 201) return data;
  else throw new Error(data.message);
};

export const createLeaseOccupant = async (
  lease_id: number,
  name: string,
  email: string
): Promise<ILeaseOccupant> => {
  const response = await fetch(`${API_URL}/leases/${lease_id}/occupants`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      email,
    }),
  });

  const data = await response.json();
  if (response.status === 201) return data;
  else throw new Error(data.message);
};

export const deleteLeaseOccupant = async (
  lease_id: number,
  occupant_id: number
): Promise<boolean> => {
  const response = await fetch(
    `${API_URL}/leases/${lease_id}/occupants/${occupant_id}`,
    {
      method: "DELETE",
      credentials: "include",
    }
  );
  return response.status === 200;
};

export const updateLease = async (lease: ILease): Promise<ILease> => {
  const response = await fetch(`${API_URL}/leases/${lease.lease_id}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(lease),
  });

  const data = await response.json();
  if (response.status === 200) return data;
  else throw new Error(data.message);
};

export const uploadLeaseDocuments = async (
  lease_id: number,
  file: File
): Promise<IDocument> => {
  const formData = new FormData();

  formData.append(`file_contract`, file);

  const response = await fetch(`${API_URL}/leases/${lease_id}/document`, {
    method: "POST",
    credentials: "include",
    body: formData,
  });

  const data = await response.json();
  if (response.status === 201) return data;
  else throw new Error(data.message);
};

export const exportLeaseCsv = async (leaseIds: number[]): Promise<Blob> => {
  const response = await fetch(`${API_URL}/leases/csv-export`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ leaseIds }),
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message);
  }

  return await response.blob();
};
