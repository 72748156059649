import { useEffect } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import Switch, { SwitchOptions } from "../../components/switch/switch";
import useSearchStore from "../../stores/searchStore";
import { useTranslation } from "react-i18next";
import useAlertStore from "../../stores/alertStore";
import { getCurrentFormattedDateTime } from "../../utils/helpers";
import useDebounce from "../../hooks/useDebounce";
import Lottie from "lottie-react";
import LoadingAnim from "../../assets/anims/loading.-anim.json";
import usePageTitle from "../../hooks/usePageTitle";
import { useDocuments } from "../../hooks/useDocuments";
import useDocumentFilterStore from "../../stores/documentFilterStore";
import { DocumentStatus } from "../../services/documentService";
import DocumentTable from "../../components/document-table/document-table";

const DocumentsPage = () => {
    const { t } = useTranslation();

    // Set page title
    usePageTitle(t("pages.documents.page_title"));

    // Stores
    const search = useSearchStore(state => state.search);
    const showAlert = useAlertStore(state => state.showAlert);
    const { selectedState, setSelectedState } = useDocumentFilterStore();

    const switchOptions: SwitchOptions[] = [
        { label: t("pages.documents.filter.all"), value: "all" },
        { label: t("pages.documents.filter.accepted"), value: "accepted" },
        { label: t("pages.documents.filter.in_review"), value: "in_review" },
        { label: t("pages.documents.filter.declined"), value: "declined" },
    ];

    // Hooks
    const debouncedKeyword = useDebounce(search, 500);
    const {
        documents,
        isLoading,
        size,
        setSize,
        mutate,
        isReachingEnd,
        error,
    } = useDocuments(selectedState, debouncedKeyword);

    useEffect(() => {
        if (error) {
            showAlert("error", error.message);
        }
    }, [error, showAlert]);

    const loadMore = () => {
        setSize(size + 1);
    };

    const handleFilterClick = (value: string) => {
        let status = null;

        switch (value) {
            case "accepted":
                status = DocumentStatus.ACCEPTED;
                break;
            case "in_review":
                status = DocumentStatus.IN_REVIEW;
                break;
            case "declined":
                status = DocumentStatus.DECLINED;
                break;
            default:
                status = null; // 'all'
        }

        setSelectedState(status);
        setSize(1);
    };

    return (
        <div className="no-select">
            {/* SECTION TITLE */}
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h4 className="m-0">{t("pages.documents.title")}</h4>
                    <p style={{ margin: 0, fontSize: 14 }}>{t("pages.documents.subtitle", { date: getCurrentFormattedDateTime() })}</p>
                </div>
            </div>

            {/* FILTER BUTTONS */}
            <div className="d-none d-sm-flex justify-content-between align-items-center mt-4">
                <Switch options={switchOptions} value={selectedState ? selectedState.toString() : "all"} onChange={handleFilterClick} />
            </div>

            <Switch className="d-sm-none mt-4" value={selectedState ? selectedState.toString() : "all"} options={switchOptions} onChange={handleFilterClick} />

            {/* DOCUMENTS OVERVIEW */}
            {isLoading ? (
                <div className="mt-4 white-card" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </div>
            ) : (
                <>
                    <div className="mt-4 white-card">
                        <InfiniteScroll
                            style={{ overflow: "visible" }}
                            dataLength={documents.length}
                            next={loadMore}
                            hasMore={!isReachingEnd}
                            loader={
                                <div className="d-flex justify-content-center mt-4">
                                    <div className="spinner-border text-primary" role="status" />
                                </div>
                            }
                            scrollableTarget="content-area"
                        >
                            <DocumentTable
                                documents={documents}
                                mutate={mutate}
                            />
                        </InfiniteScroll>
                    </div>
                </>
            )}
        </div>
    );
};

export default DocumentsPage;
