// PMContractsPage.tsx

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CustomButton from "../../components/custom-button/custom-button";
import { BsPlus } from "react-icons/bs";
import InfiniteScroll from 'react-infinite-scroll-component';
import { getCurrentFormattedDateTime } from "../../utils/helpers";
import { useTranslation } from "react-i18next";
import Switch from "../../components/switch/switch";
import useEmployeeStore from "../../stores/employeeStore";
import { EmployeeRole } from "../../services/employeeService";
import { useProperties } from "../../hooks/useProperties";
import useAlertStore from "../../stores/alertStore";
import Lottie from "lottie-react";
import LoadingAnim from "../../assets/anims/loading.-anim.json";
import usePageTitle from "../../hooks/usePageTitle";
import PMContractFilters from "./components/pm-contract-filters/pm-contract-filters";
import usePMContractFilterStore from "../../stores/pmContractFilterStore";
import { usePMContracts } from "../../hooks/usePMContracts";
import { IPropertyManagementContract, PMContractSortBy } from "../../services/pmContractService";
import PMContractTable from "./components/pm-contract-table/pm-contract-table";
import useSearchStore from "../../stores/searchStore";
import useDebounce from "../../hooks/useDebounce";
import AddPMContractSidebarModal from "../../modals/sidebar/add-pm-contract-sidemodal/add-pm-contract-sidemodal";
import ImportPMContractsModal from "../../modals/pm-contract-csv-import-modal/pm-contract-csv-import-modal";
import { SortOrder } from "../../@types";

const PMContractsPage = () => {
    const { t } = useTranslation();

    // References
    const fileInputRef = useRef<HTMLInputElement>(null);

    // Set page title
    usePageTitle(t("pages.pm_contracts.page_title"));

    // Stores
    const employeeRoleId = useEmployeeStore(state => state.employee?.role_id);
    const search = useSearchStore(state => state.search);
    const showAlert = useAlertStore(state => state.showAlert);
    const {
        pm_package,
        selectedProperty,
        sortColumn,
        sortDirection,
        setSort,
        setSelectedProperty,
    } = usePMContractFilterStore();

    // States
    const [selectedContract, setSelectedContract] = useState<IPropertyManagementContract | null>(null);
    const [isSidebarModalVisible, setIsSidebarModalVisible] = useState<boolean>(false);
    const [csvFile, setCSVFile] = useState<File | null>(null);

    // Hooks
    const debouncedKeyword = useDebounce(search, 500);
    const { properties } = useProperties();
    const {
        contracts,
        isLoading,
        size,
        setSize,
        mutate,
        isReachingEnd,
        error,
    } = usePMContracts(
        selectedProperty,
        debouncedKeyword,
        pm_package ?? undefined,
        sortColumn || undefined,
        sortDirection || undefined
    );

    const propertyOptions = useMemo(() => {
        return [
            ...properties.map(property => ({
                label: `${property.property_name}`,
                value: property.property_id.toString(),
            }))
        ];
    }, [properties]);

    useEffect(() => {
        if (selectedProperty === null && propertyOptions.length > 0) {
            setSelectedProperty(Number(propertyOptions[0].value));
        }
    }, [propertyOptions, selectedProperty, setSelectedProperty]);

    useEffect(() => {
        if (error) {
            showAlert("error", error.message);
        }
    }, [error, showAlert]);

    const loadMore = () => {
        setSize(size + 1);
    };

    const handleFilterClick = (value: string) => {
        const index = Number(value);
        setSelectedProperty(index === 0 ? null : index);
        setSize(1);
    }

    const onContractCreated = useCallback((pm_contract: IPropertyManagementContract) => {
        setIsSidebarModalVisible(false);
        mutate(
            (data: IPropertyManagementContract[][] | undefined) => {
                if (!data) return [];
                return [
                    [pm_contract, ...data[0]],
                    ...data.slice(1)
                ];
            },
            false
        );
    }, [mutate]);

    const onContractUpdated = useCallback((pm_contract: IPropertyManagementContract) => {
        setIsSidebarModalVisible(false);
        setSelectedContract(null);

        mutate((data: IPropertyManagementContract[][] | undefined) => {
            if (!data) return [];

            return data.map((contractPage) =>
                contractPage.map((contract) =>
                    contract.contract_id === pm_contract.contract_id ? pm_contract : contract
                )
            );
        }, false);
    }, [mutate]);

    const onContractDeleted = useCallback((contract_id: number) => {
        setIsSidebarModalVisible(false);
        setSelectedContract(null);
        mutate(
            (data: IPropertyManagementContract[][] | undefined) => {
                if (!data) return [];

                return data.map(contractList =>
                    contractList.filter(contract => contract.contract_id !== contract_id)
                );
            },
            false
        );
    }, [mutate]);

    const handleFileSelectClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setCSVFile(file);
        }
    };

    const handleSortChange = (column: PMContractSortBy) => {
        const newDirection: SortOrder = sortColumn === column && sortDirection === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
        setSort(column, newDirection);
        setSize(1);
    };

    return (
        <div className="no-select">
            {/* SECTION TITLE */}
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h4 className="m-0">{t("pages.pm_contracts.title")}</h4>
                    <p style={{ margin: 0, fontSize: 14 }}>{t("pages.pm_contracts.subtitle", { date: getCurrentFormattedDateTime() })}</p>
                </div>
                {(employeeRoleId === EmployeeRole.ADMINISTRATOR || employeeRoleId === EmployeeRole.PROPERTY_MANAGER) && (
                    <div className="d-flex d-none d-sm-inline">
                        <CustomButton
                            className="me-3"
                            title={t("buttons.csv_import")}
                            color="green"
                            isLoading={false}
                            onClick={handleFileSelectClick}
                        />
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            accept=".csv"
                            onChange={handleFileChange}
                        />
                        <CustomButton
                            title={t("pages.pm_contracts.create_button")}
                            icon={BsPlus}
                            isLoading={false}
                            onClick={() => setIsSidebarModalVisible(true)}
                        />
                    </div>
                )}
            </div>

            {/* MOBILE BUTTONS */}
            {(employeeRoleId === EmployeeRole.ADMINISTRATOR || employeeRoleId === EmployeeRole.PROPERTY_MANAGER) && (
                <div className="d-flex d-sm-none mt-3">
                    <CustomButton
                        title={t("pages.pm_contracts.create_button")}
                        icon={BsPlus}
                        isLoading={false}
                        onClick={() => setIsSidebarModalVisible(true)}
                    />
                </div>
            )}

            {/* FILTER BUTTONS  */}
            <div className="d-none d-sm-flex justify-content-between align-items-center mt-4">
                <Switch options={propertyOptions} value={selectedProperty ? selectedProperty.toString() : "0"} onChange={handleFilterClick} />
            </div>

            <Switch className="d-sm-none mt-4" options={propertyOptions} value={selectedProperty ? selectedProperty.toString() : "0"} onChange={handleFilterClick} />

            {/* PM CONTRACTS OVERVIEW */}
            {isLoading ? (
                <div className="mt-4 white-card" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </div>
            ) : (
                <>
                    <PMContractFilters onFilterUpdated={() => setSize(1)} />
                    <div className="mt-4 white-card">
                        <InfiniteScroll
                            style={{ overflowY: "hidden", overflowX: "hidden" }}
                            dataLength={contracts.length}
                            next={loadMore}
                            hasMore={!isReachingEnd}
                            loader={
                                <div className="d-flex justify-content-center mt-4">
                                    <div className="spinner-border text-primary" role="status" />
                                </div>
                            }
                            scrollableTarget="content-area"
                        >
                            <PMContractTable
                                contracts={contracts}
                                selectedSortColumn={sortColumn}
                                selectedSortDirection={sortDirection}
                                onSortChange={handleSortChange}
                                onClickContract={(contract) => {
                                    setSelectedContract(contract);
                                    setIsSidebarModalVisible(true);
                                }}
                            />
                        </InfiniteScroll>
                    </div>
                </>
            )}

            {/* CREATE PM Contract SIDEBAR MODAL */}
            {(employeeRoleId === EmployeeRole.ADMINISTRATOR || employeeRoleId === EmployeeRole.PROPERTY_MANAGER) && (
                <>
                    <AddPMContractSidebarModal
                        isVisible={isSidebarModalVisible}
                        selectedContract={selectedContract ?? undefined}
                        onClose={() => setIsSidebarModalVisible(false)}
                        onAddContract={onContractCreated}
                        onUpdateContract={onContractUpdated}
                        onDeleteContract={onContractDeleted}
                    />

                    {csvFile && (
                        <ImportPMContractsModal
                            csv={csvFile}
                            onClose={(refresh: boolean) => {
                                setCSVFile(null)
                                if (refresh) {
                                    window.location.reload();
                                }
                            }}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default PMContractsPage;
