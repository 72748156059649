import './payments-table.css';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../../components/custom-button/custom-button';
import { formatMoney, getFormattedDate } from '../../../../utils/helpers';
import { SortOrder } from '../../../../@types';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import { exportPaymentsCsv, formatPaymentPurpose, formatPaymentStatus, IPayment, PaymentSortBy } from '../../../../services/paymentService';
import { IoReceipt } from 'react-icons/io5';
import { downloadDocument, IDocument } from '../../../../services/documentService';

interface PaymentsTableProps {
    payments: IPayment[];
    selectedSortColumn: PaymentSortBy | null;
    selectedSortDirection: SortOrder | null;
    onSortChange: (sortColumn: PaymentSortBy, sortDirection: SortOrder) => void;
}

const PaymentsTable: React.FC<PaymentsTableProps> = ({
    payments,
    selectedSortColumn,
    selectedSortDirection,
    onSortChange,
}) => {
    const { t } = useTranslation();

    // State management
    const [selectedPayments, setSelectedPayments] = useState<Set<number>>(new Set());
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSort = (column: PaymentSortBy) => {
        const direction = selectedSortColumn === column && selectedSortDirection === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
        onSortChange(column, direction);
    };

    const handleCheckboxChange = (leaseId: number) => {
        setSelectedPayments(prevSelected => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(leaseId)) {
                newSelected.delete(leaseId);
            } else {
                newSelected.add(leaseId);
            }
            return newSelected;
        });
    };

    const handleExport = async () => {
        const selected = Array.from(selectedPayments);
        setIsLoading(true);
        try {
            const blob = await exportPaymentsCsv(selected);

            // Generate current date and time string
            const now = new Date();
            const formattedDate = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // yyyy-mm-dd
            const formattedTime = now.toLocaleTimeString().replace(/:/g, "-"); // hh-mm-ss    

            const fileName = `payments_${formattedDate}_${formattedTime}.csv`;

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();

            // Reset selected leases
            setSelectedPayments(new Set());
        } catch (error) {
            console.error("Error exporting payments:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const onDownloadFile = async (doc: IDocument) => {
        try {
            setIsLoading(true);
            const blob: Blob | null = await downloadDocument(doc.document_id);
            if (blob) {
                const blobUrl = window.URL.createObjectURL(blob);

                const anchor = document.createElement('a');
                anchor.href = blobUrl;
                anchor.download = doc.file_name;
                document.body.appendChild(anchor);
                anchor.click();

                window.URL.revokeObjectURL(blobUrl);
                document.body.removeChild(anchor);
            }
        } catch (error) {
            console.log("error while downloading file: ", error);
            // @ts-ignore
            showAlert("error", error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            {selectedPayments.size > 0 && (
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="d-flex align-items-center ms-auto">
                        <span className="me-3">{selectedPayments.size} {t("labels.selected")}</span>
                        <CustomButton
                            title={t("buttons.csv_export")}
                            isLoading={isLoading}
                            onClick={handleExport}
                        />
                    </div>
                </div>
            )}
            {payments.length > 0 ? (
                <div className="payments-overview-table-responsive">
                    <table className="payments-overview-table">
                        <thead>
                            <tr>
                                <th>
                                    <input
                                        type="checkbox"
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setSelectedPayments(new Set(payments.map(c => c.payment_id)));
                                            } else {
                                                setSelectedPayments(new Set());
                                            }
                                        }}
                                        checked={selectedPayments.size === payments.length}
                                    />
                                </th>
                                <th>{t("payments_table.payment_id_header")}</th>
                                <th onClick={() => handleSort(PaymentSortBy.UNIT_NUMBER)} style={{ cursor: 'pointer' }}>
                                    {t("payments_table.unit_header")}
                                    {selectedSortColumn === PaymentSortBy.UNIT_NUMBER ? (
                                        selectedSortDirection === SortOrder.ASC ? <FaSortUp /> : <FaSortDown />
                                    ) : <FaSort />}
                                </th>
                                <th>{t("payments_table.purpose_header")}</th>
                                <th onClick={() => handleSort(PaymentSortBy.AMOUNT)} style={{ cursor: 'pointer' }}>
                                    {t("payments_table.amount_header")}
                                    {selectedSortColumn === PaymentSortBy.AMOUNT ? (
                                        selectedSortDirection === SortOrder.ASC ? <FaSortUp /> : <FaSortDown />
                                    ) : <FaSort />}
                                </th>
                                <th>{t("payments_table.status_header")}</th>
                                <th onClick={() => handleSort(PaymentSortBy.CREATED_AT)} style={{ cursor: 'pointer' }}>
                                    {t("payments_table.transaction_date_header")}
                                    {selectedSortColumn === PaymentSortBy.CREATED_AT ? (
                                        selectedSortDirection === SortOrder.ASC ? <FaSortUp /> : <FaSortDown />
                                    ) : <FaSort />}
                                </th>
                                <th>{t("payments_table.actions_header")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {payments.map(payment => (
                                <tr key={payment.payment_id} className="active">
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedPayments.has(payment.payment_id)}
                                            onChange={() => handleCheckboxChange(payment.payment_id)}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    </td>
                                    <td>
                                        {payment.payment_id}
                                    </td>
                                    <td>
                                        <a href={`/units/${payment.unit_account.unit.unit_id}`}>
                                            {payment.unit_account.unit.unit_number}
                                        </a>
                                    </td>
                                    <td>{formatPaymentPurpose(payment.purpose, t)}</td>
                                    <td>{formatMoney(payment.amount)}</td>
                                    <td>{formatPaymentStatus(payment.status, t)}</td>
                                    <td>{getFormattedDate(payment.created_at)}</td>
                                    <td>
                                        {payment.documents.length > 0 && (
                                            <IoReceipt onClick={() => onDownloadFile(payment.documents[0])} />
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p className="text-center my-0">{t("pages.payments.empty_message")}</p>
            )}
        </div>
    );
};

export default PaymentsTable;
