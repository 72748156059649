import useSWRInfinite from 'swr/infinite';
import { constructPaymentsUrl, fetchPayments, IPayment, PaymentSortBy } from '../services/paymentService';
import { SortOrder } from '../@types';

const PAGE_SIZE = 20;

export const usePayments = (
    propertyId?: number,
    unitId?: number,
    unitAccountId?: number,
    search?: string,
    amount?: string,
    purpose?: string,
    method?: string,
    status?: string,
    sortBy?: PaymentSortBy,
    sortOrder?: SortOrder
) => {
    const fetcher = (url: string) => fetchPayments(url);

    const getKey = (pageIndex: number, previousPageData: { payments: IPayment[] } | null) => {
        if (previousPageData && !previousPageData.payments.length) return null; // reached the end
        return constructPaymentsUrl(
            propertyId,
            unitId,
            unitAccountId,
            search,
            pageIndex + 1,
            PAGE_SIZE,
            amount,
            purpose,
            method,
            status,
            sortBy,
            sortOrder
        );
    };

    const { data, error, size, setSize, mutate } = useSWRInfinite(getKey, fetcher);

    const isLoadingInitialData = !data && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === "undefined");

    // Combine payments across all pages
    const payments = data ? ([] as IPayment[]).concat(...data.map(d => d.payments)) : [];

    // Get the total count and current page count from the last page of data
    const totalCount = data?.[0]?.totalCount ?? 0;
    const currentPageCount = data?.[size - 1]?.currentPageCount ?? 0;

    return {
        payments,
        totalCount,
        currentPageCount,
        isLoading: isLoadingInitialData,
        isLoadingMore,
        size,
        setSize,
        mutate,
        isReachingEnd: data && data[data.length - 1]?.payments.length < PAGE_SIZE,
        error,
    };
};
