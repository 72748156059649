import './file-card.css';
import React, { useCallback, useState } from 'react';
import { Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoDocumentTextOutline, IoTrashOutline, IoShieldOutline } from 'react-icons/io5';
import { DocumentType, downloadDocument, IDocument } from '../../services/documentService';
import useAlertStore from '../../stores/alertStore';
import { useTranslation } from 'react-i18next';
import ViewDocumentModal from '../../modals/view-document-modal/view-document-modal';
import EditDocumentModal from '../../modals/edit-document-modal/edit-document-modal';

type FileCardProps = {
    className?: string;
    index: number;
    fileName: string;
    fileSize?: string;
    filePath?: string;
    progress?: number | null;
    uploaded: boolean;
    validUntil?: string | null;
    internalDocument?: boolean;
    doc?: IDocument | null;
    onClickRemove: () => void;
    onDocumentUpdated?: (document: IDocument) => void;
};

const FileCard: React.FC<FileCardProps> = ({
    className,
    index,
    fileName,
    fileSize,
    filePath,
    progress,
    uploaded,
    validUntil,
    internalDocument = false,
    doc,
    onClickRemove,
    onDocumentUpdated
}) => {
    const progressBarColor = uploaded ? 'file-status-success' : 'file-status-progress';

    const { t } = useTranslation();

    // Store
    const showAlert = useAlertStore(state => state.showAlert);

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isViewDocumentModalVisible, setIsViewDocumentModalVisible] = useState<number | null>(null);
    const [isEditDocumentModalVisible, setIsEditDocumentModalVisible] = useState<IDocument | null>(null);

    const onDownloadFile = useCallback(async () => {
        if (doc) {
            try {
                setIsLoading(true);
                const blob: Blob | null = await downloadDocument(doc.document_id);
                if (blob) {
                    const blobUrl = window.URL.createObjectURL(blob);

                    const anchor = document.createElement('a');
                    anchor.href = blobUrl;
                    anchor.download = fileName;
                    document.body.appendChild(anchor);
                    anchor.click();

                    window.URL.revokeObjectURL(blobUrl);
                    document.body.removeChild(anchor);
                }
            } catch (error) {
                console.log("error while downloading file: ", error);
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsLoading(false);
            }
        }
    }, [doc]);

    const getValidityStatus = (validUntilDate: string) => {
        const currentDate = new Date();
        const validUntil = new Date(validUntilDate);
        const differenceInDays = (validUntil.getTime() - currentDate.getTime()) / (1000 * 3600 * 24);

        if (differenceInDays < 0) {
            return "expired";
        } else if (differenceInDays <= 30) {
            return "expiring-soon";
        } else {
            return "valid";
        }
    };

    const validityStatus = validUntil ? getValidityStatus(validUntil) : "valid";
    const formattedDate = validUntil ? new Date(validUntil).toLocaleDateString() : null;

    return (
        <>
            <div key={index} className={`file-status mb-2 p-2 d-flex align-items-center ${className}`}>
                <IoDocumentTextOutline className="me-2" size={24} />
                <div className="flex-grow-1 me-2">
                    <div className="d-flex justify-content-between align-items-center">
                        <span className="file-status-name">{fileName}</span>
                        <IoTrashOutline className="file-remove-icon" size={20} onClick={onClickRemove} />
                    </div>
                    {progress && (
                        <div className="d-flex align-items-center mb-2">
                            <div className="progress mt-1 flex-grow-1">
                                <div className={`progress-bar ${progressBarColor}`} role="progressbar" style={{ width: `${progress}%` }} />
                            </div>
                            <div className="ms-2 file-status-indicator">{progress}%</div>
                        </div>
                    )}
                    <div className="d-flex align-items-center">
                        <div className="d-flex flex-grow-1 justify-content-between align-items-center">
                            {fileSize && (
                                <small className="file-status-size">{fileSize}</small>
                            )}
                            {filePath && doc && !isLoading && (
                                <div className="d-flex" style={{ gap: 8 }}>
                                    <small className="file-status-download" onClick={onDownloadFile}>{t("buttons.download")}</small>
                                    <small className="file-status-download" onClick={() => setIsViewDocumentModalVisible(doc.document_id)}>{t("buttons.view")}</small>
                                    {doc.document_type === DocumentType.EMIRATES_ID || doc.document_type === DocumentType.PASSPORT && (
                                        <small className="file-status-download" onClick={() => setIsEditDocumentModalVisible(doc)}>{t("buttons.edit")}</small>
                                    )}
                                </div>
                            )}
                        </div>
                        {validUntil && (
                            <div className={`valid-until-badge ${validityStatus} ms-auto`}>
                                <span className="badge-status-text">
                                    {validityStatus === "valid" && t("labels.valid")}
                                    {validityStatus === "expiring-soon" && t("labels.expiring_soon")}
                                    {validityStatus === "expired" && t("labels.expired")}
                                </span>
                                <span className="badge-date">{formattedDate}</span>
                            </div>
                        )}
                        {internalDocument && (
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id={`tooltip-top`}>{t("labels.internal_document_tooltip")}</Tooltip>}
                            >
                                <div className="internal-document-badge ms-auto">
                                    <IoShieldOutline className="internal-document-icon" />
                                    <span className="internal-document-text">{t("labels.internal_document")}</span>
                                </div>
                            </OverlayTrigger>
                        )}
                    </div>
                    {isLoading && (
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    )}
                </div>
            </div >

            {/* View Document Modal */}
            {isViewDocumentModalVisible && (
                <ViewDocumentModal
                    document_id={isViewDocumentModalVisible}
                    onClose={() => setIsViewDocumentModalVisible(null)}
                />
            )}

            {/* Edit Document Modal */}
            {isEditDocumentModalVisible && onDocumentUpdated && (
                <EditDocumentModal
                    document={isEditDocumentModalVisible}
                    onClose={() => setIsEditDocumentModalVisible(null)}
                    onUpdateDocument={(document: IDocument) => {
                        onDocumentUpdated(document);
                        setIsEditDocumentModalVisible(null);
                    }}
                />
            )}
        </>
    );
};

export default FileCard;
