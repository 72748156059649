import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useEmployeeStore from '../../../../stores/employeeStore';
import { EmployeeRole } from '../../../../services/employeeService';
import { uploadPropertyImage } from '../../../../services/propertyService';
import useAlertStore from '../../../../stores/alertStore';
import { IoConstructOutline, IoLocationOutline, IoLinkOutline, IoCalendarOutline, IoHome } from 'react-icons/io5';
import { MdFileUpload, MdEmail, MdPhone, MdNumbers, MdAttachMoney, MdSquareFoot } from 'react-icons/md';
import { FaRegBuilding, FaRegIdCard } from 'react-icons/fa';
import useEditPermission from '../../../../hooks/useEditPermission';
import { formatMoney, getFormattedDate } from '../../../../utils/helpers';
import {
    PropertyDetailsCard,
    ImageContainer,
    Image,
    Badge,
    PropertyInfo,
    PropertyName,
    PropertyPlotNo,
    Divider,
    Stats,
    StatItem,
    CustomProgress,
    ProgressBar,
    PropertyLeasedProgress,
    UnderConstructionOverlay,
    UploadOverlay,
    UploadInput,
    InfoSection,
    InfoList,
    InfoRow,
    IconCol,
    LabelCol,
    ValueCol,
    StatusIndicator,
    DetailLink,
    TabsContainer,
    Tab,
    TabContent,
} from './property-details.styles';

interface IPropertyDetails {
    property_id: number;
    image: string;
    name: string;
    email: string | null;
    phone_number: string | null;
    plot_no: number;
    type: string;
    tenants: number;
    residents: number;
    units: number;
    vacant: number;
    upcoming: number;
    leasePercentage: number;
    under_construction: boolean;
    property_location?: string | undefined;
    property_map_link?: string | undefined;
    makani_no?: string | undefined;
    plot_purchased_price?: number;
    plot_size?: number;
    total_build_up_area_size?: number;
    registration_number?: string;
    handover?: boolean;
    published?: boolean;
    planned_completion?: string | undefined;
}

interface Props {
    property: IPropertyDetails;
}

type TabType = 'details' | 'location' | 'status' | 'plot';

const PropertyDetails: React.FC<Props> = ({ property }) => {
    const { t } = useTranslation();
    const fileInputRef = useRef<HTMLInputElement>(null);

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const employeeRoleId = useEmployeeStore(state => state.employee?.role_id);

    const hasEditPermission = useEditPermission(employeeRoleId, [EmployeeRole.ADMINISTRATOR]);

    // States
    const [isUploadingImage, setIsUploadingImage] = useState<boolean>(false);
    const [propertyDetails, setPropertyDetails] = useState<IPropertyDetails>(property);
    const [activeTab, setActiveTab] = useState<TabType>('details');

    const leasePercentage = useMemo(() => propertyDetails.leasePercentage.toFixed(0), [propertyDetails]);

    const handleImageUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!files || files.length === 0) return;

        const newImage = files[0];
        if (newImage.type !== 'image/jpeg' && newImage.type !== 'image/png' && newImage.type !== 'image/jpg' && newImage.type !== 'image/webp') {
            showAlert("error", t("errors.invalid_image_format", { defaultValue: "Invalid image format. Please upload JPEG, PNG, or WebP image." }));
            return;
        }

        try {
            setIsUploadingImage(true);
            const updatedProperty = await uploadPropertyImage(propertyDetails.property_id, newImage);

            // Update the image in the local state
            setPropertyDetails(prev => ({
                ...prev,
                image: updatedProperty.property_image
            }));

            showAlert("success", t("pages.property_detail.image_upload_success", { defaultValue: "Property image updated successfully" }));
        } catch (error) {
            console.error('Error while uploading property image:', error);
            // @ts-ignore
            showAlert("error", error.message || t("errors.unknown_error", { defaultValue: "Unknown error occurred" }));
        } finally {
            setIsUploadingImage(false);
            // Clear the file input
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
        }
    };

    return (
        <PropertyDetailsCard underConstruction={propertyDetails.under_construction}>
            <ImageContainer>
                <Image src={propertyDetails.image} alt={propertyDetails.name} draggable={false} />
                <UnderConstructionOverlay underConstruction={propertyDetails.under_construction}>
                    <IoConstructOutline className="construction-icon" />
                </UnderConstructionOverlay>

                {hasEditPermission && (
                    <>
                        <UploadOverlay onClick={handleImageUploadClick}>
                            <div className="d-flex flex-column align-items-center">
                                <MdFileUpload className="upload-icon" />
                                <div className="upload-text">
                                    {isUploadingImage
                                        ? t("pages.property_detail.uploading_image", { defaultValue: "Uploading..." })
                                        : t("pages.property_detail.upload_new_image", { defaultValue: "Upload New Image" })
                                    }
                                </div>
                            </div>
                        </UploadOverlay>
                        <UploadInput
                            type="file"
                            accept="image/jpeg,image/png,image/jpg,image/webp"
                            ref={fileInputRef}
                            onChange={handleImageChange}
                            disabled={isUploadingImage}
                        />
                    </>
                )}

                <Badge>{propertyDetails.type}</Badge>
            </ImageContainer>
            <PropertyInfo>
                <PropertyName>{propertyDetails.name}</PropertyName>
                <PropertyPlotNo>Plot-No: {propertyDetails.plot_no}</PropertyPlotNo>
                <Divider />
                <Stats>
                    <StatItem>{propertyDetails.residents} <span>{t("pages.property_detail.residents_label")}</span></StatItem>
                    <StatItem>{propertyDetails.units} <span>{t("pages.property_detail.units_label")}</span></StatItem>
                    <StatItem>{propertyDetails.vacant} <span>{t("pages.property_detail.vacant_label")}</span></StatItem>
                    <StatItem>{propertyDetails.upcoming} <span>{t("pages.property_detail.upcoming_label")}</span></StatItem>
                </Stats>
                <CustomProgress>
                    <ProgressBar leasePercentage={parseFloat(leasePercentage)} />
                </CustomProgress>
                <PropertyLeasedProgress>
                    {t("pages.property_detail.leased_by", { percentage: leasePercentage })}
                </PropertyLeasedProgress>

                {/* Tabs für Details, Standort und Status */}
                <TabsContainer>
                    <Tab
                        active={activeTab === 'details'}
                        onClick={() => setActiveTab('details')}
                    >
                        {String(t("pages.property_detail.property_details", { defaultValue: "Kontakt" }))}
                    </Tab>
                    <Tab
                        active={activeTab === 'location'}
                        onClick={() => setActiveTab('location')}
                    >
                        {String(t("pages.property_detail.location_details", { defaultValue: "Standort" }))}
                    </Tab>
                    <Tab
                        active={activeTab === 'plot'}
                        onClick={() => setActiveTab('plot')}
                    >
                        {String(t("pages.property_detail.plot_details", { defaultValue: "Plot Informationen" }))}
                    </Tab>
                    <Tab
                        active={activeTab === 'status'}
                        onClick={() => setActiveTab('status')}
                    >
                        {String(t("pages.property_detail.property_status", { defaultValue: "Status" }))}
                    </Tab>
                </TabsContainer>

                <TabContent>
                    {/* Contact Tab (ehemals Property Details Tab) */}
                    {activeTab === 'details' && (
                        <InfoSection>
                            <InfoList>
                                {/* Kontaktdaten */}
                                <InfoRow>
                                    <IconCol>
                                        <MdEmail />
                                    </IconCol>
                                    <LabelCol>
                                        {String(t("labels.email", { defaultValue: "Email" }))}
                                    </LabelCol>
                                    <ValueCol className={!propertyDetails.email ? "text-muted" : ""}>
                                        {propertyDetails.email || String(t("labels.not_available", { defaultValue: "Not available" }))}
                                    </ValueCol>
                                </InfoRow>

                                <InfoRow>
                                    <IconCol>
                                        <MdPhone />
                                    </IconCol>
                                    <LabelCol>
                                        {String(t("labels.phone_number", { defaultValue: "Phone Number" }))}
                                    </LabelCol>
                                    <ValueCol className={!propertyDetails.phone_number ? "text-muted" : ""}>
                                        {propertyDetails.phone_number || String(t("labels.not_available", { defaultValue: "Not available" }))}
                                    </ValueCol>
                                </InfoRow>
                            </InfoList>
                        </InfoSection>
                    )}

                    {/* Location Tab */}
                    {activeTab === 'location' && (
                        <InfoSection>
                            <InfoList>
                                <InfoRow>
                                    <IconCol>
                                        <IoLocationOutline />
                                    </IconCol>
                                    <LabelCol>
                                        {String(t("labels.property_location", { defaultValue: "Location" }))}
                                    </LabelCol>
                                    <ValueCol className={!propertyDetails.property_location ? "text-muted" : ""}>
                                        {propertyDetails.property_location || String(t("labels.not_available", { defaultValue: "Not available" }))}
                                    </ValueCol>
                                </InfoRow>

                                <InfoRow>
                                    <IconCol>
                                        <IoLinkOutline />
                                    </IconCol>
                                    <LabelCol>
                                        {String(t("labels.property_map_link", { defaultValue: "Map Link" }))}
                                    </LabelCol>
                                    <ValueCol>
                                        {propertyDetails.property_map_link ?
                                            <DetailLink href={propertyDetails.property_map_link} target="_blank" rel="noopener noreferrer">
                                                {String(t("actions.view_on_map", { defaultValue: "View on Map" }))}
                                            </DetailLink> :
                                            <span className="text-muted">{String(t("labels.not_available", { defaultValue: "Not available" }))}</span>
                                        }
                                    </ValueCol>
                                </InfoRow>

                                <InfoRow>
                                    <IconCol>
                                        <MdNumbers />
                                    </IconCol>
                                    <LabelCol>
                                        {String(t("labels.makani_no", { defaultValue: "Makani No." }))}
                                    </LabelCol>
                                    <ValueCol className={!propertyDetails.makani_no ? "text-muted" : ""}>
                                        {propertyDetails.makani_no || String(t("labels.not_available", { defaultValue: "Not available" }))}
                                    </ValueCol>
                                </InfoRow>
                            </InfoList>
                        </InfoSection>
                    )}

                    {/* Plot Informationen Tab */}
                    {activeTab === 'plot' && (
                        <InfoSection>
                            <InfoList>
                                <InfoRow>
                                    <IconCol>
                                        <MdNumbers />
                                    </IconCol>
                                    <LabelCol>
                                        {String(t("labels.plot_no", { defaultValue: "Plot No." }))}
                                    </LabelCol>
                                    <ValueCol>
                                        {propertyDetails.plot_no}
                                    </ValueCol>
                                </InfoRow>

                                <InfoRow>
                                    <IconCol>
                                        <MdNumbers />
                                    </IconCol>
                                    <LabelCol>
                                        {String(t("labels.makani_no", { defaultValue: "Makani No." }))}
                                    </LabelCol>
                                    <ValueCol className={!propertyDetails.makani_no ? "text-muted" : ""}>
                                        {propertyDetails.makani_no || "N/A"}
                                    </ValueCol>
                                </InfoRow>

                                <InfoRow>
                                    <IconCol>
                                        <MdSquareFoot />
                                    </IconCol>
                                    <LabelCol>
                                        {String(t("labels.plot_size", { defaultValue: "Plot Size (sqm)" }))}
                                    </LabelCol>
                                    <ValueCol className={!propertyDetails.plot_size ? "text-muted" : ""}>
                                        {propertyDetails.plot_size ? `${propertyDetails.plot_size} m²` : "N/A"}
                                    </ValueCol>
                                </InfoRow>

                                <InfoRow>
                                    <IconCol>
                                        <MdSquareFoot />
                                    </IconCol>
                                    <LabelCol>
                                        {String(t("labels.total_build_up_area_size", { defaultValue: "Build Up Area (sqm)" }))}
                                    </LabelCol>
                                    <ValueCol className={!propertyDetails.total_build_up_area_size ? "text-muted" : ""}>
                                        {propertyDetails.total_build_up_area_size ? `${propertyDetails.total_build_up_area_size} m²` : "N/A"}
                                    </ValueCol>
                                </InfoRow>

                                <InfoRow>
                                    <IconCol>
                                        <MdAttachMoney />
                                    </IconCol>
                                    <LabelCol>
                                        {String(t("labels.plot_purchased_price", { defaultValue: "Purchase Price (AED)" }))}
                                    </LabelCol>
                                    <ValueCol className={!propertyDetails.plot_purchased_price ? "text-muted" : ""}>
                                        {propertyDetails.plot_purchased_price ? formatMoney(propertyDetails.plot_purchased_price) : "N/A"}
                                    </ValueCol>
                                </InfoRow>

                                <InfoRow>
                                    <IconCol>
                                        <FaRegIdCard />
                                    </IconCol>
                                    <LabelCol>
                                        {String(t("labels.registration_number", { defaultValue: "Registration No." }))}
                                    </LabelCol>
                                    <ValueCol className={!propertyDetails.registration_number ? "text-muted" : ""}>
                                        {propertyDetails.registration_number || "N/A"}
                                    </ValueCol>
                                </InfoRow>

                                <InfoRow>
                                    <IconCol>
                                        <IoCalendarOutline />
                                    </IconCol>
                                    <LabelCol style={{ minWidth: '180px' }}>
                                        {String(t("labels.planned_completion", { defaultValue: "Planned Completion" }))}
                                    </LabelCol>
                                    <ValueCol className={!propertyDetails.planned_completion ? "text-muted" : ""}>
                                        {propertyDetails.planned_completion ? getFormattedDate(propertyDetails.planned_completion) : "N/A"}
                                    </ValueCol>
                                </InfoRow>
                            </InfoList>
                        </InfoSection>
                    )}

                    {/* Status Tab */}
                    {activeTab === 'status' && (
                        <InfoSection>
                            <InfoList>
                                <InfoRow>
                                    <IconCol>
                                        <IoConstructOutline />
                                    </IconCol>
                                    <LabelCol>
                                        {String(t("labels.under_construction", { defaultValue: "Under Construction" }))}
                                    </LabelCol>
                                    <ValueCol>
                                        <StatusIndicator status={propertyDetails.under_construction ? 'active' : 'inactive'}>
                                            {String(propertyDetails.under_construction
                                                ? t("labels.yes", { defaultValue: "Yes" })
                                                : t("labels.no", { defaultValue: "No" }))}
                                        </StatusIndicator>
                                    </ValueCol>
                                </InfoRow>

                                <InfoRow>
                                    <IconCol>
                                        <IoHome />
                                    </IconCol>
                                    <LabelCol>
                                        {String(t("labels.handover", { defaultValue: "Handover" }))}
                                    </LabelCol>
                                    <ValueCol>
                                        <StatusIndicator status={propertyDetails.handover ? 'active' : 'inactive'}>
                                            {String(propertyDetails.handover
                                                ? t("labels.completed", { defaultValue: "Completed" })
                                                : t("labels.pending", { defaultValue: "Pending" }))}
                                        </StatusIndicator>
                                    </ValueCol>
                                </InfoRow>

                                <InfoRow>
                                    <IconCol>
                                        <FaRegBuilding />
                                    </IconCol>
                                    <LabelCol>
                                        {String(t("labels.published", { defaultValue: "Published" }))}
                                    </LabelCol>
                                    <ValueCol>
                                        <StatusIndicator status={propertyDetails.published ? 'active' : 'inactive'}>
                                            {String(propertyDetails.published
                                                ? t("labels.yes", { defaultValue: "Yes" })
                                                : t("labels.no", { defaultValue: "No" }))}
                                        </StatusIndicator>
                                    </ValueCol>
                                </InfoRow>
                            </InfoList>
                        </InfoSection>
                    )}
                </TabContent>
            </PropertyInfo>
        </PropertyDetailsCard>
    );
};

export default PropertyDetails;
