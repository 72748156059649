import '../sidemodal.css';
import './property-sidemodal.css';

import React, { useState, useCallback, useEffect } from 'react';
import { IoClose } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { UploadedFile } from '../../../components/file-upload/file-upload';
import SidebarInputField from '../components/sidebar-input-field/sidebar-input-field';
import CustomButton from '../../../components/custom-button/custom-button';
import ExpandableSection from '../../../components/expandable-section/expandable-section';
import FileUploadSection from '../../../components/file-upload-section/file-upload-section';
import useAlertStore from '../../../stores/alertStore';
import useEmployeeStore from '../../../stores/employeeStore';
import { EmployeeRole } from '../../../services/employeeService';
import { 
    createProperty, 
    updateProperty, 
    uploadPropertyImage, 
    ICreatePropertyPayload, 
    IProperty 
} from '../../../services/propertyService';
import { SPACES_ENDPOINT } from '../../../utils/constants';

interface PropertySideModalProps {
    isVisible: boolean;
    onClose: () => void;
    mutate: (data?: any, shouldRevalidate?: boolean) => Promise<any>;
    editMode?: boolean;
    existingProperty?: IProperty;
}

const PropertySideModal: React.FC<PropertySideModalProps> = ({ 
    isVisible, 
    onClose, 
    mutate, 
    editMode = false,
    existingProperty
}) => {
    const { t } = useTranslation();
    const showAlert = useAlertStore(state => state.showAlert);
    const { employee } = useEmployeeStore();
    const hasEditPermission = employee?.role_id === EmployeeRole.ADMINISTRATOR || employee?.role_id === EmployeeRole.PROPERTY_MANAGER;

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [propertyName, setPropertyName] = useState<string>("");
    const [propertyImage, setPropertyImage] = useState<File | null>(null);
    const [propertyMapLink, setPropertyMapLink] = useState<string>("");
    const [propertyLocation, setPropertyLocation] = useState<string>("");
    const [plotNo, setPlotNo] = useState<string>("1");
    const [makaniNo, setMakaniNo] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [published, setPublished] = useState<boolean>(false);
    const [plotPurchasedPrice, setPlotPurchasedPrice] = useState<string>("0");
    const [plotSize, setPlotSize] = useState<string>("1");
    const [totalBuildUpAreaSize, setTotalBuildUpAreaSize] = useState<string>("1");
    const [registrationNumber, setRegistrationNumber] = useState<string>("");
    const [underConstruction, setUnderConstruction] = useState<boolean>(true);
    const [handover, setHandover] = useState<boolean>(false);
    const [plannedCompletion, setPlannedCompletion] = useState<string>("");

    // Validation errors
    const [propertyNameError, setPropertyNameError] = useState<string>("");
    const [propertyImageError, setPropertyImageError] = useState<string>("");
    const [plotNoError, setPlotNoError] = useState<string>("");
    const [plotPurchasedPriceError, setPlotPurchasedPriceError] = useState<string>("");
    const [plotSizeError, setPlotSizeError] = useState<string>("");
    const [totalBuildUpAreaSizeError, setTotalBuildUpAreaSizeError] = useState<string>("");
    const [registrationNumberError, setRegistrationNumberError] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [error, setError] = useState<string>("");

    // Populate form with existing property data if in edit mode
    useEffect(() => {
        if (isVisible && editMode && existingProperty) {
            setPropertyName(existingProperty.property_name || "");
            setPropertyMapLink(existingProperty.property_map_link || "");
            setPropertyLocation(existingProperty.property_location || "");
            setPlotNo(existingProperty.plot_no?.toString() || "1");
            setMakaniNo(existingProperty.makani_no || "");
            setEmail(existingProperty.email || "");
            setPhoneNumber(existingProperty.phone_number || "");
            setPublished(existingProperty.published || false);
            setPlotPurchasedPrice(existingProperty.plot_purchased_price?.toString() || "0");
            setPlotSize(existingProperty.plot_size?.toString() || "1");
            setTotalBuildUpAreaSize(existingProperty.total_build_up_area_size?.toString() || "1");
            setRegistrationNumber(existingProperty.registration_number || "");
            setUnderConstruction(existingProperty.under_construction || false);
            setHandover(existingProperty.handover || false);
            setPlannedCompletion(existingProperty.planned_completion || "");
        }
    }, [isVisible, editMode, existingProperty]);

    // Reset states when modal closes
    useEffect(() => {
        if (!isVisible) {
            if (!editMode) {
                setPropertyName("");
                setPropertyImage(null);
                setPropertyMapLink("");
                setPropertyLocation("");
                setPlotNo("1");
                setMakaniNo("");
                setEmail("");
                setPhoneNumber("");
                setPublished(false);
                setPlotPurchasedPrice("0");
                setPlotSize("1");
                setTotalBuildUpAreaSize("1");
                setRegistrationNumber("");
                setUnderConstruction(true);
                setHandover(false);
                setPlannedCompletion("");
            }

            // Clear errors
            setPropertyNameError("");
            setPropertyImageError("");
            setPlotNoError("");
            setPlotPurchasedPriceError("");
            setPlotSizeError("");
            setTotalBuildUpAreaSizeError("");
            setRegistrationNumberError("");
            setEmailError("");
            setError("");
        }
    }, [isVisible, editMode]);

    const onFileSelected = (selectedFiles: UploadedFile[]) => {
        if (selectedFiles.length > 0) {
            setPropertyImage(selectedFiles[0].file);
            setPropertyImageError("");
        }
    };

    console.log = console.log.bind(console);
    console.error = console.error.bind(console);
    
    const validateForm = (): boolean => {
        let isValid = true;

        // Validate required fields
        if (!propertyName.trim()) {
            setPropertyNameError(t("invalid_input.property_name_empty", { defaultValue: "Property name must not be empty." }));
            isValid = false;
            console.log("Validation failed: Property name is empty");
        }

        if (!editMode && !propertyImage) {
            setPropertyImageError(t("invalid_input.property_image_required", { defaultValue: "Property image is required." }));
            isValid = false;
            console.log("Validation failed: Property image is not provided");
        }

        if (plotNo.trim() === "" || isNaN(Number(plotNo)) || Number(plotNo) <= 0) {
            setPlotNoError(t("invalid_input.plot_no_invalid", { defaultValue: "Plot number must be a positive number." }));
            isValid = false;
            console.log("Validation failed: Plot number is invalid");
        }

        if (plotPurchasedPrice.trim() === "" || isNaN(Number(plotPurchasedPrice)) || Number(plotPurchasedPrice) < 0) {
            setPlotPurchasedPriceError(t("invalid_input.plot_purchased_price_invalid", { defaultValue: "Purchase price must be a valid number." }));
            isValid = false;
            console.log("Validation failed: Plot purchased price is invalid");
        }

        if (plotSize.trim() === "" || isNaN(Number(plotSize)) || Number(plotSize) <= 0) {
            setPlotSizeError(t("invalid_input.plot_size_invalid", { defaultValue: "Plot size must be a positive number." }));
            isValid = false;
            console.log("Validation failed: Plot size is invalid");
        }

        if (totalBuildUpAreaSize.trim() === "" || isNaN(Number(totalBuildUpAreaSize)) || Number(totalBuildUpAreaSize) <= 0) {
            setTotalBuildUpAreaSizeError(t("invalid_input.total_build_up_area_size_invalid", { defaultValue: "Build up area size must be a positive number." }));
            isValid = false;
            console.log("Validation failed: Build up area size is invalid");
        }

        if (!registrationNumber.trim()) {
            setRegistrationNumberError(t("invalid_input.registration_number_empty", { defaultValue: "Registration number must not be empty." }));
            isValid = false;
            console.log("Validation failed: Registration number is empty");
        }

        if (email.trim() && !/^\S+@\S+\.\S+$/.test(email.trim())) {
            setEmailError(t("invalid_input.email_invalid"));
            isValid = false;
            console.log("Validation failed: Email is invalid");
        }

        console.log("Form validation result:", isValid);
        return isValid;
    };
    
    const handleCreateProperty = useCallback(async () => {
        console.log(`${editMode ? "handleUpdateProperty" : "handleCreateProperty"} function called`);
        
        if (!validateForm()) return;

        try {
            setError("");
            setIsLoading(true);

            // Erstelle das Payload für die API-Anfrage
            const propertyPayload: ICreatePropertyPayload = {
                property_name: propertyName.trim(),
                property_location: propertyLocation.trim() || undefined,
                property_map_link: propertyMapLink.trim() || undefined,
                email: email.trim() || undefined,
                phone_number: phoneNumber.trim() || undefined,
                plot_no: Number(plotNo),
                makani_no: makaniNo.trim() || undefined,
                plot_purchased_price: Number(plotPurchasedPrice),
                plot_size: Number(plotSize),
                total_build_up_area_size: Number(totalBuildUpAreaSize),
                registration_number: registrationNumber.trim(),
                under_construction: underConstruction,
                handover: handover,
                published: published,
                planned_completion: underConstruction && plannedCompletion ? plannedCompletion : undefined,
            };

            let responseProperty: IProperty;

            if (editMode && existingProperty) {
                // Update existing property
                console.log("Updating property with ID:", existingProperty.property_id);
                responseProperty = await updateProperty(existingProperty.property_id, propertyPayload);
                console.log("Property updated successfully:", responseProperty);

                // Upload new image if provided
                if (propertyImage) {
                    console.log("Uploading new property image for property ID:", existingProperty.property_id);
                    try {
                        responseProperty = await uploadPropertyImage(existingProperty.property_id, propertyImage);
                        console.log("Property image uploaded successfully");
                    } catch (error: unknown) {
                        const imageError = error as Error;
                        console.error("Error uploading property image:", imageError);
                        setError(`Property updated but image upload failed: ${imageError?.message || 'Unknown error'}`);
                    }
                }

                // Update the property in the list
                mutate();
            } else {
                // Create new property
                console.log("Creating property with data:", propertyName, propertyLocation, propertyMapLink);
                responseProperty = await createProperty(propertyPayload);
                console.log("Property created successfully:", responseProperty);

                // Upload image for new property
                if (propertyImage) {
                    console.log("Uploading property image for property ID:", responseProperty.property_id);
                    try {
                        responseProperty = await uploadPropertyImage(responseProperty.property_id, propertyImage);
                        console.log("Property image uploaded successfully");
                    } catch (error: unknown) {
                        const imageError = error as Error;
                        console.error("Error uploading property image:", imageError);
                        setError(`Property created but image upload failed: ${imageError?.message || 'Unknown error'}`);
                    }
                }

                // Add the new property to the list
                mutate((data: any[]) => {
                    if (!data) return [];
                    return [...data, responseProperty];
                }, false);
            }

            onClose();
            showAlert(
                "success", 
                editMode 
                    ? t("modals.edit_property.edit_property_success_message", { defaultValue: "Property updated successfully!" })
                    : t("modals.create_property.create_property_success_message", { defaultValue: "Property created successfully!" })
            );
        } catch (error: any) {
            console.error(`Error in ${editMode ? "updateProperty" : "createProperty"}:`, error);
            setError(error.message || "Ein unbekannter Fehler ist aufgetreten");
        } finally {
            setIsLoading(false);
        }
    }, [
        editMode, existingProperty,
        propertyName, propertyImage, propertyMapLink, propertyLocation, plotNo,
        makaniNo, email, phoneNumber, published, plotPurchasedPrice, plotSize,
        totalBuildUpAreaSize, registrationNumber, underConstruction, handover,
        plannedCompletion, mutate, onClose, showAlert, t
    ]);

    return (
        <div className={`sidebar-modal-backdrop ${isVisible ? 'visible' : ''}`}>
            <div className={`sidebar-modal ${isVisible ? 'visible' : ''}`}>
                <div className="d-flex align-items-center justify-content-between my-3">
                    <h4 className="sidebar-modal-headline ms-3">
                        {editMode
                            ? t("modals.edit_property.title", { defaultValue: "Edit Property" })
                            : t("modals.create_property.title", { defaultValue: "Add New Property" })
                        }
                    </h4>
                    <button className="btn" onClick={onClose}>
                        <IoClose size={20} />
                    </button>
                </div>
                <div className="sidebar-modal-divider" />
                <div className="sidebar-modal-content">
                    <div className="sidebar-modal-scrollable-content">
                        {error && (
                            <div className="alert alert-danger" role="alert">
                                {error}
                            </div>
                        )}

                        {/* Basic Information Section */}
                        <ExpandableSection
                            title={t("modals.create_property.basic_info_section", { defaultValue: "Basic Information" })}
                            isDefaultExpanded
                        >
                            <div className="row">
                                <div className="col-12">
                                    <SidebarInputField
                                        id="propertyName"
                                        type="text"
                                        label={t("labels.property_name", { defaultValue: "Property Name" })}
                                        placeholder="Dubai Residence"
                                        value={propertyName}
                                        errorMessage={propertyNameError}
                                        onClear={() => setPropertyName("")}
                                        onChange={(value: string) => {
                                            setPropertyName(value);
                                            setPropertyNameError("");
                                        }}
                                        required
                                        labelOnTop
                                    />
                                </div>
                                <div className="col-12">
                                    <SidebarInputField
                                        id="propertyLocation"
                                        type="text"
                                        label={t("labels.property_location", { defaultValue: "Location" })}
                                        placeholder="Dubai Marina"
                                        value={propertyLocation}
                                        onClear={() => setPropertyLocation("")}
                                        onChange={(value: string) => setPropertyLocation(value)}
                                        labelOnTop
                                    />
                                </div>
                                <div className="col-12">
                                    <SidebarInputField
                                        id="propertyMapLink"
                                        type="text"
                                        label={t("labels.property_map_link", { defaultValue: "Map Link" })}
                                        placeholder="https://maps.google.com/..."
                                        value={propertyMapLink}
                                        onClear={() => setPropertyMapLink("")}
                                        onChange={(value: string) => setPropertyMapLink(value)}
                                        labelOnTop
                                    />
                                </div>
                            </div>
                        </ExpandableSection>

                        {/* Plot Information Section */}
                        <ExpandableSection
                            className="mt-4"
                            title={t("modals.create_property.plot_info_section", { defaultValue: "Plot Information" })}
                            isDefaultExpanded={true}
                        >
                            <div className="row">
                                <div className="col-12 col-xxl-6">
                                    <SidebarInputField
                                        id="plotNo"
                                        type="number"
                                        label={t("labels.plot_no", { defaultValue: "Plot No." })}
                                        placeholder="1"
                                        value={plotNo}
                                        errorMessage={plotNoError}
                                        onClear={() => setPlotNo("1")}
                                        onChange={(value: string) => {
                                            setPlotNo(value);
                                            setPlotNoError("");
                                        }}
                                        required
                                        labelOnTop
                                    />
                                </div>
                                <div className="col-12 col-xxl-6">
                                    <SidebarInputField
                                        id="makaniNo"
                                        type="text"
                                        label={t("labels.makani_no", { defaultValue: "Makani No." })}
                                        placeholder="123456"
                                        value={makaniNo}
                                        onClear={() => setMakaniNo("")}
                                        onChange={(value: string) => setMakaniNo(value)}
                                        labelOnTop
                                    />
                                </div>
                                <div className="col-12 col-xxl-6">
                                    <SidebarInputField
                                        id="plotSize"
                                        type="number"
                                        label={t("labels.plot_size", { defaultValue: "Plot Size (sqm)" })}
                                        placeholder="1000"
                                        value={plotSize}
                                        errorMessage={plotSizeError}
                                        onClear={() => setPlotSize("1")}
                                        onChange={(value: string) => {
                                            setPlotSize(value);
                                            setPlotSizeError("");
                                        }}
                                        required
                                        labelOnTop
                                    />
                                </div>
                                <div className="col-12 col-xxl-6">
                                    <SidebarInputField
                                        id="totalBuildUpAreaSize"
                                        type="number"
                                        label={t("labels.total_build_up_area_size", { defaultValue: "Build Up Area (sqm)" })}
                                        placeholder="800"
                                        value={totalBuildUpAreaSize}
                                        errorMessage={totalBuildUpAreaSizeError}
                                        onClear={() => setTotalBuildUpAreaSize("1")}
                                        onChange={(value: string) => {
                                            setTotalBuildUpAreaSize(value);
                                            setTotalBuildUpAreaSizeError("");
                                        }}
                                        required
                                        labelOnTop
                                    />
                                </div>
                                <div className="col-12 col-xxl-6">
                                    <SidebarInputField
                                        id="plotPurchasedPrice"
                                        type="number"
                                        label={t("labels.plot_purchased_price", { defaultValue: "Purchase Price (AED)" })}
                                        placeholder="1000000"
                                        value={plotPurchasedPrice}
                                        errorMessage={plotPurchasedPriceError}
                                        onClear={() => setPlotPurchasedPrice("0")}
                                        onChange={(value: string) => {
                                            setPlotPurchasedPrice(value);
                                            setPlotPurchasedPriceError("");
                                        }}
                                        required
                                        labelOnTop
                                    />
                                </div>
                                <div className="col-12 col-xxl-6">
                                    <SidebarInputField
                                        id="registrationNumber"
                                        type="text"
                                        label={t("labels.registration_number", { defaultValue: "Registration No." })}
                                        placeholder="REG12345"
                                        value={registrationNumber}
                                        errorMessage={registrationNumberError}
                                        onClear={() => setRegistrationNumber("")}
                                        onChange={(value: string) => {
                                            setRegistrationNumber(value);
                                            setRegistrationNumberError("");
                                        }}
                                        required
                                        labelOnTop
                                    />
                                </div>
                            </div>
                        </ExpandableSection>

                        {/* Contact Information Section */}
                        <ExpandableSection
                            className="mt-4"
                            title={t("modals.create_property.contact_info_section", { defaultValue: "Contact Information" })}
                            isDefaultExpanded={false}
                        >
                            <div className="row">
                                <div className="col-12 col-xxl-6">
                                    <SidebarInputField
                                        id="email"
                                        type="email"
                                        label={t("labels.email", { defaultValue: "Email" })}
                                        placeholder="property@example.com"
                                        value={email}
                                        errorMessage={emailError}
                                        onClear={() => setEmail("")}
                                        onChange={(value: string) => {
                                            setEmail(value);
                                            setEmailError("");
                                        }}
                                        labelOnTop
                                    />
                                </div>
                                <div className="col-12 col-xxl-6">
                                    <SidebarInputField
                                        id="phoneNumber"
                                        type="text"
                                        label={t("labels.phone_number", { defaultValue: "Phone Number" })}
                                        placeholder="+971 50 123 4567"
                                        value={phoneNumber}
                                        onClear={() => setPhoneNumber("")}
                                        onChange={(value: string) => setPhoneNumber(value)}
                                        labelOnTop
                                    />
                                </div>
                            </div>
                        </ExpandableSection>

                        {/* Status Information Section */}
                        <ExpandableSection
                            className="mt-4"
                            title={t("modals.create_property.status_info_section", { defaultValue: "Status Information" })}
                            isDefaultExpanded={false}
                        >
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <div className="checkbox-container">
                                        <input
                                            type="checkbox"
                                            id="underConstruction"
                                            checked={underConstruction}
                                            onChange={(e) => setUnderConstruction(e.target.checked)}
                                        />
                                        <label htmlFor="underConstruction" className="checkbox-label">
                                            {t("labels.under_construction", { defaultValue: "Under Construction" })}
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="checkbox-container">
                                        <input
                                            type="checkbox"
                                            id="handover"
                                            checked={handover}
                                            onChange={(e) => setHandover(e.target.checked)}
                                        />
                                        <label htmlFor="handover" className="checkbox-label">
                                            {t("labels.handover", { defaultValue: "Ready for Handover" })}
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="checkbox-container">
                                        <input
                                            type="checkbox"
                                            id="published"
                                            checked={published}
                                            onChange={(e) => setPublished(e.target.checked)}
                                        />
                                        <label htmlFor="published" className="checkbox-label">
                                            {t("labels.published", { defaultValue: "Published" })}
                                        </label>
                                    </div>
                                </div>
                                {underConstruction && (
                                    <div className="col-12">
                                        <SidebarInputField
                                            id="plannedCompletion"
                                            type="text"
                                            label={t("labels.planned_completion", { defaultValue: "Planned Completion Date" })}
                                            placeholder="YYYY-MM-DD"
                                            value={plannedCompletion}
                                            onClear={() => setPlannedCompletion("")}
                                            onChange={(value: string) => setPlannedCompletion(value)}
                                            labelOnTop
                                        />
                                        <small className="text-muted">
                                            {t("labels.date_format", { defaultValue: "Format: YYYY-MM-DD" })}
                                        </small>
                                    </div>
                                )}
                            </div>
                        </ExpandableSection>

                        {/* Property Image Section - Only show file upload if not in edit mode or if in edit mode and no image selected yet */}
                        {(!editMode || (editMode && !propertyImage)) && (
                            <>
                                <FileUploadSection
                                    fileUploadId="propertyImage"
                                    // @ts-ignore
                                    title={t(editMode ? "modals.edit_property.change_image" : "modals.create_property.upload_image", { defaultValue: editMode ? "Change Property Image" : "Upload Property Image" }) + (editMode ? "" : " *")}
                                    documents={[]}
                                    uploadedFiles={propertyImage ? [{ file: propertyImage, filename: propertyImage.name, filesize: `${(propertyImage.size / 1024).toFixed(2)} KB`, progress: 100, uploaded: true }] : []}
                                    onUpdateUploadedFiles={onFileSelected}
                                    maxFilesToUpload={1}
                                    allowedTypes="image/*"
                                    isDefaultExpanded={true}
                                    isHeadlineLarge={false}
                                    hasEditPermission={true}
                                    removePadding
                                />
                                {propertyImageError && <div className="text-danger mt-2">{propertyImageError}</div>}
                                {!editMode && !propertyImage && <div className="text-danger mt-2 mb-3">{t("invalid_input.required_field", { defaultValue: "This field is required" })}</div>}
                            </>
                        )}
                        
                        {/* Show current image in edit mode */}
                        {editMode && existingProperty?.property_image && (
                            <div className="mt-4 mb-3">
                                <h6>{t("modals.edit_property.current_image", { defaultValue: "Current Image" })}</h6>
                                <div style={{ maxWidth: '100%', overflow: 'hidden', borderRadius: '8px' }}>
                                    <img 
                                        src={`${SPACES_ENDPOINT}${existingProperty.property_image}`} 
                                        alt={existingProperty.property_name} 
                                        style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="store-investor-btn">
                        {error && (
                            <p className="text-danger">{error}</p>
                        )}
                        <CustomButton
                            className="w-100"
                            title={editMode 
                                ? t("buttons.update_property", { defaultValue: "Update Property" })
                                : t("buttons.create_property", { defaultValue: "Create Property" })
                            }
                            isLoading={isLoading}
                            onClick={() => {
                                console.log(`${editMode ? "Update" : "Create"} property button clicked`);
                                handleCreateProperty();
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PropertySideModal; 