// PaymentsPage.tsx

import { useEffect, useMemo } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import { getCurrentFormattedDateTime } from "../../utils/helpers";
import { useTranslation } from "react-i18next";
import Switch from "../../components/switch/switch";
import { useProperties } from "../../hooks/useProperties";
import useAlertStore from "../../stores/alertStore";
import Lottie from "lottie-react";
import LoadingAnim from "../../assets/anims/loading.-anim.json";
import usePageTitle from "../../hooks/usePageTitle";
import PMContractFilters from "./components/payment-filters/payment-filters";
import PaymentsTable from "./components/payments-table/payments-table";
import { SortOrder } from "../../@types";
import { usePayments } from "../../hooks/usePayments";
import usePaymentFilterStore from "../../stores/paymentFilterStore";
import { PaymentSortBy } from "../../services/paymentService";
import useDebounce from "../../hooks/useDebounce";
import useSearchStore from "../../stores/searchStore";

const PaymentsPage = () => {
    const { t } = useTranslation();

    // Set page title
    usePageTitle(t("pages.payments.page_title"));

    // Stores
    const search = useSearchStore(state => state.search);
    const showAlert = useAlertStore(state => state.showAlert);
    const {
        selectedProperty,
        selectedUnit,
        amount,
        purpose,
        method,
        status,
        sortColumn,
        sortDirection,
        setSort,
        setSelectedProperty,
    } = usePaymentFilterStore();

    // Hooks
    const debouncedKeyword = useDebounce(search, 500);
    const { properties } = useProperties();
    const {
        payments,
        isLoading,
        size,
        setSize,
        mutate,
        isReachingEnd,
        error,
    } = usePayments(
        selectedProperty ?? undefined,
        selectedUnit ?? undefined,
        undefined,
        debouncedKeyword,
        amount,
        purpose ?? undefined,
        method ?? undefined,
        status ?? undefined,
        sortColumn || undefined,
        sortDirection || undefined
    );

    const propertyOptions = useMemo(() => {
        return [
            ...properties.map(property => ({
                label: `${property.property_name}`,
                value: property.property_id.toString(),
            }))
        ];
    }, [properties]);

    useEffect(() => {
        if (selectedProperty === null && propertyOptions.length > 0) {
            setSelectedProperty(Number(propertyOptions[0].value));
        }
    }, [propertyOptions, selectedProperty, setSelectedProperty]);

    useEffect(() => {
        if (error) {
            showAlert("error", error.message);
        }
    }, [error, showAlert]);

    const loadMore = () => {
        setSize(size + 1);
    };

    const handleFilterClick = (value: string) => {
        const index = Number(value);
        setSelectedProperty(index === 0 ? null : index);
        setSize(1);
    }

    const handleSortChange = (column: PaymentSortBy) => {
        const newDirection: SortOrder = sortColumn === column && sortDirection === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
        setSort(column, newDirection);
        setSize(1);
    };

    return (
        <div className="no-select">
            {/* SECTION TITLE */}
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h4 className="m-0">{t("pages.payments.title")}</h4>
                    <p style={{ margin: 0, fontSize: 14 }}>{t("pages.payments.subtitle", { date: getCurrentFormattedDateTime() })}</p>
                </div>
            </div>

            {/* FILTER BUTTONS  */}
            <div className="d-none d-sm-flex justify-content-between align-items-center mt-4">
                <Switch options={propertyOptions} value={selectedProperty ? selectedProperty.toString() : "0"} onChange={handleFilterClick} />
            </div>

            <Switch className="d-sm-none mt-4" options={propertyOptions} value={selectedProperty ? selectedProperty.toString() : "0"} onChange={handleFilterClick} />

            {/* PM CONTRACTS OVERVIEW */}
            {isLoading ? (
                <div className="mt-4 white-card" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </div>
            ) : (
                <>
                    <PMContractFilters onFilterUpdated={() => setSize(1)} />
                    <div className="mt-4 white-card">
                        <InfiniteScroll
                            style={{ overflowY: "hidden", overflowX: "hidden" }}
                            dataLength={payments.length}
                            next={loadMore}
                            hasMore={!isReachingEnd}
                            loader={
                                <div className="d-flex justify-content-center mt-4">
                                    <div className="spinner-border text-primary" role="status" />
                                </div>
                            }
                            scrollableTarget="content-area"
                        >
                            <PaymentsTable
                                payments={payments}
                                selectedSortColumn={sortColumn}
                                selectedSortDirection={sortDirection}
                                onSortChange={handleSortChange}
                            />
                        </InfiniteScroll>
                    </div>
                </>
            )}
        </div>
    );
};

export default PaymentsPage;
