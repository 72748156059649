import { SortOrder } from "../@types";
import { API_URL } from "../utils/constants";
import { DocumentType, IDocument } from "./documentService";
import { ILease } from "./leaseService";

export enum LeadType {
  PROPERTY_FINDER = "property-finder",
  INSTAGRAM = "instagram",
  REFERRAL = "referral",
  WALK_IN = "walk-in",
  OTHERS = "others",
  AGENT = "agent",
}

export interface IStatus {
  status_id: number;
  status_name: string;
}

export interface ITenant {
  tenant_id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number?: string | null;
  birth_date: string;
  gender: string;
  nationality_code: string;
  source_of_lead: string;
  blocked: boolean;
  created_at: string;
  updated_at: string;
  passport: IDocument[];
  emirates_id: IDocument[];
  visa: IDocument[];
  documents: IDocument[];
  leases: ILease[];
}

export enum TenantSortBy {
  NAME = "name",
  EMAIL = "email",
  PHONE_NUMBER = "phone_number",
}

export const fetchTenants = async (url: string): Promise<ITenant[]> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.tenants;
};

export const constructTenantsUrl = (
  propertyId: number | null,
  search: string,
  page?: number,
  pageSize?: number,
  active?: boolean,
  sortBy?: TenantSortBy,
  sortOrder?: SortOrder
): string => {
  let queryParams = new URLSearchParams();

  if (search.trim().length > 0) {
    queryParams.append("search", encodeURIComponent(search));
  }
  if (propertyId !== null) {
    queryParams.append("propertyId", propertyId.toString());
  }
  if (page !== undefined) {
    queryParams.append("page", page.toString());
  }
  if (pageSize !== undefined) {
    queryParams.append("pageSize", pageSize.toString());
  }
  if (active !== undefined) {
    queryParams.append("active", active.toString());
  }
  if (sortBy !== undefined) {
    queryParams.append("sortBy", sortBy);
  }
  if (sortOrder !== undefined) {
    queryParams.append("sortOrder", sortOrder);
  }

  return `${API_URL}/tenants?${queryParams.toString()}`;
};

export const fetchTenant = async (tenant_id: number): Promise<ITenant> => {
  const response = await fetch(`${API_URL}/tenants/${tenant_id}`, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data;
};

export const createTenant = async (
  first_name: string,
  last_name: string,
  email: string,
  phone_number: string | null,
  birth_date: Date,
  gender: string,
  nationality_code: string,
  source_of_lead: string
): Promise<ITenant> => {
  const response = await fetch(`${API_URL}/tenants`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      first_name,
      last_name,
      email,
      phone_number,
      birth_date,
      gender,
      nationality_code,
      source_of_lead,
    }),
  });

  const data = await response.json();
  if (response.status === 201) return data;
  else throw new Error(data.message);
};

export const updateTenant = async (tenant: ITenant): Promise<ITenant> => {
  const response = await fetch(`${API_URL}/tenants/${tenant.tenant_id}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(tenant),
  });

  const data = await response.json();
  if (response.status === 200) return data;
  else throw new Error(data.message);
};

export const deleteTenant = async (tenant_id: number): Promise<boolean> => {
  const response = await fetch(`${API_URL}/tenants/${tenant_id}`, {
    method: "DELETE",
    credentials: "include",
  });
  return response.status === 200;
};

export const uploadTenantDocuments = async (
  tenant_id: number,
  files: File[],
  document_type: DocumentType | null = null,
  valid_until_dates: (string | null)[] = [],
  internal_document_list: string[] = [],
  document_identifiers: (string | null)[] = []
): Promise<IDocument[]> => {
  const formData = new FormData();

  files.forEach((file, index) => {
    formData.append(`file_${index}`, file);
    if (valid_until_dates[index]) {
      formData.append(
        `valid_until_${index}`,
        valid_until_dates[index] as string
      );
    }
    if (internal_document_list[index]) {
      formData.append(
        `internal_document_${index}`,
        internal_document_list[index]
      );
    }
    if (document_identifiers[index]) {
      formData.append(
        `document_identifier_${index}`,
        document_identifiers[index] as string
      );
    }
  });

  const response = await fetch(
    `${API_URL}/tenants/${tenant_id}/${document_type ?? "documents"}`,
    {
      method: "POST",
      credentials: "include",
      body: formData,
    }
  );

  const data = await response.json();
  if (response.status === 201) return data.documents;
  else throw new Error(data.message);
};

export const exportTenantCsv = async (tenantIds: number[]): Promise<Blob> => {
  const response = await fetch(`${API_URL}/tenants/csv-export`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ tenantIds }),
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message);
  }

  return await response.blob();
};
