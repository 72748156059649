import '../sidemodal.css';
import './tenant-sidemodal.css';

import React, { useCallback, useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import CustomButton from '../../../components/custom-button/custom-button';
import { UploadedFile } from '../../../components/file-upload/file-upload';
import SidebarInputField from '../components/sidebar-input-field/sidebar-input-field';
import { Value } from 'react-phone-number-input';
import CustomPhoneInput from '../../../components/custom-phone-input/custom-phone-input';
import useAlertStore from '../../../stores/alertStore';
import { useTranslation } from 'react-i18next';
import BirthdateSelect from '../../../components/birthdate-select/birthdate-select';
import GenderSelect from '../../../components/gender-select/gender-select';
import NationalitySelect from '../../../components/nationality-select/nationality-select';
import { ITenant, LeadType, createTenant, uploadTenantDocuments } from '../../../services/tenantService';
import { DocumentType } from '../../../services/documentService';
import FileUploadSection from '../../../components/file-upload-section/file-upload-section';
import useEmployeeStore from '../../../stores/employeeStore';
import useEditPermission from '../../../hooks/useEditPermission';
import SidebarSelectField, { SidebarSelectFieldOptions } from '../components/sidebar-select-field/sidebar-select-field';

interface TenantSidebarModalProps {
    isVisible: boolean;
    onClose: () => void;
    mutate: (data?: any, shouldRevalidate?: boolean) => Promise<any>;
}

const TenantSidebarModal: React.FC<TenantSidebarModalProps> = ({ isVisible, onClose, mutate }) => {
    const { t } = useTranslation();

    const leadOptions: SidebarSelectFieldOptions[] = Object.values(LeadType).map((lead) => ({
        label: lead.replace(/-/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase()),
        value: lead,
    }));

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const employeeRoleId = useEmployeeStore(state => state.employee?.role_id);
    const hasEditPermission = useEditPermission(employeeRoleId);

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [lead, setLead] = useState<string>("");
    const [firstname, setFirstname] = useState<string>("");
    const [lastname, setLastname] = useState<string>("");
    const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
    const [gender, setGender] = useState<string>("");
    const [nationality, setNationality] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<Value | undefined>(undefined);
    const [files, setFiles] = useState<UploadedFile[]>([]);
    const [passportFiles, setPassportFiles] = useState<UploadedFile[]>([]);
    const [emiratesIDFiles, setEmiratesIDFiles] = useState<UploadedFile[]>([]);
    const [visaFiles, setVisaFiles] = useState<UploadedFile[]>([]);

    // Error states
    const [firstnameError, setFirstnameError] = useState<string>("");
    const [lastnameError, setLastnamError] = useState<string>("");
    const [birthdateError, setBirthdateError] = useState<string>("");
    const [genderError, setGenderError] = useState<string>("");
    const [nationalityError, setNationalityError] = useState<string>("");
    const [phoneNumberError, setPhoneNumberError] = useState<string>("");
    const [leadError, setLeadError] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [error, setError] = useState<string>("");

    // Clean states
    useEffect(() => {
        if (!isVisible) {
            setFirstname("");
            setLastname("");
            setDateOfBirth(null);
            setNationality("");
            setGender("");
            setEmail("");
            setPhoneNumber("");
            setLead("");
            setFiles([]);
            setPassportFiles([]);
            setEmiratesIDFiles([]);
            setVisaFiles([]);

            // Reset error states
            setFirstnameError("");
            setLastnamError("");
            setBirthdateError("");
            setGenderError("");
            setNationalityError("");
            setPhoneNumberError("");
            setLeadError("");
            setEmailError("");
            setError("");
        }
    }, [isVisible]);

    const onHandleCreateTenant = useCallback(async () => {
        let isValid = true;

        // Check if the firstname is not empty
        if (!firstname.trim()) {
            setFirstnameError(t("invalid_input.firstname_empty"));
            isValid = false;
        }

        // Check if the lastname is not empty
        if (!lastname.trim()) {
            setLastnamError(t("invalid_input.lastname_empty"));
            isValid = false;
        }

        // Check if the dateOfBirth is not empty
        if (!dateOfBirth) {
            setBirthdateError(t("invalid_input.date_of_birth_empty"));
            isValid = false;
        }

        // Check if the gender is not empty
        if (!gender.trim()) {
            setGenderError(t("invalid_input.gender_empty"));
            isValid = false;
        }

        // Check if the nationality is not empty
        if (!nationality.trim()) {
            setNationalityError(t("invalid_input.nationality_empty"));
            isValid = false;
        }

        // Check if the phone number is not empty
        if (!phoneNumber?.trim()) {
            setPhoneNumberError(t("invalid_input.phone_number_empty"));
            isValid = false;
        }

        // Check if the lead is not empty
        if (!lead.trim()) {
            setLeadError(t("invalid_input.source_of_lead_empty"));
            isValid = false;
        }

        // Basic email validation
        if (!email.trim()) {
            setEmailError(t("invalid_input.email_empty"));
            isValid = false;
        } else if (!/^\S+@\S+\.\S+$/.test(email.trim())) {
            setEmailError(t("invalid_input.email_invalid"));
            isValid = false;
        }

        // Check passport files for required fields
        if (passportFiles.length > 0) {
            const invalidPassportFiles = passportFiles.filter(
                (file) => !file.valid_until || !file.document_identifier || file.document_identifier.trim() === ""
            );

            if (invalidPassportFiles.length > 0) {
                setError(t("pages.investor_detail.upload_passport_missing_fields_error"));
                isValid = false;
            }
        }

        // Check emirates ID files for required fields
        if (emiratesIDFiles.length > 0) {
            const invalidEmiratesIDFiles = emiratesIDFiles.filter(
                (file) => !file.valid_until || !file.document_identifier || file.document_identifier.trim() === ""
            );

            if (invalidEmiratesIDFiles.length > 0) {
                setError(t("pages.investor_detail.upload_emirates_id_missing_fields_error"));
                isValid = false;
            }
        }

        if (isValid) {
            try {
                setError("");
                setIsLoading(true);
                const createdTenant = await createTenant(firstname, lastname, email, phoneNumber ?? "", dateOfBirth!, gender, nationality, lead);

                // Upload files
                if (files.length > 0) {
                    await uploadTenantDocuments(createdTenant.tenant_id, files.map(uploadedFiles => uploadedFiles.file));
                }

                // Upload Passport
                if (passportFiles.length > 0) {
                    await uploadTenantDocuments(
                        createdTenant.tenant_id,
                        passportFiles.map(passportFile => passportFile.file),
                        DocumentType.PASSPORT,
                        passportFiles.map(file => file.valid_until ? file.valid_until.toISOString() : null),
                        undefined,
                        passportFiles.map(file => file.document_identifier ?? null),
                    );
                }

                // Upload Emirates ID
                if (emiratesIDFiles.length > 0) {
                    await uploadTenantDocuments(
                        createdTenant.tenant_id,
                        emiratesIDFiles.map(emiratesId => emiratesId.file),
                        DocumentType.EMIRATES_ID,
                        emiratesIDFiles.map(file => file.valid_until ? file.valid_until.toISOString() : null),
                        undefined,
                        emiratesIDFiles.map(file => file.document_identifier ?? null),
                    );
                }

                // Upload Visa
                if (visaFiles.length > 0) {
                    await uploadTenantDocuments(
                        createdTenant.tenant_id,
                        visaFiles.map(visa => visa.file),
                        DocumentType.VISA,
                        visaFiles.map(file => file.valid_until ? file.valid_until.toISOString() : null)
                    );
                }

                mutate(
                    (data: ITenant[][]) => {
                        if (!data) return [];
                        return [
                            [createdTenant, ...data[0]],
                            ...data.slice(1)
                        ];
                    },
                    false
                );

                onClose();
                showAlert("success", t("modals.create_tenant.create_success_message"));
            } catch (error) {
                // @ts-ignore
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    }, [firstname, lastname, dateOfBirth, gender, nationality, lead, email, phoneNumber, files, passportFiles, emiratesIDFiles, visaFiles, mutate, onClose, showAlert, t]);

    return (
        <div className={`sidebar-modal-backdrop ${isVisible ? 'visible' : ''}`}>
            <div className={`sidebar-modal ${isVisible ? 'visible' : ''}`}>
                <div className="d-flex align-items-center justify-content-between my-3">
                    <h4 className="sidebar-modal-headline ms-3">{t("modals.create_tenant.title")}</h4>
                    <button className="btn" onClick={onClose}>
                        <IoClose size={20} />
                    </button>
                </div>
                <div className="sidebar-modal-divider" />
                <div className="sidebar-modal-content">
                    <div className="sidebar-modal-scrollable-content">
                        <div className="row">
                            <div className="col-12 col-xxl-6">
                                <SidebarInputField
                                    id="firstname"
                                    type="text"
                                    label={t("labels.firstname")}
                                    placeholder="Max"
                                    value={firstname}
                                    errorMessage={firstnameError}
                                    onClear={() => setFirstname("")}
                                    onChange={(value: string) => {
                                        setFirstname(value);
                                        setFirstnameError("");
                                    }}
                                    required
                                    labelOnTop
                                />
                            </div>
                            <div className="col-12 col-xxl-6">
                                <SidebarInputField
                                    id="lastname"
                                    type="text"
                                    label={t("labels.lastname")}
                                    placeholder="Mustermann"
                                    value={lastname}
                                    errorMessage={lastnameError}
                                    onClear={() => setLastname("")}
                                    onChange={(value: string) => {
                                        setLastname(value);
                                        setLastnamError("");
                                    }}
                                    required
                                    labelOnTop
                                />
                            </div>
                            <div className="col-12">
                                <BirthdateSelect
                                    id="user-birthdate"
                                    label={t("labels.birthdate")}
                                    errorMessage={birthdateError}
                                    date={dateOfBirth}
                                    onChange={(value: Date) => {
                                        setDateOfBirth(value);
                                        setBirthdateError("");
                                    }}
                                    required
                                    labelOnTop
                                />
                            </div>
                            <div className="col-12 col-xxl-6">
                                <GenderSelect
                                    gender={gender}
                                    errorMessage={genderError}
                                    onChange={(value: string) => {
                                        setGender(value);
                                        setGenderError("");
                                    }}
                                    required
                                    labelOnTop
                                />
                            </div>
                            <div className="col-12 col-xxl-6">
                                <NationalitySelect
                                    nationality={nationality}
                                    errorMessage={nationalityError}
                                    onChange={(value: string) => {
                                        setNationality(value);
                                        setNationalityError("");
                                    }}
                                    required
                                    labelOnTop
                                />
                            </div>
                            <div className="col-12 col-xxl-6">
                                <SidebarInputField
                                    id="email"
                                    type="email"
                                    label={t("labels.email")}
                                    placeholder="tenant@email.com"
                                    value={email}
                                    errorMessage={emailError}
                                    onClear={() => setEmail("")}
                                    onChange={(value: string) => {
                                        setEmail(value);
                                        setEmailError("");
                                    }}
                                    required
                                    labelOnTop
                                />
                            </div>
                            <div className="col-12 col-xxl-6">
                                <CustomPhoneInput
                                    id="phone-number"
                                    defaultCountry='AE'
                                    value={phoneNumber}
                                    onClear={() => setPhoneNumber(undefined)}
                                    onChange={(value: string) => {
                                        setPhoneNumber(value);
                                        setPhoneNumberError("");
                                    }}
                                    errorMessage={phoneNumberError}
                                    labelOnTop
                                    required
                                />
                            </div>
                            <div className="col-12 col-xxl-6">
                                <SidebarSelectField
                                    id="tenant-creation-source-of-lead-selection"
                                    label={t("labels.source_of_lead")}
                                    placeholder={t("placeholders.select")}
                                    value={lead}
                                    onChange={(value: string) => {
                                        setLead(value);
                                        setLeadError("");
                                    }}
                                    options={leadOptions}
                                    errorMessage={leadError}
                                    labelOnTop
                                    required
                                />
                            </div>
                        </div>

                        <div className="sidebar-modal-divider mt-2 mb-4" />

                        {/* UPLOAD PASSPORT SECTION */}
                        <FileUploadSection
                            fileUploadId="create-tenant-upload-passport"
                            title={t("modals.create_tenant.upload_passport")}
                            documents={[]}
                            uploadedFiles={passportFiles}
                            onUpdateUploadedFiles={setPassportFiles}
                            maxFilesToUpload={2}
                            hasEditPermission={hasEditPermission}
                            isHeadlineLarge={false}
                            isDefaultExpanded={false}
                            allowExpirationDate
                            allowDocumentIdentifier
                            removePadding
                        />

                        {/* UPLOAD EMIRATES ID SECTION */}
                        <FileUploadSection
                            fileUploadId="create-tenant-upload-emirates-id"
                            title={t("modals.create_tenant.upload_emirates_id")}
                            documents={[]}
                            uploadedFiles={emiratesIDFiles}
                            onUpdateUploadedFiles={setEmiratesIDFiles}
                            maxFilesToUpload={2}
                            hasEditPermission={hasEditPermission}
                            isHeadlineLarge={false}
                            isDefaultExpanded={false}
                            allowExpirationDate
                            allowDocumentIdentifier
                            removePadding
                        />

                        {/* UPLOAD VISA SECTION */}
                        <FileUploadSection
                            fileUploadId="create-tenant-upload-visa"
                            title={t("modals.create_tenant.upload_visa")}
                            documents={[]}
                            uploadedFiles={visaFiles}
                            onUpdateUploadedFiles={setVisaFiles}
                            maxFilesToUpload={1}
                            hasEditPermission={hasEditPermission}
                            isHeadlineLarge={false}
                            isDefaultExpanded={false}
                            allowExpirationDate
                            removePadding
                        />

                        {/* UPLOAD DOCUMENTS SECTION */}
                        <FileUploadSection
                            fileUploadId="create-tenant-upload-documents"
                            title={t("modals.create_tenant.upload_documents")}
                            documents={[]}
                            uploadedFiles={files}
                            onUpdateUploadedFiles={setFiles}
                            hasEditPermission={hasEditPermission}
                            isHeadlineLarge={false}
                            isDefaultExpanded={false}
                            removePadding
                        />

                        {/* Extra space to ensure bottom content is visible when scrolling */}
                        <div style={{ height: "70px" }}></div>
                    </div>
                    <div className="store-investor-btn">
                        {error && (
                            <div className="error-container mb-2">
                                <p className="text-danger mb-0">{error}</p>
                            </div>
                        )}
                        <CustomButton
                            className="w-100"
                            title={t("modals.create_tenant.create_button")}
                            isLoading={isLoading}
                            onClick={onHandleCreateTenant}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TenantSidebarModal;
