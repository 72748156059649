import { SortOrder } from "../@types";
import { API_URL } from "../utils/constants";
import { IConstructionProgressItem } from "./constructionProgressService";
import { ILease } from "./leaseService";
import { IPaymentPlanItem } from "./paymentPlanService";
import { IUnit, UnitSortBy } from "./unitService";

export interface IProperty {
  property_id: number;
  property_name: string;
  property_image: string;
  property_location: string;
  property_map_link: string;
  email: string | null;
  phone_number: string | null;
  plot_no: number;
  plot_purchased_price: number;
  plot_size: number;
  total_build_up_area_size: number;
  registration_number: string;
  under_construction: boolean;
  handover: boolean;
  published?: boolean;
  makani_no?: string;
  planned_completion?: string | null;
  created_at: string;
  payment_plan_items: IPaymentPlanItem[];
  construction_progress_items: IConstructionProgressItem[];
}

export interface ICreatePropertyPayload {
  property_name: string;
  property_location?: string;
  property_map_link?: string;
  email?: string;
  phone_number?: string;
  plot_no: number;
  makani_no?: string;
  plot_purchased_price: number;
  plot_size: number;
  total_build_up_area_size: number;
  registration_number: string;
  under_construction: boolean;
  handover: boolean;
  published: boolean;
  planned_completion?: string;
}

export interface IPropertyDashboard {
  property: IProperty;
  totalUnits: number;
  rentedUnits: number;
  totalOccupants: number;
  totalAvailableSoonUnits: number;
  availableSoonLeases: ILease[];
  availableUnits: IUnit[];
  rentThisMonth: number;
  depositThisMonth: number;
  petDepositThisMonth: number;
}

export const fetchProperties = async (url: string): Promise<IProperty[]> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.properties;
};

export const constructPropertiesUrl = (
  page?: number,
  pageSize?: number
): string => {
  let queryParams = new URLSearchParams();

  if (page !== undefined) {
    queryParams.append("page", page.toString());
  }
  if (pageSize !== undefined) {
    queryParams.append("pageSize", pageSize.toString());
  }

  return `${API_URL}/properties?${queryParams.toString()}`;
};

export const fetchProperty = async (propertyId: number): Promise<IProperty> => {
  const response = await fetch(`${API_URL}/properties/${propertyId}`, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data;
};

export const fetchPropertyDashboard = async (
  propertyId: number
): Promise<IPropertyDashboard> => {
  const response = await fetch(
    `${API_URL}/properties/${propertyId}/dashboard`,
    {
      method: "GET",
      credentials: "include",
    }
  );

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data;
};

/**
 * Updates an existing property
 * @param property_id The ID of the property to update
 * @param updateData The data to update the property with
 * @returns The updated property
 */
export const updateProperty = async (
  property_id: number,
  updateData: Partial<IProperty>
): Promise<IProperty> => {
  const response = await fetch(`${API_URL}/properties/${property_id}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updateData),
  });

  const data = await response.json();
  if (response.status === 200) return data;
  else throw new Error(data.message);
};

export const fetchPropertyAvailableUnits = async (
  url: string
): Promise<IUnit[]> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.available_units;
};

export const constructPropertyAvailableUnitsUrl = (
  propertyId: number,
  search: string,
  page?: number,
  pageSize?: number,
  bedrooms?: string,
  bathrooms?: string,
  minimum_annual_rent?: string,
  sortBy?: UnitSortBy,
  sortOrder?: SortOrder
): string => {
  let queryParams = new URLSearchParams();

  if (search.trim().length > 0) {
    queryParams.append("search", encodeURIComponent(search));
  }
  if (propertyId !== null) {
    queryParams.append("propertyId", propertyId.toString());
  }
  if (page !== undefined) {
    queryParams.append("page", page.toString());
  }
  if (pageSize !== undefined) {
    queryParams.append("pageSize", pageSize.toString());
  }
  if (bedrooms !== undefined && bedrooms.length > 0) {
    queryParams.append("bedrooms", bedrooms);
  }
  if (bathrooms !== undefined && bathrooms.length > 0) {
    queryParams.append("bathrooms", bathrooms);
  }
  if (minimum_annual_rent !== undefined && minimum_annual_rent.length > 0) {
    queryParams.append("minimum_annual_rent", minimum_annual_rent);
  }
  if (sortBy !== undefined) {
    queryParams.append("sortBy", sortBy);
  }
  if (sortOrder !== undefined) {
    queryParams.append("sortOrder", sortOrder);
  }

  return `${API_URL}/properties/${propertyId}/available-units?${queryParams.toString()}`;
};

/**
 * Creates a new property without uploading images or documents
 * @param payload The property data
 * @returns The created property
 */
export const createProperty = async (
  payload: ICreatePropertyPayload
): Promise<IProperty> => {
  console.log("createProperty service called with payload:", payload);
  console.log("API_URL:", API_URL);
  
  try {
    const response = await fetch(`${API_URL}/properties`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    
    console.log("createProperty response status:", response.status);
    const data = await response.json();
    console.log("createProperty response data:", data);
    
    if (!response.ok) throw new Error(data.message || "Failed to create property");
    
    return data;
  } catch (error) {
    console.error("Error in createProperty service:", error);
    throw error;
  }
};

/**
 * Uploads a property image after creating the property
 * @param propertyId The ID of the property
 * @param propertyImage The image file to upload
 * @returns The updated property with the image URL
 */
export const uploadPropertyImage = async (
  propertyId: number,
  propertyImage: File
): Promise<IProperty> => {
  const formData = new FormData();
  formData.append('image', propertyImage);
  
  const response = await fetch(`${API_URL}/properties/${propertyId}/upload-image`, {
    method: 'POST',
    credentials: 'include',
    body: formData,
  });
  
  const data = await response.json();
  if (!response.ok) throw new Error(data.message);
  
  return data;
};
