import '../base-modal.css';
import './pm-contract-csv-import-modal.css';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uploadPMContractCsvFile } from '../../services/pmContractService';

interface ModalProps {
    csv: File;
    onClose: (refresh: boolean) => void;
}

const ImportPMContractsModal: React.FC<ModalProps> = ({ csv, onClose }) => {
    const { t } = useTranslation();

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onHandleCSVUpload = async () => {
        try {
            setIsLoading(true);
            await uploadPMContractCsvFile(csv);
            onClose(true);
        } catch (error) {
            console.log("Error while importing pm contracts through csv file: ", error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="modal-backdrop">
            <div className="modal-box">
                <h3 className="input-modal-title mb-4">{t("modals.pm_contract_csv_import.title")}</h3>
                <p>{t("modals.pm_contract_csv_import.message")}</p>
                <div className="text-end mt-4">
                    <button className={`btn btn-success add-btn me-2 ${isLoading ? 'disabled' : ''}`} onClick={onHandleCSVUpload} disabled={isLoading}>
                        {isLoading ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        ) : (
                            t("buttons.import")
                        )}
                    </button>
                    {!isLoading && (
                        <button className="btn btn-dark close-btn" onClick={() => onClose(false)}>{t("buttons.close")}</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ImportPMContractsModal;
