// DashboardPage.tsx
import BarChart from "../components/bar-chart/bar-chart";
import PieChart from "../components/pie-chart/pie-chart";
import useEmployeeStore from "../stores/employeeStore";
import { useEffect, useMemo } from "react";
import useAlertStore from "../stores/alertStore";
import { useTranslation } from "react-i18next";
import { interpolateColor } from "../utils/helpers";
import { useDashboard } from "../hooks/useDashboard";
import usePageTitle from "../hooks/usePageTitle";
import Lottie from "lottie-react";
import LoadingAnim from "../assets/anims/loading.-anim.json";
import Switch from "../components/switch/switch";
import { useProperties } from "../hooks/useProperties";
import useDashboardFilterStore from "../stores/dashboardFilterStore";
import SidebarSelectField from "../modals/sidebar/components/sidebar-select-field/sidebar-select-field";

const DashboardPage = () => {
    const { t } = useTranslation();

    // Set page title
    usePageTitle(t("pages.dashboard.page_title"));

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const { employee } = useEmployeeStore();
    const { selectedProperty, setSelectedProperty } = useDashboardFilterStore();

    // Hooks
    const { properties } = useProperties();
    const { dashboardData, isLoading, error } = useDashboard(selectedProperty);

    const propertyOptions = useMemo(() => [
        { label: "Alle Projekte", value: "0" },
        ...properties.map(property => ({
            label: `${property.property_name}`,
            value: property.property_id.toString(),
        }))
    ], [properties]);

    useEffect(() => {
        if (error) {
            showAlert("error", error.message);
        }
    }, [error, showAlert]);

    const handleFilterClick = (value: string) => {
        const index = Number(value);
        setSelectedProperty(index === 0 ? null : index);
    };

    const ratio = useMemo(() => {
        if (!dashboardData) return 0;
        return dashboardData.totalInvestors > 0 ? dashboardData.verifiedInvestors / dashboardData.totalInvestors : 0;
    }, [dashboardData]);

    const investorsWithoutDocumentsColor = useMemo(() => interpolateColor("#e74c3c", "#76CA66", 1 - ratio), [ratio]);

    const hasAccessToPMOnboardingData = useMemo(() => {
        if (!employee) return false;
        return employee.employee_id === 2 || employee.employee_id === 18 || employee.employee_id === 24;
    }, [employee]);

    return (
        <div className="no-select">
            {/* SECTION TITLE */}
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h4 className="m-0">{t("pages.dashboard.title", { name: employee?.first_name })}</h4>
                    <p style={{ margin: 0, fontSize: 14 }}>{t("pages.dashboard.subtitle")}</p>
                </div>
            </div>

            {/* DESKTOP FILTER BUTTONS */}
            <div className="d-none d-sm-flex justify-content-between align-items-center mt-4">
                <Switch 
                    options={propertyOptions} 
                    value={selectedProperty ? selectedProperty.toString() : "0"} 
                    onChange={handleFilterClick} 
                />
            </div>

            {/* MOBILE FILTER BUTTONS */}
            <SidebarSelectField
                className="d-sm-none mt-4"
                id="dashboard-property-selection"
                label={t("labels.filter.property")}
                value={selectedProperty ? selectedProperty.toString() : "0"}
                onChange={handleFilterClick}
                options={propertyOptions}
            />

            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </div>
            ) : (
                dashboardData && (
                    <>

                        {hasAccessToPMOnboardingData && (
                            <div className="mb-5">
                                <h4 className="mt-5">Property Management Contracts:</h4>
                                <div className="row mt-2 gy-4">
                                    <div className="col-12 col-md-4 col-xl-3">
                                        <div className="white-card">
                                            <p>Premium</p>
                                            <PieChart
                                                colors={['#76CA66', '#D9DDE7']}
                                                total={dashboardData.totalPMContracts}
                                                active={dashboardData.totalPMPremium}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-xl-3">
                                        <div className="white-card">
                                            <p>Basic</p>
                                            <PieChart
                                                colors={['#76CA66', '#D9DDE7']}
                                                total={dashboardData.totalPMContracts}
                                                active={dashboardData.totalPMBasic}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-xl-3">
                                        <div className="white-card">
                                            <p>Unsigned</p>
                                            <PieChart
                                                colors={['#76CA66', '#D9DDE7']}
                                                total={dashboardData.totalPMContracts}
                                                active={dashboardData.totalPMContractsUnsigned}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-xl-3">
                                        <div className="white-card">
                                            <p>Onboarding</p>
                                            <PieChart
                                                colors={['#76CA66', '#D9DDE7']}
                                                total={dashboardData.totalPMOnboarding}
                                                active={dashboardData.totalPMOnboarding}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="row mt-2 gy-4">
                            <div className="col-12 col-md-4 col-xl-3">
                                <div className="white-card">
                                    <p>{t("pages.dashboard.active_investors")}</p>
                                    <PieChart
                                        colors={['#76CA66', '#D9DDE7']}
                                        total={dashboardData.totalInvestors}
                                        active={dashboardData.activeInvestors}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 col-xl-3">
                                <div className="white-card">
                                    <p>{t("pages.dashboard.logged_in_investors")}</p>
                                    <PieChart
                                        colors={['#76CA66', '#D9DDE7']}
                                        total={dashboardData.totalInvestors}
                                        active={dashboardData.loggedInInvestors}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 col-xl-3">
                                <div className="white-card">
                                    <p>{t("pages.dashboard.investors_verified")}</p>
                                    <PieChart
                                        colors={['#76CA66', '#D9DDE7']}
                                        total={dashboardData.totalInvestors}
                                        active={dashboardData.verifiedInvestors}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 col-xl-3">
                                <div className="white-card">
                                    <p>{t("pages.dashboard.units_covered")}</p>
                                    <PieChart
                                        colors={['#76CA66', '#D9DDE7']}
                                        total={dashboardData.totalUnits}
                                        active={dashboardData.unitsCovered}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-8 col-xl-6">
                                <div className="white-card">
                                    <p>{t("pages.dashboard.investor_growth")}</p >
                                    <BarChart dataNumbers={dashboardData.dataNumbers} />
                                </div>
                            </div>
                        </div>
                    </>
                )
            )}
        </div>
    );
};

export default DashboardPage;
