import styled, { keyframes } from "styled-components";

// Fade In animation
const fadeInLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const PropertyDetailsCard = styled.div<{ underConstruction: boolean }>`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: white;
  overflow: hidden;
  border: 1px solid #dfe3ea;
  opacity: 0;
  animation: ${fadeInLeft} 1s forwards 0.5s;
  position: relative;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const Image = styled.img`
  height: 300px;
  width: 100%;
  object-fit: cover;
`;

export const UploadOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 4;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  .upload-icon {
    color: black;
    font-size: 40px;
  }

  .upload-text {
    color: white;
    margin-top: 10px;
    font-size: 16px;
    text-align: center;
  }
`;

export const UploadInput = styled.input`
  display: none;
`;

export const Badge = styled.span`
  position: absolute;
  bottom: 16px;
  left: 16px;
  background-color: #fcedcb;
  color: black;
  padding: 8px 10px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: normal;
  z-index: 3;
`;

export const PropertyInfo = styled.div`
  text-align: center;
  padding: 0 20px 20px;
`;

export const PropertyName = styled.h5`
  font-weight: bold;
  margin-top: 20px;
  color: #17224D;
`;

export const PropertyPlotNo = styled.p`
  font-size: 14px;
  margin-bottom: 20px;
  color: #666B80;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f4f7fe;
  margin-bottom: 20px;
`;

export const Stats = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
`;

export const StatItem = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #17224D;

  span {
    display: block;
    font-size: 14px;
    font-weight: normal;
    color: #6c757d;
  }
`;

export const CustomProgress = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  height: 16px;
  background-color: #f8f9fa;
  border-radius: 18px;
`;

export const ProgressBar = styled.div<{ leasePercentage: number }>`
  background-color: #fecc00;
  font-size: 12px;
  width: ${({ leasePercentage }) => `${leasePercentage}%`};
  height: 100%;
  border-radius: 18px;
`;

export const PropertyLeasedProgress = styled.p`
  text-align: start;
  font-size: 14px;
  margin-top: 8px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  color: #666B80;
`;

export const ContactDetailText = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
`;

export const ContactDetailStrong = styled.strong`
  color: #000;
`;

// Neues Layout für die Informationen
export const InfoSection = styled.div`
  margin-top: 16px;
  background-color: transparent;
  border-radius: 12px;
  padding: 0;
`;

export const InfoList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #EBF1F7;
  
  &:last-child {
    border-bottom: none;
  }
`;

export const IconCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 30px;
  margin-right: -5px;
  
  svg {
    color: #7FCCFA;
    font-size: 20px;
  }
`;

export const LabelCol = styled.div`
  display: flex;
  align-items: center;
  flex: 1.5;
  font-size: 14px;
  color: #666B80;
  padding-left: 10px;
`;

export const ValueCol = styled.div`
  flex: 2;
  text-align: right;
  font-size: 15px;
  font-weight: 500;
  color: #17224D;
  
  &.text-muted {
    color: #95A0B4;
  }
`;

export const StatusIndicator = styled.span<{ status: 'active' | 'inactive' }>`
  display: inline-block;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  background-color: ${({ status }) => status === 'active' ? '#E2F9F1' : '#EBF1F7'};
  color: ${({ status }) => status === 'active' ? '#136956' : '#666B80'};
`;

export const DetailLink = styled.a`
  color: #276EF1;
  text-decoration: none;
  font-size: 14px;
  padding: 5px 0;
  display: inline-block;

  &:hover {
    text-decoration: underline;
  }
`;

// Under Construction overlay
export const UnderConstructionOverlay = styled.div<{ underConstruction: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: ${({ underConstruction }) => (underConstruction ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 2;

  .construction-icon {
    color: #fff;
    font-size: 40px;
  }
`;

// Tabs für wichtige/weniger wichtige Informationen
export const TabsContainer = styled.div`
  margin-top: 20px;
  border-bottom: 1px solid #EBF1F7;
  display: flex;
  overflow-x: auto;
  padding-bottom: 1px;
  
  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
`;

export const Tab = styled.button<{ active: boolean }>`
  background: none;
  border: none;
  padding: 12px 20px;
  margin-right: 8px;
  font-size: 14px;
  font-weight: ${({ active }) => active ? '600' : '500'};
  color: ${({ active }) => active ? '#17224D' : '#666B80'};
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  transition: color 0.2s ease;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: ${({ active }) => active ? '#7FCCFA' : 'transparent'};
    border-radius: 3px 3px 0 0;
    transition: background-color 0.2s ease;
  }
  
  &:hover {
    color: #17224D;
    
    &:after {
      background-color: ${({ active }) => active ? '#7FCCFA' : '#EBF1F7'};
    }
  }
`;

export const TabContent = styled.div`
  padding: 24px 0;
`;
