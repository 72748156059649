import { API_URL } from "../utils/constants";
import { IDocument } from "./documentService";
import { TFunction } from "i18next";
import { ILease } from "./leaseService";
import { IBankDetail } from "./bankDetailService";
import { SortOrder } from "../@types";
import { IUnitAccount } from "./unitService";

// Enums
export enum PaymentSortBy {
  UNIT_NUMBER = "unit_number",
  AMOUNT = "amount",
  CREATED_AT = "created_at",
}

export enum PaymentFilter {
  OVERDUE = "overdue",
  UPCOMING = "upcoming",
}

export enum PaymentMethod {
  CASH = "cash",
  CHEQUE = "cheque",
  BANK_TRANSFER = "bank_transfer",
}

export const formatPaymentMethod = (method: PaymentMethod, t: TFunction) => {
  switch (method) {
    case PaymentMethod.CHEQUE:
      return t("payment_method.cheque");
    case PaymentMethod.CASH:
      return t("payment_method.cash");
    case PaymentMethod.BANK_TRANSFER:
      return t("payment_method.bank_transfer");
    default:
      return "-";
  }
};

export enum PaymentPurpose {
  RENT = "rent",
  SECURITY_DEPOSIT = "security_deposit",
  PET_DEPOSIT = "pet_deposit",
  ADMIN_FEE = "admin_fee",
  PROPERTY_MANAGEMENT_FEE = "property_management_fee",
  HANDOVER_FEE = "handover_fee",
  OVER_PAYMENT = "over_payment",
  UNDER_PAYMENT = "under_payment",
}

export const formatPaymentPurpose = (purpose: PaymentPurpose, t: TFunction) => {
  switch (purpose) {
    case PaymentPurpose.RENT:
      return t("payment_purpose.rent");
    case PaymentPurpose.SECURITY_DEPOSIT:
      return t("payment_purpose.security_deposit");
    case PaymentPurpose.PET_DEPOSIT:
      return t("payment_purpose.pet_deposit");
    case PaymentPurpose.ADMIN_FEE:
      return t("payment_purpose.admin_fee");
    case PaymentPurpose.PROPERTY_MANAGEMENT_FEE:
      return t("payment_purpose.property_management_fee");
    case PaymentPurpose.HANDOVER_FEE:
      return t("payment_purpose.handover_fee");
    case PaymentPurpose.OVER_PAYMENT:
      return t("payment_purpose.over_payment");
    case PaymentPurpose.UNDER_PAYMENT:
      return t("payment_purpose.under_payment");
    default:
      return "-";
  }
};

export enum PaymentDirection {
  INCOMING = "incoming",
  OUTGOING = "outgoing",
}

export enum PaymentStatus {
  PENDING = "pending",
  PAID = "paid",
  FAILED = "failed",
  BOUNCED = "bounced",
  CANCELLED = "cancelled",
}

export const formatPaymentStatus = (status: PaymentStatus, t: TFunction) => {
  switch (status) {
    case PaymentStatus.PENDING:
      return t("payment_status.pending");
    case PaymentStatus.PAID:
      return t("payment_status.paid");
    case PaymentStatus.FAILED:
      return t("payment_status.failed");
    case PaymentStatus.BOUNCED:
      return t("payment_status.bounced");
    case PaymentStatus.CANCELLED:
      return t("payment_status.cancelled");
    default:
      return "-";
  }
};

// Interfaces
export interface IPayment {
  payment_id: number;
  zoho_id: string | null;
  lease: ILease | null;
  unit_account: IUnitAccount;
  due_date: string;
  amount: number;
  cheque_no: string | null;
  bank_name: string | null;
  remark: string | null;
  method: PaymentMethod;
  purpose: PaymentPurpose;
  status: PaymentStatus;
  direction: PaymentDirection;
  payment_date: string | null;
  documents: IDocument[];
  created_at: string;
  updated_at: string;
  destination_bank_detail: IBankDetail | null;
}

// Fetch Payments
export const fetchPayments = async (
  url: string
): Promise<{
  payments: IPayment[];
  totalCount: number;
  currentPageCount: number;
}> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return {
    payments: data.payments,
    totalCount: data.totalCount,
    currentPageCount: data.currentPageCount,
  };
};

// Construct Payments URL
export const constructPaymentsUrl = (
  propertyId?: number,
  unitId?: number,
  unitAccountId?: number,
  search?: string,
  page?: number,
  pageSize?: number,
  amount?: string,
  purpose?: string,
  method?: string,
  status?: string,
  sortBy?: PaymentSortBy,
  sortOrder?: SortOrder
): string => {
  let queryParams = new URLSearchParams();

  if (search !== undefined && search.trim().length > 0) {
    queryParams.append("search", encodeURIComponent(search));
  }
  if (propertyId !== undefined) {
    queryParams.append("propertyId", propertyId.toString());
  }
  if (unitId !== undefined) {
    queryParams.append("unitId", unitId.toString());
  }
  if (unitAccountId !== undefined) {
    queryParams.append("unitAccountId", unitAccountId.toString());
  }
  if (page !== undefined) {
    queryParams.append("page", page.toString());
  }
  if (pageSize !== undefined) {
    queryParams.append("pageSize", pageSize.toString());
  }
  if (amount !== undefined && amount.length > 0) {
    queryParams.append("amount", amount);
  }
  if (purpose !== undefined && purpose.length > 0) {
    queryParams.append("purpose", purpose);
  }
  if (method !== undefined && method.length > 0) {
    queryParams.append("method", method);
  }
  if (status !== undefined && status.length > 0) {
    queryParams.append("status", status);
  }
  if (sortBy !== undefined) {
    queryParams.append("sortBy", sortBy);
  }
  if (sortOrder !== undefined) {
    queryParams.append("sortOrder", sortOrder);
  }

  return `${API_URL}/payments?${queryParams.toString()}`;
};

// Create Payments
export interface ICreatePayment {
  unit_account_id: number;
  lease_id: number | null;
  due_date: Date;
  amount: number;
  cheque_no: string | null;
  bank_name: string | null;
  remark: string | null;
  method: PaymentMethod;
  purpose: PaymentPurpose;
  direction: PaymentDirection;
  status: PaymentStatus;
  destination_bank_detail_id: number | null;
}

export const createPayments = async (
  payments: ICreatePayment[]
): Promise<IPayment[]> => {
  const formattedPayments = payments.map((payment) => ({
    lease_id: payment.lease_id,
    unit_account_id: payment.unit_account_id,
    due_date: payment.due_date,
    amount: payment.amount,
    cheque_no: payment.cheque_no,
    bank_name: payment.bank_name,
    remark: payment.remark,
    method: payment.method,
    purpose: payment.purpose,
    direction: payment.direction,
    status: payment.status,
    destination_bank_detail_id: payment.destination_bank_detail_id,
  }));

  const response = await fetch(`${API_URL}/payments`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formattedPayments),
  });

  const data = await response.json();
  if (response.status === 201) return data;
  else throw new Error(data.message);
};

// Update Payment
export const updatePayment = async (
  payment_id: number,
  payment: ICreatePayment
): Promise<IPayment> => {
  const formattedPayment = {
    lease_id: payment.lease_id,
    unit_account_id: payment.unit_account_id,
    due_date: payment.due_date,
    amount: payment.amount,
    cheque_no: payment.cheque_no,
    bank_name: payment.bank_name,
    remark: payment.remark,
    method: payment.method,
    purpose: payment.purpose,
    direction: payment.direction,
    status: payment.status,
    destination_bank_detail_id: payment.destination_bank_detail_id,
  };

  const response = await fetch(`${API_URL}/payments/${payment_id}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formattedPayment),
  });

  const data = await response.json();
  if (response.status === 200) return data;
  else throw new Error(data.message);
};

// Upload Payment Document
export const uploadPaymentDocument = async (
  payment_id: number,
  file: File
): Promise<IDocument> => {
  const formData = new FormData();
  formData.append(`file`, file);

  const response = await fetch(`${API_URL}/payments/${payment_id}/document`, {
    method: "POST",
    credentials: "include",
    body: formData,
  });

  const data = await response.json();
  if (response.status === 201) return data.document;
  else throw new Error(data.message);
};

// Delete Payment
export const deletePayment = async (payment_id: number): Promise<boolean> => {
  const response = await fetch(`${API_URL}/payments/${payment_id}`, {
    method: "DELETE",
    credentials: "include",
  });
  return response.status === 200;
};

export const exportPaymentsCsv = async (
  paymentIds: number[]
): Promise<Blob> => {
  const response = await fetch(`${API_URL}/payments/csv-export`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ paymentIds }),
  });

  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message);
  }

  return await response.blob();
};
