import React from 'react';
import { SPACES_ENDPOINT } from '../../utils/constants';
import TheIvyImage from "../../assets/images/the-ivy.jpeg";
import {
    PropertyCardContainer,
    PropertyCardWrapper,
    PropertyImageWrapper,
    PropertyOverlay,
    PropertyContent,
    PropertyTitle,
    PropertyLocationWrapper,
    PropertyLocation,
    StyledLazyLoadImage,
    ConstructionIcon,
    PropertyBadge,
    LocationIcon
} from './property-card.styles';
import useEmployeeStore from '../../stores/employeeStore';
import { EmployeeRole } from '../../services/employeeService';
import { useTranslation } from 'react-i18next';

interface PropertyCardProps {
    imageSrc?: string;
    title: string;
    location: string;
    locationUrl: string;
    under_construction: boolean;
    onClick: () => void;
}

const PropertyCard: React.FC<PropertyCardProps> = ({
    imageSrc,
    title,
    location,
    locationUrl,
    under_construction,
    onClick,
}) => {
    const { t } = useTranslation();
    const employeeRole = useEmployeeStore((state) => state.employee?.role_id);
    const isRestricted = under_construction && employeeRole !== EmployeeRole.ADMINISTRATOR;

    const handleLocationClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation();
    };

    return (
        <PropertyCardContainer>
            <PropertyCardWrapper deactivated={isRestricted} onClick={isRestricted ? undefined : onClick}>
                <PropertyImageWrapper>
                    <StyledLazyLoadImage
                        placeholderSrc='https://placehold.co/600x400'
                        src={imageSrc ? `${SPACES_ENDPOINT}${imageSrc}` : TheIvyImage}
                        alt={title}
                        effect="opacity"
                        width="100%"
                        height="100%"
                        draggable={false}
                    />
                    {under_construction && (
                        <>
                            <PropertyBadge>Under Construction</PropertyBadge>
                            <PropertyOverlay>
                                <ConstructionIcon />
                            </PropertyOverlay>
                        </>
                    )}
                </PropertyImageWrapper>
                <PropertyContent>
                    <PropertyTitle>{title}</PropertyTitle>
                    <PropertyLocationWrapper>
                        <LocationIcon />
                        <PropertyLocation
                            href={locationUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={handleLocationClick}>
                            {location}
                        </PropertyLocation>
                    </PropertyLocationWrapper>
                </PropertyContent>
            </PropertyCardWrapper>
        </PropertyCardContainer>
    );
};

export default PropertyCard;
