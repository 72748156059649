import useSWR from 'swr';
import { fetchDashboardData, IDashboardData } from '../services/dashboardService';
import { API_URL } from '../utils/constants';


export const useDashboard = (propertyId?: number | null) => {
    const fetcher = (url: string) => fetchDashboardData(url);
    const url = `${API_URL}/dashboard/${propertyId ? `?propertyId=${propertyId}` : ''}`;
    
    const { data, error, mutate } = useSWR<IDashboardData>(url, fetcher, {
        refreshInterval: 60000, // Refresh every 60 seconds (60000 ms)
    });

    return {
        dashboardData: data,
        isLoading: !error && !data,
        error: error,
        mutate,
    };
};
