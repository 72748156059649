import styled, { css, keyframes } from 'styled-components';

// Keyframes
const pulseAnimation = keyframes`
  0% {
    transform: translateY(-50%) scale(1);
    opacity: 1;
  }
  50% {
    transform: translateY(-50%) scale(1.2);
    opacity: 0.8;
  }
  100% {
    transform: translateY(-50%) scale(1);
    opacity: 1;
  }
`;

// Styled Components
export const SidebarContainer = styled.div<{ isVisible?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: #fff;
  z-index: 1045;
  transition: transform 0.3s ease-in-out;

  h4 {
    color: black;
    font-size: 15px;
  }

  .divider {
    height: 1px;
    width: 100%;
    background-color: #F4F7FE;
  }

  .nav-item {
    list-style-type: none;
  }

  .nav-item .nav-link {
    color: #182233 !important;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    margin-left: 16px;
    margin-right: 16px;
    transition: opacity 0.3s ease-in-out;
    padding: 16px;
    position: relative;
  }

  .nav-item .logout {
    color: #FD4E5D !important;
    margin: 0px;
  }

  .nav-item.active .nav-link {
    padding: 16px;
    border-radius: 16px;
    background-color: #F5FAFE;
  }

  .environment {
    margin-top: 8px;
    font-size: 12px;
    margin-bottom: 0;
  }

  .deactivated {
    opacity: 0.3;
    cursor: default;
  }

  .nav-item:not(.active):not(.deactivated):hover {
    opacity: 0.7;
  }

  .nav.flex-column {
    flex-grow: 1;
  }

  /* Media Queries für Mobile */
  @media (max-width: 992px) {
    width: calc(100% - 32px);
    top: 81px;
    left: 16px;
    transform: translateX(-105%);
    transition: transform 0.3s ease-in-out;
    z-index: 1045;
    background-color: white;
    height: calc(100 * var(--vh) - 101px);
    border-radius: 8px;
    padding: 16px;
    margin-top: 16px;
    user-select: none;

    ${props => props.isVisible && css`
      transform: translateX(0);
    `}

    .nav-item .nav-link {
      border-radius: 0;
    }

    .close-btn {
      display: block;
    }
  }

  /* Desktop Styles */
  @media (min-width: 992px) {
    position: fixed;
    top: 0;
    left: 0;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 250px;
    user-select: none;
  }
`;

export const SidebarHeader = styled.div`
  /* Mobile Styles */
  @media (max-width: 992px) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 125px;
    }
  }

  /* Desktop Styles */
  @media (min-width: 992px) {
    margin-top: 16px;
    margin-left: 16px;

    img {
      width: 150px;
    }
  }
`;

export const TitleWrapper = styled.div`
  /* Mobile Styles */
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 8px;
  }

  /* Desktop Styles */
  @media (min-width: 992px) {
    display: block;
  }
`;

export const SidebarTitle = styled.h4`
  /* Mobile Styles */
  @media (max-width: 992px) {
    font-size: 14px;
  }

  /* Desktop Styles */
  @media (min-width: 992px) {
    margin-top: 8px;
    font-size: 15px;
  }
`;

export const EnvironmentText = styled.p`
  margin-top: 8px;
  margin-bottom: 0;

  /* Mobile Styles */
  @media (max-width: 992px) {
    font-size: 10px;
  }

  /* Desktop Styles */
  @media (min-width: 992px) {
    font-size: 12px;
  }
`;

export const Category = styled.p<{ expanded?: boolean }>`
  font-size: 0.75rem;
  font-weight: 700;
  color: rgb(119, 131, 142);
  padding: 8px 16px;
  margin-top: 10px;
  margin-bottom: 5px;
  position: relative;
  text-transform: uppercase;

  .category-arrow {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    transition: transform 0.3s ease-in-out;
  }

  ${props => props.expanded && css`
    .category-arrow {
      transform: translateY(-50%) rotate(180deg);
    }
  `}
`;

export const ScrollableContent = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
`;

export const SidebarFooter = styled.div`
  padding: 16px 0;
`;

export const NotificationDot = styled.span<{ isRed: boolean }>`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  background-color: ${props => props.isRed ? '#ff0000' : '#28a745'};
  
  ${props => props.isRed && css`
    animation: ${pulseAnimation} 1.5s infinite;
  `}
`; 